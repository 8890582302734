// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host .ellipsis-label {
  font-weight: inherit;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  cursor: inherit;
}
:host p {
  touch-action: auto !important;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/custom-components/ellipsis-label/ellipsis-label.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,oBAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AACJ;AAEE;EACE,6BAAA;AAAJ","sourcesContent":[":host {\n  width: 100%;\n\n  .ellipsis-label {\n    font-weight: inherit;\n    margin-bottom: 0;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    display: block;\n    cursor: inherit;\n  }\n\n  p {\n    touch-action: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
