// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .density {
  font-weight: 600;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/select-machine-quality/select-machine-quality.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,gBAAA;AAAJ","sourcesContent":["::ng-deep {\n  .density {\n    font-weight: 600;\n    margin-left: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
