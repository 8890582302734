// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .amount-specifier-container {
  position: absolute;
  bottom: 0;
  height: 56px;
  width: 100%;
  z-index: 2;
}
:host .amount-specifier-container .mat-mdc-form-field.amount {
  width: 116px;
}
:host .amount-specifier-container ::ng-deep.mat-mdc-input-element {
  text-align: center;
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/amount-specifier/amount-specifier.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;AAAJ;AAEI;EACE,YAAA;AAAN;AAGI;EACE,kBAAA;EACA,qBAAA;AADN","sourcesContent":[":host {\n  .amount-specifier-container {\n    position: absolute;\n    bottom: 0;\n    height: 56px;\n    width: 100%;\n    z-index: 2;\n\n    .mat-mdc-form-field.amount {\n      width: 116px;\n    }\n\n    ::ng-deep.mat-mdc-input-element {\n      text-align: center;\n      padding: 0 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
