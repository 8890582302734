// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
:host .link-icon-cell-name {
  min-width: 0;
  margin: auto 0;
}
:host .no-flex-shrink {
  flex-shrink: 0;
}
:host .ag-icon-button {
  visibility: hidden;
  margin: auto 0;
}

.ag-row-selected :host .ag-icon-button,
.ag-row-hover :host .ag-icon-button {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/grids/link-icon-renderer/link-icon-renderer.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;AACF;AACE;EACE,YAAA;EACA,cAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AAGE;EACE,kBAAA;EACA,cAAA;AADJ;;AAOE;;EACE,mBAAA;AAHJ","sourcesContent":[":host {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  flex-wrap: nowrap;\r\n\r\n  .link-icon-cell-name {\r\n    min-width: 0;\r\n    margin: auto 0;\r\n  }\r\n\r\n  .no-flex-shrink {\r\n    flex-shrink: 0;\r\n  }\r\n\r\n  .ag-icon-button {\r\n    visibility: hidden;\r\n    margin: auto 0;\r\n  }\r\n}\r\n\r\n.ag-row-selected,\r\n.ag-row-hover {\r\n  :host .ag-icon-button {\r\n    visibility: visible;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
