export class BuggyForProductionSchedule {
  private _id: number;
  private _name: string;
  private _amountPlannedInMeter: number;
  private _amountPlannedInPicks: number;

  public constructor(id: number, name: string, amountPlannedInMeter: number = 0, amountPlannedInPicks: number = 0) {
    this._id = id;
    this._name = name;
    this._amountPlannedInMeter = amountPlannedInMeter;
    this._amountPlannedInPicks = amountPlannedInPicks;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public get amountPlannedInMeter(): number {
    return this._amountPlannedInMeter;
  }

  public set amountPlannedInMeter(amountPlannedInMeter: number) {
    this._amountPlannedInMeter = amountPlannedInMeter;
  }

  public get amountPlannedInPicks(): number {
    return this._amountPlannedInPicks;
  }

  public set amountPlannedInPicks(amountPlannedInPicks: number) {
    this._amountPlannedInPicks = amountPlannedInPicks;
  }

  public static fromJSON(buggyForProductionScheduleJSON: any): BuggyForProductionSchedule {
    return new BuggyForProductionSchedule(
      buggyForProductionScheduleJSON.id,
      buggyForProductionScheduleJSON.name,
      buggyForProductionScheduleJSON.amountPlannedInMeter,
      buggyForProductionScheduleJSON.amountPlannedInPicks
    );
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      amountPlannedInMeter: this.amountPlannedInMeter,
      amountPlannedInPicks: this.amountPlannedInPicks
    } as any as JSON;
  }
}
