import {InjectionToken} from '@angular/core';
import {BuggyForProductionSchedule} from '@domain/production-schedule/buggy-for-production-schedule';
import {PlaceholderCategory} from '@domain/textile-data/finishing-and-finishing-template/placeholder-category';
import {Observable} from 'rxjs';
import {Repository} from '../repository';

export const BUGGIES = new InjectionToken<Buggies>('Buggies');

export interface Buggies extends Repository<BuggyForProductionSchedule, BuggyForProductionSchedule> {
  getCategoriesWithComponentsForBuggyName(): Observable<PlaceholderCategory[]>;
  getGeneratedBuggyName(machineId: number, qualityId: number, creelId: number): Observable<string>;
}
