import {Component, Inject, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {BackendErrorContext} from '@application/helper/backend-error-context';
import {leastCommonMultiple} from '@application/helper/textile-data/colored-yarn-set/least-common-multiple';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription as ProfileSubscription} from '@domain/profile/subscription';
import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {ColoredYarnSet} from '@domain/textile-data/colored-yarn-set/colored-yarn-set';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {CalculatedYarnConsumption} from '@domain/yarn-consumption/calculated-yarn-consumption';
import {CreelPositionForCalculatedYarnConsumption} from '@domain/yarn-consumption/creel-position';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {YarnConsumption, YARN_CONSUMPTION} from '@infrastructure/http/yarn-consumption/yarn-consumption';
import {RealtimeYarnConsumption, REALTIME_YARN_CONSUMPTION} from '@infrastructure/signalr/yarn-consumption/realtime-yarn-consumption';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {GridColorsOfCreelPositionComponent} from '@presentation/pages/textile-data/colored-yarn-set/add/grid-colors-of-creel-position/grid-colors-of-creel-position.component';
import {GridYarnTypesOfCreelPositionComponent} from '@presentation/pages/textile-data/colored-yarn-set/add/grid-yarn-types-of-creel-position/grid-yarn-types-of-creel-position.component';
import {GridYarnConsumptionComponent} from '@presentation/pages/textile-data/textile-data-detail/grid-yarn-consumption/grid-yarn-consumption.component';
import {OverviewListCreelPositionWithColorAndYarnTypes} from '@presentation/pages/textile-data/textile-data-detail/overview-list-creel-position-with-color-and-yarn-types';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {OverviewListYarnType} from '@presentation/pages/textile-data/yarn-type/overview/overview-list-yarn-type';
import {
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  DialogBuilderFactoryService,
  DialogType,
  GridOptionsBuilderFactoryService,
  OverlayComponentParams,
  TranslateService
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridOptions, ICellRendererParams, ITooltipParams, RowHeightParams} from 'ag-grid-community';
import {cloneDeep, isEqual, isNil, map, noop, reverse, size, times} from 'lodash-es';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-colored-yarn-set-preview',
  templateUrl: './colored-yarn-set-preview.component.html',
  styleUrls: ['./colored-yarn-set-preview.component.scss']
})
export class ColoredYarnSetPreviewComponent extends BaseComponent implements OnChanges, OnInit {
  @ViewChild('creelPositionsGrid') public creelPositionsGrid: AgGridAngular;
  @Input() public coloredYarnSet: ColoredYarnSet;
  @Input() public colorSet: OverviewListColorSet;
  @Input() public yarnSet: OverviewListYarnSet;
  @Input() public orderlineId: number;
  @Input() public designId: number;
  @Input() public qualityId: number;
  public listOfCreelPositions: OverviewListCreelPositionWithColorAndYarnTypes[];
  public gridOptionsForListOfCreelPositions: GridOptions;
  public calculatingYarnConsumption = false;
  public yarnConsumptionCalculated = false;
  private readonly rowVerticalPadding = 32;
  private readonly rowLabelHeight = 20;
  private errorContextForMissingMachineQualityParameters = 'YARN_CONSUMPTION_INSUFFICIENT_INFORMATION';
  private readonly calculateYarnConsumptionPermission: Permission = Permission.TEXFAB_YARNCONSUMPTION;
  private currentSubscription: ProfileSubscription;

  public constructor(
    @Inject(AUTHENTICATION) private authentication: Authentication,
    @Inject(YARN_CONSUMPTION) private readonly yarnConsumption: YarnConsumption,
    @Inject(REALTIME_YARN_CONSUMPTION) private realtimeYarnConsumption: RealtimeYarnConsumption,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.initializeGridOptionsForCreelPositions();
  }

  public ngOnChanges(): void {
    this.connectRealtimeYarnConsumption();
    if (this.coloredYarnSet || (this.colorSet && this.yarnSet)) {
      this.setListOfCreelPositions();
    }
  }

  public calculateYarnConsumption(): void {
    this.calculatingYarnConsumption = true;
    let request: Observable<void>;

    if (!isNil(this.orderlineId)) {
      request = this.yarnConsumption.calculateYarnConsumptionForOrderline(this.orderlineId).pipe(takeUntil(this.unSubscribeOnViewDestroy));
    } else {
      request = this.yarnConsumption.calculateYarnConsumptionForDesign(this.designId, this.colorSet.id, this.yarnSet.id, this.qualityId, false);
    }

    request.subscribe({
      next: () => noop,
      error: (error: BackendErrorContext) => {
        if (isEqual(this.errorContextForMissingMachineQualityParameters, error.errorCode)) {
          this.showErrorDialogForBackendError();
        }

        this.calculatingYarnConsumption = false;
      }
    });
  }

  public hasPermissionToCalculateYarnConsumption(): boolean {
    return this.currentSubscription.hasPermission(this.calculateYarnConsumptionPermission);
  }

  public getColorList(): ColoredYarnSet | OverviewListColorSet {
    let colorList;

    if (!isNil(this.coloredYarnSet)) {
      colorList = new ColoredYarnSet(
        this.coloredYarnSet.id,
        this.coloredYarnSet.name,
        new ColorSet(
          this.coloredYarnSet.colorSet.id,
          this.coloredYarnSet.colorSet.name,
          reverse(cloneDeep(this.coloredYarnSet.colorSet.creelPositions)),
          this.coloredYarnSet.colorSet.used,
          this.coloredYarnSet.colorSet.technicalCode,
          this.coloredYarnSet.colorSet.alternativeColorSetIds
        ),
        this.coloredYarnSet.yarnSet,
        this.coloredYarnSet.used,
        this.coloredYarnSet.technicalCode,
        this.coloredYarnSet.alternativeColoredYarnSetIds
      );
    } else {
      colorList = new OverviewListColorSet(this.colorSet.id, this.colorSet.name, reverse(cloneDeep(this.colorSet.creelPositions)));
    }

    return colorList;
  }

  public getLabelName(): string {
    return !isNil(this.coloredYarnSet) ? this.coloredYarnSet.name : this.colorSet.name;
  }

  private showErrorDialogForBackendError(): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant('TEXTILE_DATA.YARN_TYPE.CALCULATE_YARN_CONSUMPTION_ERROR_TITLE'),
      messageText: this.translate.instant('TEXTILE_DATA.YARN_TYPE.CALCULATE_YARN_CONSUMPTION_ERROR'),
      type: DialogType.INFORMATION
    });
  }

  private connectRealtimeYarnConsumption(): void {
    this.getCalculatedYarnConsumption();
  }

  private getCalculatedYarnConsumption(): void {
    this.realtimeYarnConsumption
      .getCalculatedYarnConsumption()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((calculatedYarnConsumption: CalculatedYarnConsumption) => {
        this.calculatingYarnConsumption = false;
        if (
          this.isIdentifiedConfiguration(calculatedYarnConsumption.colorSetId, calculatedYarnConsumption.yarnSetId, calculatedYarnConsumption.machineQualityId) ||
          isNil(calculatedYarnConsumption.colorSetId)
        ) {
          this.yarnConsumptionCalculated = true;
        }

        if (
          !isNil(calculatedYarnConsumption.yarnConsumption) &&
          (this.isIdentifiedConfiguration(calculatedYarnConsumption.colorSetId, calculatedYarnConsumption.yarnSetId, calculatedYarnConsumption.machineQualityId) ||
            isNil(calculatedYarnConsumption.colorSetId))
        ) {
          this.listOfCreelPositions.forEach((creelPosition: OverviewListCreelPositionWithColorAndYarnTypes) => {
            const creelPositionNumber = creelPosition.creelPositionForColors.position;
            creelPosition.creelPositionForYarnTypes.items.forEach((item: OverviewListYarnType, startDent0Based: number) => {
              let yarnConsumptionForDent = calculatedYarnConsumption.yarnConsumption.creelPositions.find(
                (x: CreelPositionForCalculatedYarnConsumption) => x.creelPosition === creelPositionNumber && x.dentNumber === startDent0Based + 1
              );
              if (yarnConsumptionForDent === undefined) {
                yarnConsumptionForDent = calculatedYarnConsumption.yarnConsumption.creelPositions.find(
                  (x: CreelPositionForCalculatedYarnConsumption) => x.creelPosition === creelPositionNumber && x.dentNumber === 1
                );
              }

              item.yarnConsumption = yarnConsumptionForDent.yarnConsumption;
            });
          });

          if (AssertionUtils.isNullOrUndefined(this.orderlineId) || calculatedYarnConsumption.id === this.orderlineId) {
            this.creelPositionsGrid.api.setGridOption('columnDefs', [
              ...this.getColumnDefsForListOfCreelPositions(),
              this.colDefBuilderFactoryService
                .getBuilder()
                .withValueGetter('data.creelPositionForYarnTypes')
                .withHeaderName('TEXTILE_DATA.YARN_TYPE.YARN_CONSUMPTION')
                .withCellRenderer(GridYarnConsumptionComponent)
                .withMinWidth(200)
                .withoutFilter()
                .build()
            ]);
            this.creelPositionsGrid.api.sizeColumnsToFit();
          }
        }
      });
  }

  private isIdentifiedConfiguration(colorSetId: number, yarnSetId: number, machineQualityId: number): boolean {
    return !isNil(this.colorSet) && !isNil(this.yarnSet) && isEqual(colorSetId, this.colorSet.id) && isEqual(yarnSetId, this.yarnSet.id) && isEqual(machineQualityId, this.qualityId);
  }

  private setListOfCreelPositions(): void {
    let creelPositions: OverviewListCreelPositionWithColorAndYarnTypes[];
    if (!isNil(this.coloredYarnSet)) {
      creelPositions = this.getCreelPositions(size(this.coloredYarnSet.overviewListColorSet.creelPositions), this.coloredYarnSet.overviewListColorSet, this.coloredYarnSet.overviewListYarnSet);
    } else {
      creelPositions = this.getCreelPositions(size(this.colorSet.creelPositions), this.colorSet, this.yarnSet);
    }
    creelPositions.sort((a: OverviewListCreelPositionWithColorAndYarnTypes, b: OverviewListCreelPositionWithColorAndYarnTypes) => {
      return b.creelPositionForColors.position - a.creelPositionForColors.position;
    });

    this.setListOfCreelPositionsWithYarnSetDefined(creelPositions);
  }

  private getCreelPositions(numberOfCreelPositions: number, colorSet: OverviewListColorSet, yarnSet: OverviewListYarnSet): OverviewListCreelPositionWithColorAndYarnTypes[] {
    return times(numberOfCreelPositions, (index: number) => {
      const position = numberOfCreelPositions - index;
      return {
        creelPositionForColors: isNil(colorSet) ? null : colorSet.creelPositions.find((x: OverviewListCreelPosition) => x.position === position),
        creelPositionForYarnTypes: isNil(yarnSet) ? null : yarnSet.creelPositions.find((x: OverviewListCreelPosition) => x.position === position)
      };
    });
  }

  private setListOfCreelPositionsWithYarnSetDefined(creelPositions: OverviewListCreelPositionWithColorAndYarnTypes[]): void {
    this.listOfCreelPositions = map(cloneDeep(creelPositions), (creelPosition: OverviewListCreelPositionWithColorAndYarnTypes) => {
      const commonMultiple = leastCommonMultiple(size(creelPosition.creelPositionForColors.items), size(creelPosition.creelPositionForYarnTypes.items));

      this.replicateCreelPositionUntilLeastCommonMultiple(creelPosition.creelPositionForColors, commonMultiple);
      this.replicateCreelPositionUntilLeastCommonMultiple(creelPosition.creelPositionForYarnTypes, commonMultiple);

      return creelPosition;
    });
  }

  private replicateCreelPositionUntilLeastCommonMultiple(creelPosition: OverviewListCreelPosition, leastCommonMultipleNumber: number): void {
    const creelPositionToRepeat = cloneDeep(creelPosition.items);
    const numberOfRepititionsForCreelPosition = leastCommonMultipleNumber / size(creelPosition.items);

    for (let i = 0; i < numberOfRepititionsForCreelPosition - 1; i++) {
      creelPosition.items.push(...cloneDeep(creelPositionToRepeat));
    }
  }

  private initializeGridOptionsForCreelPositions(): void {
    this.gridOptionsForListOfCreelPositions = this.gridOptionsBuilderFactoryService
      .getBuilder(this.getColumnDefsForListOfCreelPositions(), GridIdentifier.LIST_OF_CREEL_POSITIONS_FOR_COLORED_YARN_SET_PREVIEW)
      .withGetRowHeight((params: RowHeightParams) => this.calculateRowHeight(params.data))
      .withLoadingOverlay()
      .withNoRowsOverlay({
        titleParam: 'TEXTILE_DATA.MATERIAL_SET.CREEL_POSITION'
      } as OverlayComponentParams)
      .withoutSorting()
      .withoutGetRowId()
      .withoutColumnMenu()
      .build();
  }

  private calculateRowHeight(creelPosition: OverviewListCreelPositionWithColorAndYarnTypes): number {
    let numberOfItemsInRow: number;
    const creelPositionForColors: OverviewListCreelPosition = creelPosition.creelPositionForColors;
    if (!isNil(creelPositionForColors)) {
      numberOfItemsInRow = size(creelPositionForColors.items);
    }

    return this.rowVerticalPadding + numberOfItemsInRow * this.rowLabelHeight;
  }

  private getColumnDefsForListOfCreelPositions(): ColDef[] {
    return [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.COLORED_YARN_SET.POSITION')
        .withCellRenderer(({node}: ICellRendererParams) => this.getPositionForRowIndex(node.rowIndex))
        .withTooltipValueGetter(({rowIndex}: ITooltipParams) => this.getPositionForRowIndex(rowIndex))
        .withCellClass('text-align-center')
        .withMaxWidth(120)
        .withSuppressMovable()
        .withoutFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withValueGetter('data.creelPositionForColors')
        .withHeaderName('TEXTILE_DATA.COLORED_YARN_SET.COLOR_NAMES_HEADER')
        .withCellRenderer(GridColorsOfCreelPositionComponent, {
          showRgbValues: false
        })
        .withMinWidth(160)
        .withoutFilter()
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withValueGetter('data.creelPositionForYarnTypes')
        .withHeaderName('TEXTILE_DATA.YARN_TYPE.YARN_TYPE')
        .withCellRenderer(GridYarnTypesOfCreelPositionComponent)
        .withMinWidth(160)
        .withoutFilter()
        .build()
    ];
  }

  private getPositionForRowIndex(rowIndex: number): string {
    return `${this.listOfCreelPositions.length - rowIndex}`;
  }
}
