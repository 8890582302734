import {Component, Inject} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {PropertyValue} from '@domain/property-value';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {TranslateService, Unit} from '@vdw/angular-component-library';
import {isEqual, isNil} from 'lodash-es';
import {BaseAdvancedSearchComponent} from '../base-advanced-search.component';

@Component({
  templateUrl: '../base-advanced-search.component.html',
  styleUrls: ['./advanced-search-dialog.component.scss', '../base-advanced-search.component.scss']
})
export class AdvancedSearchDialogComponent extends BaseAdvancedSearchComponent {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<RepositionDialogComponent>,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    private readonly navigationHelperService: NavigationHelperService<{advancedSearchFilters: PropertyValue[]}>
  ) {
    super(formBuilder, translate);

    this.advancedSearchFilters = data.advancedSearchFilters;
    if (!isNil(data.pickDensityUnit) && !isNil(data.reedDensityUnit)) {
      this.pickDensityUnit = data.pickDensityUnit;
      this.reedDensityUnit = data.reedDensityUnit;
    }

    if (!isNil(data.defaultUnit)) {
      this.defaultUnit = data.defaultUnit;
      if (isEqual(data.defaultUnit, Unit.CENTIMETER)) {
        this.maximumFractionDigits = 2;
        this.allowDecimalCases = true;
      }
    }
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<{advancedSearchFilters: PropertyValue[]}>({advancedSearchFilters: this.advancedSearchFilters});
  }

  public clearAdvancedSearchFilters(): void {
    this.clearAdvancedSearchFilterValues();
    this.dialogRef.close(this.advancedSearchFilters);
  }

  public applyAdvancedSearchFilters(): void {
    this.updateAdvancedSearchFilterValues();
    this.dialogRef.close(this.advancedSearchFilters);
  }

  public closeAdvancedSearch(): void {
    this.dialogRef.close();
  }
}
