// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 240px;
  display: block;
}
:host .label {
  display: block;
  margin-bottom: 4px;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/custom-components/selection-list-container/selection-list-container.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;AACJ","sourcesContent":[":host {\n  width: 240px;\n  display: block;\n\n  .label {\n    display: block;\n    margin-bottom: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
