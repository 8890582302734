import {Injectable} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MobileNavigationBarExternalLinkItem} from '@application/helper/mobile-navigation/mobile-navigation-bar-external-link-item';
import {Subscription} from '@domain/profile/subscription';
import {environment} from '@environments/environment';
import {OnboardingNavigationStep} from '@presentation/components/onboarding-dialog/onboarding-navigation-step.enum';
import {SideNavigationStateService} from '@presentation/navigation/side-navigation-state.service';
import {NotificationsService} from '@presentation/pages/notifications/notifications.service';
import {AssertionUtils} from '@vdw/angular-component-library';
import {ParametersForOpenBackgroundJobsCommand} from '../../helper/mobile-navigation/commands/open-background-job-command';
import {OpenMobileNavigationBarCommand} from '../../helper/mobile-navigation/commands/open-mobile-navigation-bar-command';
import {MobileNavigationBarActionItem} from '../../helper/mobile-navigation/mobile-navigation-bar-action-item';
import {MobileNavigationBarItem} from '../../helper/mobile-navigation/mobile-navigation-bar-item';
import {MobileNavigationBarItemMobilePosition} from '../../helper/mobile-navigation/mobile-navigation-bar-item-mobile-position.enum';
import {MobileNavigationBarItemPosition} from '../../helper/mobile-navigation/mobile-navigation-bar-item-position.enum';
import {MobileNavigationBarItemSize} from '../../helper/mobile-navigation/mobile-navigation-bar-item-size.enum';
import {MobileNavigationBarRouteLinkItem} from '../../helper/mobile-navigation/mobile-navigation-bar-route-link-item';
import {RouteUtils} from '../../helper/routing/route-utils';

@Injectable({
  providedIn: 'root'
})
export class MobileNavigationService {
  private readonly navigationBarItems: MobileNavigationBarItem[];
  private readonly openNavigationBarCommand: OpenMobileNavigationBarCommand;

  public constructor(
    protected readonly sidenavigationState: SideNavigationStateService,
    private readonly notificationsService: NotificationsService
  ) {
    this.openNavigationBarCommand = new OpenMobileNavigationBarCommand(sidenavigationState);
    this.navigationBarItems = this.composeMobileNavigationBarItems();
  }

  public getQuickAccessBarItems(subscription: Subscription): MobileNavigationBarItem[] {
    const navigationBarItems = this.navigationBarItems.filter((item: MobileNavigationBarItem) => item.mobilePositions.includes(MobileNavigationBarItemMobilePosition.QUICK_ACCESS));

    return navigationBarItems.filter((item: MobileNavigationBarItem) => this.hasRequiredPermission(item, subscription));
  }

  public getDefaultMobileRoute(subscription: Subscription): string {
    const navigationBarRouteLinkItems = this.navigationBarItems.filter((item: MobileNavigationBarItem) => this.hasRequiredPermission(item, subscription)) as MobileNavigationBarRouteLinkItem[];
    const texStateNavigationItem = navigationBarRouteLinkItems.find((item: MobileNavigationBarItem) => item instanceof MobileNavigationBarRouteLinkItem && item.id === 'texState');

    return texStateNavigationItem ? texStateNavigationItem.path : navigationBarRouteLinkItems.find((item: MobileNavigationBarItem) => item instanceof MobileNavigationBarRouteLinkItem).path;
  }

  public getNavigationBarItems(subscription: Subscription): MobileNavigationBarItem[] {
    const available = this.navigationBarItems.filter((item: MobileNavigationBarItem) => this.hasRequiredPermission(item, subscription));

    return [
      ...available.filter((item: MobileNavigationBarItem) => item.position === MobileNavigationBarItemPosition.TOP),
      ...available.filter((item: MobileNavigationBarItem) => item.position === MobileNavigationBarItemPosition.BOTTOM)
    ];
  }

  private hasRequiredPermission(navigationBarItem: MobileNavigationBarItem, subscription: Subscription): boolean {
    return AssertionUtils.isNullOrUndefined(navigationBarItem.requiredPermission) || subscription.hasPermission(navigationBarItem.requiredPermission);
  }

  private composeMobileNavigationBarItems(): MobileNavigationBarItem[] {
    return [
      new MobileNavigationBarRouteLinkItem({
        route: RouteUtils.paths.profile,
        name: 'PROFILE.PROFILE',
        icon: 'profile',
        position: MobileNavigationBarItemPosition.BOTTOM,
        mobilePositions: [MobileNavigationBarItemMobilePosition.MENU],
        id: 'profile',
        onboardingStep: OnboardingNavigationStep.PROFILE
      }),
      new MobileNavigationBarActionItem<ParametersForOpenBackgroundJobsCommand, MatDialogRef<any>>({
        name: 'SEARCH',
        icon: 'search',
        position: MobileNavigationBarItemPosition.NONE,
        mobilePositions: [MobileNavigationBarItemMobilePosition.QUICK_ACCESS],
        requiredPermission: null,
        id: 'search',
        command: null,
        size: MobileNavigationBarItemSize.SMALL
      }),
      new MobileNavigationBarRouteLinkItem({
        name: 'NOTIFICATIONS.MENU_NAME',
        icon: 'notifications',
        position: MobileNavigationBarItemPosition.TOP,
        mobilePositions: [MobileNavigationBarItemMobilePosition.QUICK_ACCESS, MobileNavigationBarItemMobilePosition.MENU],
        id: 'notifications',
        onboardingStep: OnboardingNavigationStep.NOTIFICATIONS,
        badge: this.notificationsService.unreadCount,
        route: RouteUtils.paths.notifications
      }),
      new MobileNavigationBarRouteLinkItem({
        route: RouteUtils.paths.texState,
        name: 'MACHINE.MACHINE_OVERVIEW.MENU_NAME',
        icon: 'machine-overview',
        position: MobileNavigationBarItemPosition.TOP,
        mobilePositions: [MobileNavigationBarItemMobilePosition.QUICK_ACCESS, MobileNavigationBarItemMobilePosition.MENU],
        id: 'texState',
        onboardingStep: OnboardingNavigationStep.TEXSTATE,
        canSearch: true
      }),
      new MobileNavigationBarRouteLinkItem({
        route: RouteUtils.paths.texBot,
        name: 'SUPPORT.MENU_NAME',
        icon: 'bot',
        position: MobileNavigationBarItemPosition.TOP,
        mobilePositions: [MobileNavigationBarItemMobilePosition.QUICK_ACCESS, MobileNavigationBarItemMobilePosition.MENU],
        id: 'texBot',
        onboardingStep: OnboardingNavigationStep.TEXBOT
      }),
      new MobileNavigationBarActionItem<void, void>({
        name: 'PRODUCTION_ORDER.NAVIGATION.OVERVIEW',
        icon: 'menu',
        position: MobileNavigationBarItemPosition.NONE,
        mobilePositions: [MobileNavigationBarItemMobilePosition.QUICK_ACCESS],
        requiredPermission: null,
        id: 'menu',
        command: this.openNavigationBarCommand,
        size: MobileNavigationBarItemSize.SMALL
      }),
      new MobileNavigationBarExternalLinkItem({
        link: `${environment.helpUrl}`,
        name: 'HELP.HELP',
        icon: 'help',
        position: MobileNavigationBarItemPosition.BOTTOM,
        mobilePositions: [MobileNavigationBarItemMobilePosition.MENU],
        requiredPermission: null,
        id: 'help',
        size: MobileNavigationBarItemSize.SMALL
      })
    ];
  }
}
