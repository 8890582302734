// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  width: 100%;
}
:host .image-drawing-pattern-visualisation {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  image-rendering: pixelated;
}
:host .color-pattern-visualisation {
  background-size: cover;
  border-radius: 3px;
}
:host .svg-pattern-visualisation-thumbnail::ng-deep {
  height: 100%;
  display: flex;
  flex-direction: row;
  place-content: center;
  border-radius: 3px;
  background-repeat: repeat;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/pattern-preview/pattern-preview.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;AACE;EACE,wBAAA;EACA,4BAAA;EACA,2BAAA;EACA,kBAAA;EACA,0BAAA;AACJ;AAEE;EACE,sBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;AADJ","sourcesContent":[":host {\n  height: 100%;\n  width: 100%;\n\n  .image-drawing-pattern-visualisation {\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: center;\n    border-radius: 3px;\n    image-rendering: pixelated;\n  }\n\n  .color-pattern-visualisation {\n    background-size: cover;\n    border-radius: 3px;\n  }\n\n  .svg-pattern-visualisation-thumbnail::ng-deep {\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    place-content: center;\n    border-radius: 3px;\n    background-repeat: repeat;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
