// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .container {
  gap: 8px;
  display: flex;
  margin: 8px 0;
}
:host .no-flex-shrink {
  flex-shrink: 0;
}
:host .color-preview {
  height: 20px;
  width: 20px;
}
:host .name-wrapper {
  min-width: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100%;
  flex-grow: 1;
}
:host .name-wrapper .link-icon-cell-name {
  min-width: 0;
  margin: auto 0;
}
:host .name-wrapper .ag-icon-button {
  visibility: hidden;
  margin: auto 0;
}

.ag-row-selected :host .name-wrapper .ag-icon-button,
.ag-row-hover :host .name-wrapper .ag-icon-button {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/pages/textile-data/color/overview/grid-color-preview/grid-color-preview.component.scss"],"names":[],"mappings":"AACE;EACE,QAAA;EACA,aAAA;EACA,aAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,YAAA;EACA,WAAA;AAFJ;AAKE;EACE,YAAA;EACA,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;AAHJ;AAKI;EACE,YAAA;EACA,cAAA;AAHN;AAMI;EACE,kBAAA;EACA,cAAA;AAJN;;AAWE;;EACE,mBAAA;AAPJ","sourcesContent":[":host {\n  .container {\n    gap: 8px;\n    display: flex;\n    margin: 8px 0;\n  }\n\n  .no-flex-shrink {\n    flex-shrink: 0;\n  }\n\n  .color-preview {\n    height: 20px;\n    width: 20px;\n  }\n\n  .name-wrapper {\n    min-width: 0;\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: nowrap;\n    height: 100%;\n    flex-grow: 1;\n\n    .link-icon-cell-name {\n      min-width: 0;\n      margin: auto 0;\n    }\n\n    .ag-icon-button {\n      visibility: hidden;\n      margin: auto 0;\n    }\n  }\n}\n\n.ag-row-selected,\n.ag-row-hover {\n  :host .name-wrapper .ag-icon-button {\n    visibility: visible;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
