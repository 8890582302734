// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  align-items: center;
  display: flex;
  height: 100%;
}

app-color-list-preview:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/pages/textile-data/colored-yarn-set/overview/grid-colored-yarn-set-preview/grid-colored-yarn-set-preview.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[":host {\n  align-items: center;\n  display: flex;\n  height: 100%;\n}\n\napp-color-list-preview:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
