<mat-card class="flex-column flex-100 flex-no-grow full-height" [class.mobile]="responsivenessViewMode.isPhone">
  <vdw-mobile-page-header *ngIf="responsivenessViewMode.isPhone" [title]="'SUPPORT.MACHINES_LIST.AVAILABLE_DOCUMENTS' | bmsTranslate" (backClicked)="goBack.emit()"></vdw-mobile-page-header>

  <mat-card-content class="flex-row flex-1">
    <section class="machine-details flex-column flex-100" *ngIf="canShowMachineDetails()">
      <article class="flex-row gap-8 flex-start">
        <h3 *ngIf="!isEditingName" class="title machine-name font-weight-semibold">{{ machineName }}</h3>
        <button *ngIf="!isEditingName" mat-icon-button class="mat-icon-action-button-sm-without-border" (click)="editMachineName()" [matTooltip]="'GENERAL.ACTIONS.EDIT' | bmsTranslate">
          <mat-icon svgIcon="edit"></mat-icon>
        </button>
        <form *ngIf="isEditingName" [formGroup]="machineNameChangeForm" class="machine-documentation-details-form flex-1 flex-start-center gap-12" vdwBp.lt-sm="mb-16">
          <mat-form-field class="flex-1" vdwBp.lt-sm="flex-100 full-width">
            <mat-label>{{ 'GENERAL.NAME' | bmsTranslate }}</mat-label>
            <input matInput type="text" formControlName="name" />
          </mat-form-field>
          <div class="machine-documentation-details-form-buttons-container flex-row gap-12">
            <button mat-stroked-button color="primary" (click)="cancel()" vdwBp.lt-sm="flex-50">
              {{ 'GENERAL.ACTIONS.CANCEL' | bmsTranslate }}
            </button>
            <vdw-progress-button [inProgress]="saving" (progressClick)="saveMachineName()" [fullWidth]="true" vdwBp.lt-sm="half-width"></vdw-progress-button>
          </div>
        </form>
      </article>

      <ng-container *ngIf="!canShowNoDataOverlay()">
        <div
          *ngIf="canShowMachineGeneralDocumentations()"
          class="skeleton-wrapper flex-row"
          [class.skeleton]="showSkeletonViewForGeneralDocumentations"
          [@fadeIn]="showSkeletonViewForGeneralDocumentations"
        >
          <mat-expansion-panel #machineGeneralDocumentationsExpansionPanel class="title-skeleton-wrapper flex-100">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <h4 class="font-weight-semibold">{{ 'GENERAL.GENERAL' | bmsTranslate }}</h4>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <div>
                <div>
                  <ul class="flex-column gap-8">
                    <li *ngFor="let machineGeneralDocumentation of machineGeneralDocumentations">
                      <p class="b1 url font-weight-semibold" (click)="downloadMachineGeneralDocumentationFile(machineGeneralDocumentation.number)">
                        {{ machineGeneralDocumentation.name }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </div>
        <div *ngIf="canShowMachineSchematics()" class="skeleton-wrapper flex-row" [class.skeleton]="showSkeletonViewForSchematics" [@fadeIn]="showSkeletonViewForSchematics">
          <mat-expansion-panel #machineSchematicsExpansionPanel class="title-skeleton-wrapper flex-100">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <h4 class="font-weight-semibold">{{ 'MACHINE_DOCUMENTATION.ELECTRICAL_DIAGRAMS' | bmsTranslate }}</h4>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <div>
                <div>
                  <ul class="flex-column gap-8">
                    <li *ngFor="let machineSchematic of machineSchematics">
                      <p class="b1 url font-weight-semibold" (click)="downloadMachineSchematicsFile(machineSchematic.name)">
                        {{ getMachineSchematic(machineSchematic) }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </div>
        <div *ngIf="canShowMachineManuals()" class="skeleton-wrapper flex-row" [class.skeleton]="showSkeletonViewForManuals" [@fadeIn]="showSkeletonViewForManuals">
          <mat-expansion-panel #machineManualsExpansionPanel class="title-skeleton-wrapper flex-100">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <h4 class="font-weight-semibold">{{ 'MACHINE_DOCUMENTATION.MANUALS' | bmsTranslate }}</h4>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <div>
                <div>
                  <ul class="flex-column gap-8">
                    <li *ngFor="let machineManual of machineManuals">
                      <p class="b1">{{ machineManual.machineType }} - {{ machineManual.name }}</p>
                      <p class="b2">
                        <span
                          class="font-weight-semibold tint-lightblue"
                          *ngFor="let machineManualLanguage of machineManual.languages; let isLast = last"
                          (click)="downloadMachineManualFile(machineManual, machineManualLanguage.language)"
                        >
                          <span class="url font-weight-semibold">{{ machineManualLanguage.language }}</span
                          >{{ isLast ? '' : ', ' }}
                        </span>
                      </p>
                    </li>
                    <li *ngIf="canShowMachineDitaManualUrl()">
                      <p class="b1">{{ 'MACHINE_DOCUMENTATION.INTERACTIVE_USER_MANUAL' | bmsTranslate }}</p>
                      <p class="b2">
                        <a class="url" href="{{ machineDitaManualUrl }}" target="_blank" rel="noopener">EN-English</a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </div>
        <div *ngIf="canShowMachineSparePartsCatalogue()" class="skeleton-wrapper flex-row" [class.skeleton]="showSkeletonViewForSparePartsCatalogue" [@fadeIn]="showSkeletonViewForSparePartsCatalogue">
          <mat-expansion-panel #machineSparePartsCatalogueExpansionPanel class="title-skeleton-wrapper flex-100">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <h4 class="font-weight-semibold">{{ 'MACHINE_DOCUMENTATION.SPARE_PARTS_CATALOGUE' | bmsTranslate }}</h4>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <div>
                <div>
                  <ul class="flex-column gap-8">
                    <li>
                      <p class="b1 url font-weight-semibold" (click)="downloadMachineSparePartsCatalogueFile()">
                        {{ machineSparePartsCatalogue.name }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </div>
        <div
          *ngIf="canShowMachineTechnicalWeaveSettings()"
          class="skeleton-wrapper flex-row"
          [class.skeleton]="showSkeletonViewForTechnicalWeaveSettings"
          [@fadeIn]="showSkeletonViewForTechnicalWeaveSettings"
        >
          <mat-expansion-panel #machineTechnicalWeaveSettingsExpansionPanel class="title-skeleton-wrapper flex-100">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <h4 class="font-weight-semibold">{{ 'MACHINE_DOCUMENTATION.TECHNICAL_WEAVE_SETTINGS' | bmsTranslate }}</h4>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <div>
                <div>
                  <ul class="flex-column gap-8">
                    <li *ngFor="let machineTechnicalWeaveSetting of machineTechnicalWeaveSettings">
                      <p class="b1">{{ machineTechnicalWeaveSetting.type }}</p>
                      <p class="b2">
                        <span
                          class="url font-weight-semibold"
                          *ngFor="let revision of machineTechnicalWeaveSetting.revisions; let isLast = last"
                          (click)="downloadTechnicalWeaveSettingsRevisionFiles(machineTechnicalWeaveSetting.type, revision)"
                        >
                          <p class="no-margin">{{ getRevisionFileName(revision, isLast) }}</p>
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </div>
        <div *ngIf="canShowMachineSetups()" class="skeleton-wrapper flex-row" [class.skeleton]="showSkeletonViewForSetups" [@fadeIn]="showSkeletonViewForSetups">
          <mat-expansion-panel #machineSetupsExpansionPanel class="title-skeleton-wrapper flex-100">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <h4 class="font-weight-semibold">{{ 'MACHINE_DOCUMENTATION.SOFTWARE' | bmsTranslate }}</h4>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <div>
                <div>
                  <ul class="flex-column gap-8">
                    <li>
                      <p class="b1 url font-weight-semibold" (click)="downloadMachineSetupFile()">
                        {{ 'GENERAL.ACTIONS.DOWNLOAD' | bmsTranslate }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </div>
      </ng-container>
      <vdw-nodata-overlay
        class="overlay flex-row"
        *ngIf="canShowNoDataOverlay()"
        titleKey="MACHINE_DOCUMENTATION.NO_DOCUMENTATION_FOUND"
        [hideDescription]="true"
        [hideActions]="true"
        [scale]="1"
      ></vdw-nodata-overlay>
    </section>
  </mat-card-content>
</mat-card>
