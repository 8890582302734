// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .header {
  display: flex;
  justify-content: space-between;
}
:host .header .toggle {
  display: flex;
  align-items: center;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/creel-mapping/creel-mapping.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;AAAJ;AAEI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAAN","sourcesContent":[":host {\n  .header {\n    display: flex;\n    justify-content: space-between;\n\n    .toggle {\n      display: flex;\n      align-items: center;\n      gap: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
