// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .toggle-button {
  display: flex;
  align-items: center;
}
:host .toggle-button.interactable {
  pointer-events: all;
}
:host .toggle-button.interactable .mat-mdc-slide-toggle {
  cursor: pointer;
}
:host .toggle-button.not-interactable {
  pointer-events: none;
}
:host .toggle-button.not-interactable .mat-mdc-slide-toggle {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/custom-components/toggle-button/toggle-button.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AAEI;EACE,mBAAA;AAAN;AAEM;EACE,eAAA;AAAR;AAII;EACE,oBAAA;AAFN;AAIM;EACE,eAAA;AAFR","sourcesContent":[":host {\n  .toggle-button {\n    display: flex;\n    align-items: center;\n\n    &.interactable {\n      pointer-events: all;\n\n      .mat-mdc-slide-toggle {\n        cursor: pointer;\n      }\n    }\n\n    &.not-interactable {\n      pointer-events: none;\n\n      .mat-mdc-slide-toggle {\n        cursor: default;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
