// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .ag-selection-checkbox {
  margin-right: 16px;
}
:host ::ng-deep .ag-header-cell,
:host ::ng-deep .ag-cell {
  padding: 0 16px;
}
:host ::ng-deep .finishing-label-cell .ag-cell-wrapper {
  justify-content: center;
}
:host .dialog-content {
  padding: 24px 24px 0 24px;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/select-rule-entity/select-rule-entity.component.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;AADN;AAII;;EAEE,eAAA;AAFN;AAMM;EACE,uBAAA;AAJR;AASE;EACE,yBAAA;AAPJ","sourcesContent":[":host {\n  ::ng-deep {\n    .ag-selection-checkbox {\n      margin-right: 16px;\n    }\n\n    .ag-header-cell,\n    .ag-cell {\n      padding: 0 16px;\n    }\n\n    .finishing-label-cell {\n      .ag-cell-wrapper {\n        justify-content: center;\n      }\n    }\n  }\n\n  .dialog-content {\n    padding: 24px 24px 0 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
