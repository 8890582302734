// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host input[type=number]::-webkit-outer-spin-button,
:host input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
:host input[type=number] {
  -moz-appearance: textfield;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/grid/numeric-editor/numeric-editor.component.scss"],"names":[],"mappings":"AACE;;EAEE,wBAAA;EACA,SAAA;AAAJ;AAGE;EACE,0BAAA;EACA,WAAA;AADJ","sourcesContent":[":host {\n  input[type='number']::-webkit-outer-spin-button,\n  input[type='number']::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  input[type='number'] {\n    -moz-appearance: textfield;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
