import {IdName} from '@domain/id-name';
import {IdNameDescription} from '@domain/id-name-description';
import {OverviewListReportItem} from '@presentation/pages/settings/report-item/overview/overview-list-report-item';
import {ReportItemPeriodType} from '@presentation/pages/settings/report-item/overview/report-item-period-type.enum';
import {MachineType} from './machine-type.enum';

export class ReportItem extends OverviewListReportItem {
  private _abbreviation: string;
  private _dataType: IdName;
  private _cumulationType: IdName;
  private _supportedMachineTypes: MachineType[];
  private _catalogId: number;
  private _value: any;
  private _parentReportItemId: number | null;

  public constructor(
    id: number,
    name: string,
    category: IdNameDescription,
    module: IdName,
    physicalQuantity: IdName,
    unit: IdName,
    formatting: string,
    description: string,
    enabled: boolean,
    abbreviation: string,
    dataType: IdName,
    cumulationType: IdName,
    supportedMachineTypes: MachineType[],
    catalogId: number,
    periodType: ReportItemPeriodType,
    sequence: number | null,
    value: any,
    parentReportItemId: number | null
  ) {
    super(id, name, category, module, physicalQuantity, unit, formatting, description, enabled, periodType, sequence);
    this._abbreviation = abbreviation;
    this._dataType = dataType;
    this._cumulationType = cumulationType;
    this._supportedMachineTypes = supportedMachineTypes;
    this._catalogId = catalogId;
    this._value = value;
    this._parentReportItemId = parentReportItemId;
  }

  public get abbreviation(): string {
    return this._abbreviation;
  }

  public set abbreviation(abbreviation: string) {
    this._abbreviation = abbreviation;
  }

  public get dataType(): IdName {
    return this._dataType;
  }

  public set dataType(dataType: IdName) {
    this._dataType = dataType;
  }

  public get cumulationType(): IdName {
    return this._cumulationType;
  }

  public set cumulationType(cumulationType: IdName) {
    this._cumulationType = cumulationType;
  }

  public get supportedMachineTypes(): MachineType[] {
    return this._supportedMachineTypes;
  }

  public set supportedMachineTypes(supportedMachineTypes: MachineType[]) {
    this._supportedMachineTypes = supportedMachineTypes;
  }

  public get catalogId(): number {
    return this._catalogId;
  }

  public set catalogId(catalogId: number) {
    this._catalogId = catalogId;
  }

  public get value(): any {
    return this._value;
  }

  public set value(value: any) {
    this._value = value;
  }

  public get parentReportItemId(): number {
    return this._parentReportItemId;
  }

  public set parentReportItemId(parentReportItemId: number) {
    this._parentReportItemId = parentReportItemId;
  }

  public static fromJSON(reportItemJSON: any): ReportItem {
    return new ReportItem(
      reportItemJSON.id,
      reportItemJSON.name,
      reportItemJSON.category ? IdNameDescription.fromJSON(reportItemJSON.category) : reportItemJSON.category,
      reportItemJSON.module ? IdName.fromJSON(reportItemJSON.module) : reportItemJSON.module,
      reportItemJSON.physicalQuantity ? IdName.fromJSON(reportItemJSON.physicalQuantity) : reportItemJSON.physicalQuantity,
      reportItemJSON.physicalUnit ? IdName.fromJSON(reportItemJSON.physicalUnit) : reportItemJSON.physicalUnit,
      reportItemJSON.formatting,
      reportItemJSON.description,
      reportItemJSON.enabled,
      reportItemJSON.abbreviation,
      reportItemJSON.dataType,
      reportItemJSON.cumulationType,
      reportItemJSON.supportedEquipmentKinds,
      reportItemJSON.catalogId,
      reportItemJSON.periodType,
      reportItemJSON.sequence,
      reportItemJSON.value,
      reportItemJSON.parentReportItem
    );
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      description: this.description,
      abbreviation: this.abbreviation,
      formatting: this.formatting,
      dataTypeId: this.dataType.id,
      cumulationTypeId: this.cumulationType.id,
      moduleId: this.module.id,
      enabled: this.enabled,
      physicalQuantity: this.physicalQuantity?.id,
      physicalUnitId: this.unit?.id,
      categoryId: this.category.id,
      catalogId: this.catalogId,
      supportedMachineTypes: this.supportedMachineTypes,
      sequence: this.sequence,
      periodType: this.periodType
    } as any as JSON;
  }

  public static createReportItemWithName(reportItem: ReportItem, name: string, abbreviation: string = null): ReportItem {
    return new ReportItem(
      reportItem.id,
      name,
      reportItem.category,
      reportItem.module,
      reportItem.physicalQuantity,
      reportItem.unit,
      reportItem.formatting,
      reportItem.description,
      reportItem.enabled,
      abbreviation ?? reportItem.abbreviation,
      reportItem.dataType,
      reportItem.cumulationType,
      reportItem.supportedMachineTypes,
      reportItem.catalogId,
      reportItem.periodType,
      reportItem.sequence,
      reportItem.value,
      reportItem.parentReportItemId
    );
  }
}
