<section class="textile-data-section flex-column">
  <vdw-tabs-menu-card
    *ngIf="showHeader"
    [icon]="iconForCard"
    [title]="getTranslations().entity | bmsTranslate: {count: 2}"
    [amount]="amountOfTotalCreatedTextileDataItemsSubject | async"
    [hasPermissionToEdit]="hasEditPermission()"
    [canShowCustomSettings]="canShowCustomSettings"
    [settingsTooltip]="settingsTooltip"
    (settingsEvent)="showCustomSettingsSetup()"
    [canShowBackButton]="canShowBackButton"
  >
    <ng-content select="[extra-header-actions]"></ng-content
  ></vdw-tabs-menu-card>
  <mat-card class="grid-width" fxFlex="0 1 100%" fxLayout="column">
    <mat-card-content fxFlex fxLayout="column">
      <article fxLayout="row" class="toolbar">
        <div fxFlex="25" fxLayoutAlign="start center" fxLayoutGap="16px" class="actions" fxHide.lt-sm>
          <ng-container *appHasPermission="permissionToModifyItems">
            <button
              mat-icon-button
              appDataCy="create-new"
              class="mat-icon-action-button-lg-with-border add-button"
              *ngIf="canShowActionButton('add') && canAddItem"
              [disabled]="disableAddButton"
              (click)="onAdd()"
              [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate"
            >
              <mat-icon svgIcon="add"></mat-icon>
            </button>
            <button
              mat-icon-button
              class="mat-icon-action-button-lg-with-border edit-button"
              *ngIf="canShowActionButton('edit') && canEditItem"
              [disabled]="!isSingleTextileDataRowSelected() || disableEditButton"
              (click)="onEdit()"
              [matTooltip]="'GENERAL.ACTIONS.EDIT_OBJECT' | bmsTranslate: {object: ''}"
            >
              <mat-icon svgIcon="edit"></mat-icon>
            </button>
            <button
              mat-icon-button
              class="mat-icon-action-button-lg-with-border duplicate-button"
              *ngIf="canShowActionButton('duplicate') && canDuplicateItem"
              [disabled]="!canDuplicateSelectedRow() || disableDuplicateButton"
              (click)="onDuplicate()"
              [matTooltip]="'GENERAL.ACTIONS.DUPLICATE' | bmsTranslate"
            >
              <mat-icon [matTooltip]="(canDuplicateSelectedRow() ? 'GENERAL.ACTIONS.DUPLICATE' : getReasonForPreventingDuplicate()) | bmsTranslate" svgIcon="copy"></mat-icon>
            </button>
            <button
              mat-icon-button
              appDataCy="delete"
              class="mat-icon-action-button-lg-with-border remove-button"
              *ngIf="canShowDeleteActionButton() && canDeleteItem"
              [disabled]="isDeleteDisabled()"
              (click)="removeConfirmation()"
              [matTooltip]="'GENERAL.ACTIONS.DELETE' | bmsTranslate"
            >
              <mat-icon svgIcon="trash"></mat-icon>
            </button>

            <button
              mat-icon-button
              class="mat-icon-action-button-lg-with-border remove-button"
              *ngIf="canExportGrid"
              (click)="exportGrid()"
              [matTooltip]="'AGGRID.EXPORT' | bmsTranslate"
              [disabled]="isExporting"
            >
              <mat-icon svgIcon="export"></mat-icon>
            </button>
          </ng-container>
          <ng-content select="[extra-actions]"></ng-content>
        </div>

        <div *ngIf="isShowLatestVersionActive() || showSearchBar" class="flex-1 flex-row flex-end-center">
          <div fxFlex fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end center">
            <div fxLayout="row" fxLayoutGap="16px" *ngIf="isShowLatestVersionActive()">
              <vdw-toggle-button (checkedChange)="canShowOnlyLatestVersionChanged($event)"></vdw-toggle-button>
            </div>
            <app-search-filters
              #searchFilters
              *ngIf="canShowSearchFilters()"
              [searchFilters]="advancedSearchFilters"
              [commercialUnit]="getDefaultUnit()"
              [pickDensityUnit]="pickDensityUnit"
              [reedDensityUnit]="reedDensityUnit"
              [showFiltersFromRightToLeft]="true"
              (searchFiltersChanged)="updateFiltered()"
              (showAdvancedSearch)="showAdvancedSearch()"
            ></app-search-filters>
            <vdw-filter *ngIf="showSearchBar" #filterComponent (filteredTextChanged)="filter($event)" [icon]="iconForSearch" (filterIconClicked)="showAdvancedSearch()"></vdw-filter>
          </div>
        </div>
      </article>

      <article fxFlex appDataCy="grid" class="grid-layout">
        <ag-grid-angular #textileDataOverviewGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForTextileDataOverview" [rowData]="listOfTextileData"></ag-grid-angular>
      </article>
    </mat-card-content>
  </mat-card>
</section>
