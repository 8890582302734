// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const VERSION = {
    "dirty": true,
    "raw": "d7f76b689d-dirty",
    "hash": "d7f76b689d",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "d7f76b689d-dirty",
    "semverString": null,
    "version": "5.32.1"
};
