<section appDataCy="select-machine-quality-dialog" fxLayout="column" fxFlex>
  <vdw-header [title]="title | bmsTranslate" svgIcon="select-blue">
    <vdw-toggle-button (checkedChange)="showOnlyLatestVersionChanged($event)"></vdw-toggle-button>
  </vdw-header>
  <article class="dialog-content flex-column flex-1 gap-16">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate" (click)="navigateToAddQuality()">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
    <ag-grid-angular #machineQualitiesGrid class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfMachineQualities" [rowData]="listOfMachineQualities"></ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectMachineQualities()" [centered]="true" (chooseEvent)="selectMachineQualities()"></vdw-footer>
</section>
