// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .dialog-content {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/custom-components/datepicker/date-picker/date-picker.component.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ","sourcesContent":[":host {\n  .dialog-content {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
