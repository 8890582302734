import {IdName} from '@domain/id-name';
import {IdNameDescription} from '@domain/id-name-description';
import {ReportItemPeriodType} from '@presentation/pages/settings/report-item/overview/report-item-period-type.enum';

export class OverviewListReportItem extends IdName {
  private _category: IdNameDescription;
  private _module: IdName;
  private _physicalQuantity: IdName;
  private _unit: IdName;
  private _formatting: string;
  private _description: string;
  private _enabled: boolean;
  private _periodType: ReportItemPeriodType;
  private _sequence: number | null;

  public constructor(
    id: number,
    name: string,
    category: IdNameDescription,
    module: IdName,
    physicalQuantity: IdName,
    unit: IdName,
    formatting: string,
    description: string,
    enabled: boolean,
    periodType: ReportItemPeriodType,
    sequence: number | null
  ) {
    super(id, name);
    this._category = category;
    this._module = module;
    this._physicalQuantity = physicalQuantity;
    this._unit = unit;
    this._formatting = formatting;
    this._description = description;
    this._enabled = enabled;
    this._periodType = periodType;
    this._sequence = sequence;
  }

  public get category(): IdNameDescription {
    return this._category;
  }

  public set category(category: IdNameDescription) {
    this._category = category;
  }

  public get module(): IdName {
    return this._module;
  }

  public set module(module: IdName) {
    this._module = module;
  }

  public get physicalQuantity(): IdName {
    return this._physicalQuantity;
  }

  public set physicalQuantity(physicalQuantity: IdName) {
    this._physicalQuantity = physicalQuantity;
  }

  public get unit(): IdName {
    return this._unit;
  }

  public set unit(unit: IdName) {
    this._unit = unit;
  }

  public get formatting(): string {
    return this._formatting;
  }

  public get periodType(): ReportItemPeriodType {
    return this._periodType;
  }

  public get sequence(): number | null {
    return this._sequence;
  }

  public set formatting(formatting: string) {
    this._formatting = formatting;
  }

  public get description(): string {
    return this._description;
  }

  public set description(description: string) {
    this._description = description;
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public set enabled(enabled: boolean) {
    this._enabled = enabled;
  }

  public set periodType(periodType: ReportItemPeriodType) {
    this._periodType = periodType;
  }

  public set sequence(sequence: number | null) {
    this._sequence = sequence;
  }

  public static fromJSON(reportItemJSON: any): OverviewListReportItem {
    return new OverviewListReportItem(
      reportItemJSON.id,
      reportItemJSON.name,
      IdNameDescription.fromJSON(reportItemJSON.category),
      IdName.fromJSON(reportItemJSON.module),
      reportItemJSON.physicalQuantity ? IdName.fromJSON(reportItemJSON.physicalQuantity) : reportItemJSON.physicalQuantity,
      reportItemJSON.physicalUnit ? IdName.fromJSON(reportItemJSON.physicalUnit) : reportItemJSON.physicalUnit,
      reportItemJSON.formatting,
      reportItemJSON.description,
      reportItemJSON.enabled,
      reportItemJSON.periodType,
      reportItemJSON.sequence
    );
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      physicalUnitId: this.unit.id,
      description: this.description,
      enabled: this.enabled
    } as any as JSON;
  }
}
