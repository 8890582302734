import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {Router} from '@angular/router';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NavigationState} from '@domain/navigation/navigation-state.enum';
import {ApplicationMaintenance} from '@domain/profile/application-maintenance';
import {Company} from '@domain/profile/company';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {environment} from '@environments/environment';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {NotificationsComponent} from '@presentation/pages/notifications/notifications.component';
import {NotificationsService} from '@presentation/pages/notifications/notifications.service';
import {
  ApplicationLayoutService,
  AssertionUtils,
  BaseComponent,
  HoverDialogBuilderService,
  MenuTreeViewContextService,
  RESPONSIVENESS_VIEW_MODE,
  ResponsivenessViewMode
} from '@vdw/angular-component-library';
import moment from 'moment';
import {takeUntil} from 'rxjs';
import {NavigationComponent} from '../navigation-component/navigation.component';
import {SideNavigationStateService} from '../side-navigation-state.service';

@Component({
  selector: 'app-navigation-header',
  templateUrl: './navigation-header.component.html',
  styleUrls: ['./navigation-header.component.scss']
})
export class NavigationHeaderComponent extends BaseComponent implements OnInit, AfterViewInit {
  private readonly MAX_NOTIFICATIONS_VALUE = 99;
  public readonly notificationsComponent = NotificationsComponent;
  public readonly SETTINGS_PERMISSION = Permission.SETTINGS_SHOW_ICON;

  public unreadNotifications: string;
  public hasSettingsPermission: boolean;
  public maintenanceNotification: ApplicationMaintenance = null;

  @ViewChild('sidebarButton') public sidebarButton: MatIconButton;
  @Input() public companyData: {companies: Company[]; subscriptions: Subscription[]};

  public constructor(
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly sideNavigationState: SideNavigationStateService,
    private readonly notificationService: NotificationsService,
    private readonly menuTreeViewContext: MenuTreeViewContextService,
    public readonly applicationLayoutService: ApplicationLayoutService,
    private readonly hoverDialogBuilderService: HoverDialogBuilderService,
    private readonly backendConfiguration: BackendConfiguration,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(RESPONSIVENESS_VIEW_MODE) private readonly viewMode: ResponsivenessViewMode
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setUnreadNotifications();
    this.setMaintenanceNotifications();
    this.hasSettingsPermission = this.authentication.getCurrentSubscription()?.hasPermission(Permission.SETTINGS_SHOW_ICON) ?? false;

    this.authentication
      .currentSubscriptionChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((subscription: Subscription) => {
        this.setUnreadNotifications(subscription);
        this.hasSettingsPermission = subscription?.hasPermission(Permission.SETTINGS_SHOW_ICON) ?? false;
      });

    this.authentication
      .currentSubscriptionChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((subscription: Subscription) => this.setUnreadNotifications(subscription));

    this.applicationLayoutService.sidebarIcon = this.getSidebarIcon();
  }

  public ngAfterViewInit(): void {
    this.applicationLayoutService.sidebarHoverButton = this.sidebarButton?._elementRef?.nativeElement;
  }

  public hasNoUnreadNotifications(): boolean {
    return AssertionUtils.isNullOrUndefined(this.unreadNotifications) || this.unreadNotifications === '0';
  }

  public helpClicked(): void {
    window.open(environment.helpUrl);
  }

  public settingsClicked(): void {
    this.menuTreeViewContext.setActive(null);
    this.router.navigate([RouteUtils.paths.settings.absolutePath]);
  }

  public sidebarEnter(): void {
    setTimeout(() => {
      this.applicationLayoutService.openHoverDialog(this.applicationLayoutService.sidebarHoverButton, [], this.companyData, NavigationComponent, this.changeDetectorRef);
    });
  }

  public sidebarButtonClicked(): void {
    this.applicationLayoutService.toggle();

    if (this.applicationLayoutService.matDrawer?.mode === 'side') {
      this.sideNavigationState.getCurrentNavigationState() === NavigationState.OPEN ? this.sideNavigationState.changeNavigationStateToClosed() : this.changeNavigationStateToOpen();
    } else if (this.applicationLayoutService.isDrawerOpened()) {
      this.hoverDialogBuilderService.closeHoverDialog();
    }

    this.applicationLayoutService.sidebarIcon = this.getSidebarIcon();
  }

  public canShowMaintenanceNotification(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.maintenanceNotification);
  }

  private getSidebarIcon(): string {
    const keyboardIcon = 'solid-keyboard-double-arrow-left';
    if (AssertionUtils.isNullOrUndefined(this.applicationLayoutService.matDrawer) || !this.viewMode.isDesktop) {
      return this.sideNavigationState.getCurrentNavigationState() === NavigationState.OPEN ? keyboardIcon : 'solid-menu';
    }

    return this.applicationLayoutService.isDrawerOpened() ? keyboardIcon : 'solid-menu';
  }

  private setUnreadNotifications(subscription: Subscription = null): void {
    subscription ??= this.authentication.getCurrentSubscription();

    if (subscription?.hasPermission(Permission.ALERT_VIEW_NOTIFICATIONS)) {
      this.notificationService.unreadCount
        ?.pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((count: number) => (this.unreadNotifications = count > this.MAX_NOTIFICATIONS_VALUE ? `${this.MAX_NOTIFICATIONS_VALUE}+` : `${count}`));
    }
  }

  private changeNavigationStateToOpen(): void {
    this.hoverDialogBuilderService.closeHoverDialog();
    this.sideNavigationState.changeNavigationStateToOpen();
  }

  private setMaintenanceNotifications(): void {
    const applicationMaintenance = this.backendConfiguration.getApplicationMaintenance();

    if (!AssertionUtils.isNullOrUndefined(applicationMaintenance)) {
      const {start, end} = applicationMaintenance;
      const startDate = moment(start);
      const endDate = moment(end);
      const now = moment();
      const daysBetweenNowAndStartOfMaintenance = startDate.diff(now, 'days');
      const minDaysToShowMaintenanceNotification = 7;
      const shouldShowMaintenanceNotification = daysBetweenNowAndStartOfMaintenance <= minDaysToShowMaintenanceNotification && !now.isAfter(endDate);
      if (shouldShowMaintenanceNotification) {
        this.maintenanceNotification = applicationMaintenance;
      }
    }
  }
}
