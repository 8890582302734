// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .slide-toggle {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/dialog-button/dialog-data/order-lines-settings/order-lines-settings.component.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,mCAAA;EACA,mBAAA;AAAJ","sourcesContent":[":host {\n  .slide-toggle {\n    flex-direction: row;\n    box-sizing: border-box;\n    display: flex;\n    place-content: center space-between;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
