// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host article {
  padding: 24px;
}
:host vdw-footer::ng-deep mat-toolbar {
  padding: 0 24px 24px;
  height: 68px !important;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/pages/settings/alerts/add/select-alert-types/select-alert-types.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAGE;EACE,oBAAA;EACA,uBAAA;AADJ","sourcesContent":[":host {\n  article {\n    padding: 24px;\n  }\n\n  vdw-footer::ng-deep mat-toolbar {\n    padding: 0 24px 24px;\n    height: 68px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
