// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .permission-item {
  padding: 0 4px 8px;
  max-height: 44px;
}
:host .permission-icon {
  width: 36px;
  height: 36px;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/choose-subscription/select-subscription/select-subscription.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;AADJ","sourcesContent":[":host {\n  .permission-item {\n    padding: 0 4px 8px;\n    max-height: 44px;\n  }\n\n  .permission-icon {\n    width: 36px;\n    height: 36px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
