// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ag-grid-angular::ng-deep .ag-selection-checkbox {
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/select-pattern-field/select-pattern-field.component.scss"],"names":[],"mappings":"AAEI;EACE,iBAAA;AADN","sourcesContent":[":host {\n  ag-grid-angular::ng-deep {\n    .ag-selection-checkbox {\n      margin-right: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
