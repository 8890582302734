// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .actions-container {
  height: 100%;
  line-height: initial;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/pages/texfab/production-schedule/overview/actions/list-overview-action.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,oBAAA;AAAJ","sourcesContent":[":host {\n  .actions-container {\n    height: 100%;\n    line-height: initial;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
