// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}
:host .dialog-content {
  padding-top: 24px;
}
:host mat-dialog-actions {
  margin: 24px;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/select-pattern/add-pattern/add-pattern.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,YAAA;AAAJ","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n\n  .dialog-content {\n    padding-top: 24px;\n  }\n\n  mat-dialog-actions {\n    margin: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
