// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .mat-mdc-form-field::ng-deep {
  display: block;
  min-width: 0 !important;
}
:host .mat-mdc-form-field::ng-deep .mat-mdc-text-field-wrapper {
  padding: 0;
}
:host .mat-mdc-form-field::ng-deep .mat-icon-action-button-sm-without-border {
  width: 40px;
  height: 100%;
}
:host .mat-mdc-form-field::ng-deep .mat-icon-action-button-sm-without-border .mat-icon {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/custom-components/filter/filter.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,uBAAA;AAAJ;AAEI;EACE,UAAA;AAAN;AAGI;EACE,WAAA;EACA,YAAA;AADN;AAGM;EACE,qBAAA;AADR","sourcesContent":[":host {\n  .mat-mdc-form-field::ng-deep {\n    display: block;\n    min-width: 0 !important;\n\n    .mat-mdc-text-field-wrapper {\n      padding: 0;\n    }\n\n    .mat-icon-action-button-sm-without-border {\n      width: 40px;\n      height: 100%;\n\n      .mat-icon {\n        display: inline-block;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
