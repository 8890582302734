import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {BuggyForProductionSchedule} from '@domain/production-schedule/buggy-for-production-schedule';
import {Buggies, BUGGIES} from '@infrastructure/http/buggy/buggies';
import {
  AgGridUtils,
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog,
  Unit
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, ICellRendererParams, IRowNode, RowDataUpdatedEvent} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {isEqual, isNil, isUndefined} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-buggy.component.html',
  styleUrls: ['./select-buggy.component.scss']
})
export class SelectBuggyComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('buggiesGrid') public buggiesGrid: AgGridAngular;
  public listOfBuggies: BuggyForProductionSchedule[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];

  private readonly getBuggy: BuggyForProductionSchedule;
  private readonly machineId: number;
  private readonly machineQualityId: number;
  private readonly creelId: number;
  private readonly isPropertyValueForBuggyNameEmpty: boolean;

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    @Inject(BUGGIES) private readonly buggies: Buggies,
    private readonly dialogRef: MatDialogRef<SelectBuggyComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly l10nIntlService: L10nIntlService
  ) {
    super();

    this.dialogRef = dialogRef;
    this.gridOptionsBuilderFactoryService = gridOptionsBuilderFactoryService;
    this.getBuggy = data.getBuggy;
    this.machineId = data.machineId;
    this.machineQualityId = data.machineQualityId;
    this.creelId = data.creelId;
    this.isPropertyValueForBuggyNameEmpty = data.isPropertyValueForBuggyNameEmpty;
  }

  public get gridOptionsForListOfBuggies(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public ngOnInit(): void {
    this.getBuggies();
    this.initialiseGridOptions();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.buggiesGrid?.api];
  }

  public filterBuggies(event: string): void {
    this.buggiesGrid.api.setGridOption('quickFilterText', event);
  }

  public canSelectBuggy(): boolean {
    let result = false;
    if (!AssertionUtils.isNullOrUndefined(this.buggiesGrid?.api)) {
      result = this.buggiesGrid.api.getSelectedRows().length > 0;
    }
    return result;
  }

  public cancel(): void {
    this.dialogRef.close();

    if (!isEqual(this.getBuggy, this.getSelectedBuggy()) || !isNil(this.getSelectedBuggy())) {
      this.dialogRef.close(this.getBuggy);
    }
  }

  public selectBuggy(): void {
    this.dialogRef.close(this.getSelectedBuggy());
  }

  public addButtonEnabled(): boolean {
    return !isNil(this.machineId) && !isNil(this.machineQualityId) && !isNil(this.creelId) && !this.isPropertyValueForBuggyNameEmpty;
  }

  public addBuggy(): void {
    this.buggies
      .getGeneratedBuggyName(this.machineId, this.machineQualityId, this.creelId)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((generatedName: string) => {
        this.createBuggy(generatedName);
      });
  }

  private createBuggy(generatedBuggyName: string): void {
    const buggy = new BuggyForProductionSchedule(null, generatedBuggyName);
    this.buggies
      .save(buggy)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((buggyId: number) => {
        buggy.id = buggyId;
        this.dialogRef.close(buggy);
      });
  }

  private getBuggies(): void {
    this.buggies
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((buggies: BuggyForProductionSchedule[]) => {
        this.listOfBuggies = buggies;
      });
  }

  private initialiseGridOptions(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfBuggies(), GridIdentifier.SELECT_BUGGY)
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'PLANNING.BUGGY.BUGGY',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withOnRowDataUpdated(({api}: RowDataUpdatedEvent) => this.setBuggy(api))
        .withoutColumnMenu()
        .build()
    ];
  }

  private setBuggy(gridApi: GridApi): void {
    if (!isUndefined(this.getBuggy) && !isNil(this.getBuggy)) {
      const rowNode: IRowNode<BuggyForProductionSchedule> = gridApi.getRowNode(`${this.getBuggy.id}`);

      if (!isNil(rowNode)) {
        rowNode.setSelected(true);
      }
    }
  }

  private getColumnDefsForListOfBuggies(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withField('name').withHeaderName('GENERAL.NAME').build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('amountPlannedInPicks')
        .withNumericMultiFilter()
        .withHeaderName('ORDERBOOK.PLANNED_AMOUNT')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.value, Unit.PICKS))
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withField('amountPlannedInMeter')
        .withNumericMultiFilter()
        .withHeaderName('ORDERBOOK.PLANNED_AMOUNT')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.value, Unit.METER))
        .build()
    ];
  }

  private buildAgGridCellTextWithUnit(value: number, defaultUnit: Unit): string {
    return AgGridUtils.buildAgGridCellTextWithUnit(value, defaultUnit, this.l10nIntlService, 3);
  }

  private getSelectedBuggy(): any {
    return this.buggiesGrid.api.getSelectedRows()[0];
  }
}
