// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 80vw;
  height: 100%;
}
:host app-background-jobs-grid::ng-deep .background-jobs-list-container {
  height: 624px;
}
:host .mat-mdc-card::ng-deep {
  margin-bottom: 0;
}
:host .mat-mdc-card::ng-deep article.dialog-content {
  padding: 16px 24px;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/background-jobs/background-jobs-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AAEI;EACE,aAAA;AAAN;AAIE;EACE,gBAAA;AAFJ;AAII;EACE,kBAAA;AAFN","sourcesContent":[":host {\n  width: 80vw;\n  height: 100%;\n\n  app-background-jobs-grid::ng-deep {\n    .background-jobs-list-container {\n      height: 624px;\n    }\n  }\n\n  .mat-mdc-card::ng-deep {\n    margin-bottom: 0;\n\n    article.dialog-content {\n      padding: 16px 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
