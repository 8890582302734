import {Directive, Input, OnInit} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {NavigationService} from '@application/services/navigation/navigation.service';
import {AssertionUtils, BaseComponent} from '@vdw/angular-component-library';
import {startWith, takeUntil} from 'rxjs';

@Directive({
  selector: '[appBreadcrumbFormLinker]'
})
export class BreadcrumbFormLinkerDirective extends BaseComponent implements OnInit {
  @Input('appBreadcrumbFormLinker') public formControlName: string;

  public constructor(
    private formGroupDirective: FormGroupDirective,
    private navigationService: NavigationService
  ) {
    super();
  }

  public ngOnInit(): void {
    const formGroup = this.formGroupDirective.form;

    if (formGroup) {
      const formControl = formGroup.get(AssertionUtils.isEmpty(this.formControlName) ? 'name' : this.formControlName);

      formControl.valueChanges.pipe(startWith(formControl.value), takeUntil(this.unSubscribeOnViewDestroy)).subscribe((value: string) => this.navigationService.onNameChanged(value));
    }
  }
}
