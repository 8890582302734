// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .ag-row-group-indent-1 {
  padding-left: 32px;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/grids/disabled-cell-renderer/disabled-cell-renderer.component.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;AADN","sourcesContent":[":host {\n  ::ng-deep {\n    .ag-row-group-indent-1 {\n      padding-left: 32px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
