// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  gap: 4px;
}
:host app-color-list-preview:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/pages/textile-data/creel/overview/grid-creel-preview/grid-creel-preview.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,QAAA;AACF;AACE;EACE,eAAA;AACJ","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  margin: 8px 0;\n  gap: 4px;\n\n  app-color-list-preview:hover {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
