// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex !important;
  flex-direction: column;
}
:host .label {
  margin-bottom: 4px;
}
:host .push-notifications-toggle {
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/pages/notifications/notification-list/notification-list-settings.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,sBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ","sourcesContent":[":host {\n  display: flex !important;\n  flex-direction: column;\n\n  .label {\n    margin-bottom: 4px;\n  }\n\n  .push-notifications-toggle {\n    margin-bottom: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
