// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host article {
  padding: 24px;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/pages/settings/alerts/add/select-recipients/select-recipients.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ","sourcesContent":[":host {\n  article {\n    padding: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
