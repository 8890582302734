import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {MenuItemEntry} from './menu-item/menu-item-entry.interface';
import {MenuTreeViewContextService} from './menu-tree-view-context.service';

@Component({
  selector: 'vdw-menu-tree-view',
  templateUrl: './menu-tree-view.component.html',
  styleUrls: ['./menu-tree-view.component.scss']
})
export class MenuTreeViewComponent implements OnInit {
  @Input() public isRoot = false;
  @Input() public iconWidthInPx = 24;
  @Input() public isFavorites = false;
  @Input() public entry: MenuItemEntry;

  public constructor(
    public readonly menuTreeViewContext: MenuTreeViewContextService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    if (!AssertionUtils.isNullOrUndefined(this.entry.link) && this.router.isActive(this.entry.link, {paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'})) {
      setTimeout(() => this.menuTreeViewContext.setActive(this.entry));
    }
  }
}
