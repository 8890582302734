// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host .status-container {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/background-jobs/background-jobs-overview-status/background-jobs-overview-status.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,YAAA;AACJ","sourcesContent":[":host {\n  width: 100%;\n\n  .status-container {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
