import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable, Injector} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {ErrorMessageHelper} from '@application/helper/error-message-helper';
import {getFileExtension} from '@application/helper/get-file-extension';
import {NavigationService} from '@application/services/navigation/navigation.service';
import {MediaType} from '@domain/media-type';
import {Subscription} from '@domain/profile/subscription';
import {environment} from '@environments/environment';
import {HttpRequestManagerService} from '@infrastructure/http/http-request-manager.service';
import {CONNECTIVITY_TOAST, ConnectivityToast} from '@presentation/components/connectivity-toast/connectivity-toast';
import {
  AppInsightsLoggingService,
  BackendError,
  BackendErrorSeverity,
  EnumUtils,
  SignalrHubConnectionFactoryService,
  TranslateService,
  UnhandledBackendError,
  UuidUtils
} from '@vdw/angular-component-library';
import {includes, isEmpty, isEqual, isNil, pull, size, startsWith, trim} from 'lodash-es';
import {EMPTY, Observable, of, throwError, timer} from 'rxjs';
import {catchError, finalize, map, retry, switchMap, takeUntil} from 'rxjs/operators';
import {AUTHENTICATION} from './authentication/authentication';
import {CANCEL_REQUEST} from './cancel-request-context';
import Timeout = NodeJS.Timeout;

@Injectable()
export class InterceptedHttp implements HttpInterceptor {
  private readonly applicationEnvironmentName: string = 'env.json';
  private readonly registerPath: string = 'register';
  private readonly assetsPath: string = 'assets';
  private readonly timeoutDurationForSlowConnection = 1000;

  private connectivityToastTimeout: Timeout;
  private canShowConnectivityToast = true;
  private requests: HttpRequest<any>[] = [];

  public constructor(
    @Inject(CONNECTIVITY_TOAST) private readonly connectivityToast: ConnectivityToast,
    private readonly backendConfiguration: BackendConfiguration,
    private readonly errorMessageHelper: ErrorMessageHelper,
    private readonly translate: TranslateService,
    private readonly httpRequestManagerService: HttpRequestManagerService,
    private readonly injector: Injector,
    private readonly navigationService: NavigationService,
    private appInsightsLoggingService: AppInsightsLoggingService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.canIntercept(request)) {
      const authentication = this.injector.get(AUTHENTICATION);
      const signalrHubConnectionFactoryService = this.injector.get(SignalrHubConnectionFactoryService);

      return authentication.getToken().pipe(
        catchError(() => {
          authentication.handleInvalidSubscription();
          return EMPTY;
        }),
        switchMap((token: string) => {
          const requestId = UuidUtils.generateV4Uuid();
          const userId = authentication.getCurrentUserId();

          this.setRequestIdAndUserIdOnErrorLoggingScope(requestId, userId);

          const headers = {
            Accept: 'security/json, application/json',
            Authorization: `Bearer ${token}`,
            SubscriptionId: this.backendConfiguration.getSubscriptionId(),
            CompanyId: this.backendConfiguration.getCompanyId(),
            ConnectionId: signalrHubConnectionFactoryService.getConnectionId(),
            BrowserInstanceId: this.backendConfiguration.getBrowserInstanceId(),
            'x-requestid': requestId
          };

          if (
            !includes(request.url, 'drawings/images') &&
            !includes(request.url, 'finishingtemplates/label-images/image') &&
            !includes(request.url, 'speedtest') &&
            !includes(request.url, 'training/manage') &&
            !includes(request.url, 'weaving-order/xml') &&
            !includes(request.url, 'weaving-order/xslt') &&
            !includes(request.url, 'weavestructures/manage') &&
            !includes(request.url, 'machinegroups/floorplan')
          ) {
            headers['Content-Type'] = 'application/json';
          }

          if (includes(request.url, 'speedtest')) {
            headers['ngsw-bypass'] = 'true';
          }

          request = request.clone({
            url: request.url,
            setHeaders: headers
          });

          return this.handleRequest(next, request, headers, authentication.getCurrentSubscription());
        })
      );
    } else {
      return next.handle(request);
    }
  }

  private setRequestIdAndUserIdOnErrorLoggingScope(requestId: string, userId: string): void {
    if (!environment.isDebugMode) {
      this.setTagOnErrorLoggingScope('request_id', requestId);
      this.setTagOnErrorLoggingScope('user_id', userId);
    }
  }

  private setTagOnErrorLoggingScope(key: string, value: string): void {
    value = trim(value);

    if (!isEmpty(value)) {
      this.appInsightsLoggingService.setTag(key, value);
    }
  }

  private canIntercept(request: HttpRequest<any>): boolean {
    const isAsset = includes(request.url, this.assetsPath);
    const isApplicationEnvironment = includes(request.url, this.applicationEnvironmentName);
    const isRegistering = includes(request.url, this.registerPath);

    return !isApplicationEnvironment && !isAsset && !this.isMediaUrl(request.url) && !this.isMediaContent(request.url) && !isRegistering;
  }

  private handleRequest(next: HttpHandler, request: HttpRequest<any>, headers: any, subscription: Subscription): Observable<any> {
    this.checkForSlowConnection(request);

    return next.handle(request).pipe(
      (source: Observable<HttpEvent<any>>) => {
        return request.context.get(CANCEL_REQUEST) ? source.pipe(takeUntil(this.httpRequestManagerService.onCancelPendingRequests())) : source;
      },

      retry({
        delay: (error: HttpErrorResponse, attempt: number) => {
          if (isEqual(error.status, 503) && attempt <= this.backendConfiguration.getRetryAttempts503()) {
            return timer(500);
          }

          if (headers.SubscriptionId !== '37ed5ea1-be18-47a4-b461-4952e43aca95') {
            throw error;
          }
        }
      }),

      catchError((error: HttpErrorResponse) => {
        let result = of(null);
        const severity = this.getErrorSeverity(request);

        //TODO(https://vandewiele.atlassian.net/browse/BMSMC-2537)
        const shouldThrowError = headers.SubscriptionId !== '37ed5ea1-be18-47a4-b461-4952e43aca95';

        if (shouldThrowError && !this.navigationService.checkIsSwitchingSubscription(subscription)) {
          if (isEqual(error.status, 400)) {
            result = throwError(() => new UnhandledBackendError(severity, this.translate.instant('GENERAL.ERRORS.BACKEND.BAD_REQUEST'), error.error ?? null));
          } else if (isEqual(error.status, 403)) {
            result = throwError(() => new UnhandledBackendError(severity, this.translate.instant('GENERAL.ERRORS.BACKEND.ACCESS_DENIED')));
          } else if (isEqual(error.status, 501)) {
            result = throwError(() => new UnhandledBackendError(severity, this.translate.instant('GENERAL.ERRORS.BACKEND.NOT_IMPLEMENTED')));
          } else {
            if (!isNil(error?.error?.errorCode)) {
              result = this.errorMessageHelper.getErrorMessageFromBackendError(error.error).pipe(
                map((errorMessage: string) => {
                  throw new BackendError(severity, errorMessage, error.error);
                })
              );
            } else if (isEqual(error.status, 404)) {
              result = throwError(() => new UnhandledBackendError(severity, this.translate.instant('GENERAL.ERRORS.STATUSCODE.NOT_FOUND.TITLE'), error));
            } else {
              result = throwError(() => new UnhandledBackendError(severity, this.translate.instant('GENERAL.ERRORS.BACKEND.UNAVAILABLE'), error));
            }
          }
        }

        return result;
      }),

      finalize(() => {
        pull(this.requests, request);

        if (this.canIntercept(request) && isEqual(size(this.requests), 0)) {
          this.connectivityToast.requestEnded();
          clearTimeout(this.connectivityToastTimeout);

          this.canShowConnectivityToast = true;

          this.navigationService.removeIsSwitchingSubscription();
        }
      })
    );
  }

  private getErrorSeverity(request: HttpRequest<any>): BackendErrorSeverity {
    let result = BackendErrorSeverity.FATAL;
    const regexDrawing = /drawings\/image\?id=/;
    const regexRecoloredDrawing = /drawings\/images\/[^\/? ]*\/recolored\?/;

    if (regexDrawing.test(request.url) || regexRecoloredDrawing.test(request.url)) {
      result = BackendErrorSeverity.INFO;
    }
    return result;
  }

  private isMediaUrl(url: string): boolean {
    const fileExtension = getFileExtension(url);
    return !isEmpty(fileExtension) && includes(EnumUtils.getEnumValues(MediaType), fileExtension);
  }

  private isMediaContent(url: string): boolean {
    return startsWith(url, 'data:image');
  }

  private checkForSlowConnection(request: HttpRequest<any>): void {
    if (this.canIntercept(request)) {
      this.requests.push(request);

      if (this.canShowConnectivityToast) {
        clearTimeout(this.connectivityToastTimeout);

        this.canShowConnectivityToast = false;

        this.connectivityToastTimeout = setTimeout(() => {
          this.connectivityToast.show();
        }, this.timeoutDurationForSlowConnection);
      }
    }
  }
}
