import {Component, Input} from '@angular/core';

@Component({
  selector: 'vdw-color-picker-form-input',
  templateUrl: './color-picker-form-input.component.html',
  styleUrls: ['./color-picker-form-input.component.scss']
})
export class ColorPickerFormInputComponent {
  @Input() public selectedColor: string;
}
