// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  min-height: 150px;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/custom-components/reload-required/reload-required.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;AACF","sourcesContent":[":host {\n  height: 100%;\n  min-height: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
