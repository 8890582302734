// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  width: 100%;
  overflow: auto;
  display: flex;
}
:host app-placeholder-parts {
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/change-placeholder-parts-dialog/change-placeholder-parts-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,aAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[":host {\n  position: relative;\n  width: 100%;\n  overflow: auto;\n  display: flex;\n\n  app-placeholder-parts {\n    overflow-y: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
