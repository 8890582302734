// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 24px;
}
:host .cell-wrapper {
  display: flex;
  align-items: center;
  height: 24px;
}
:host .button-small {
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}
:host .button-large {
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/grids/input/progress-cell-renderer/progress-cell-renderer.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAEE;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAGE;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AADJ","sourcesContent":[":host {\n  height: 24px;\n\n  .cell-wrapper {\n    display: flex;\n    align-items: center;\n    height: 24px;\n  }\n\n  .button-small {\n    height: 20px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n  }\n\n  .button-large {\n    height: 32px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
