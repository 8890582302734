import {ChangeDetectorRef, EventEmitter, Injectable, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {AdvancedSearchUtils} from '@application/helper/advanced-search-utils';
import {BackendErrorCodeEnum} from '@application/helper/backend-error-code.enum';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNewItemData} from '@application/helper/navigation-helper/navigation-new-item-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {Article} from '@domain/article/article';
import {ConflictType} from '@domain/conflicts/conflict-type';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {PropertyValue} from '@domain/property-value';
import {SalesOrder} from '@domain/sales-order/sales-order';
import {WeaveQuality} from '@domain/textile-data/machine-quality/weave-quality';
import {MultiDeleteResponse} from '@domain/textile-data/multi-delete-reponse';
import {PageUrls} from '@domain/textile-data/page-urls';
import {DetailTextileData, OverviewListTextileData, TextileDataService, TextileDataWithMultiDeleteService} from '@domain/textile-data/textile-data';
import {Translations} from '@domain/textile-data/translations';
import {ConflictsRepository} from '@infrastructure/http/conflicts-repository';
import {GridModelRepository} from '@infrastructure/http/grid-model-repository';
import {WeaveStructures} from '@infrastructure/http/weave-structure/weave-structures';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {AdvancedSearchInput} from '@presentation/components/search-filters/advanced-search/advanced-search-input.enum';
import {AdvancedSearchDialogComponent} from '@presentation/components/search-filters/advanced-search/dialog/advanced-search-dialog.component';
import {SearchFiltersComponent} from '@presentation/components/search-filters/search-filters.component';
import {OverviewListOrderLine} from '@presentation/pages/texfab/orderbook/overview/overview-list-order-line';
import {OverviewListProductionOrderWeaving} from '@presentation/pages/texfab/production-order-lite/overview/overview-list-production-order-weaving';
import {OverviewListSalesOrder} from '@presentation/pages/texfab/sales-order/overview/overview-list-sales-order';
import {
  AgGridRowSelection,
  AlertDialogResult,
  AssertionUtils,
  BackendError,
  BaseComponent,
  Conflict,
  ConflictsDialogComponent,
  ConflictsDialogData,
  Device,
  DialogBuilder,
  DialogBuilderFactoryService,
  DialogType,
  FilterComponent,
  GridModel,
  GridOptionsBuilder,
  GridOptionsBuilderFactoryService,
  LoadingCellOverlayComponent,
  LoadingCellOverlayComponentParams,
  MobileColDef,
  NoDataOverlayComponentParams,
  OverlayComponentParamsAction,
  ResponsivenessViewMode,
  SaveType,
  ToastService,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {
  ColDef,
  FirstDataRenderedEvent,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IMultiFilterModel,
  IServerSideDatasource,
  RowClassParams,
  RowClickedEvent,
  SortModelItem
} from 'ag-grid-community';
import {find, isEmpty, isNil, reduce, remove, size, some} from 'lodash-es';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {debounceTime, map, switchMap, takeUntil} from 'rxjs/operators';
import {OverviewListMachineQuality} from '../machine-quality/overview/overview-list-machine-quality';
import {TextileDataType} from '../textile-data-type.enum';

@Injectable({providedIn: 'root'})
export class TextileService extends BaseComponent {
  private gridModelForTextileDataOverviewFromService: GridApi;
  private gridOptions: GridOptions;
  private readonly textileDataTypesToEmitEventForOnAction = new Map<keyof PageUrls, TextileDataType[]>([
    ['add', [TextileDataType.COMPANY_WEAVE_STRUCTURE, TextileDataType.EVENT_ALERT, TextileDataType.FINISHING, TextileDataType.WEAVE_PATTERN]],
    ['duplicate', [TextileDataType.EVENT_ALERT, TextileDataType.QUALITY_FINISHING]],
    ['edit', [TextileDataType.EVENT_ALERT, TextileDataType.COMPANY_WEAVE_STRUCTURE, TextileDataType.QUALITY_FINISHING]]
  ]);

  private readonly textileDataTypesToSaveNavigationNewItemData = [
    TextileDataType.WEAVE_PRODUCT,
    TextileDataType.COLOR_SET,
    TextileDataType.YARN_TYPE,
    TextileDataType.YARN_SET,
    TextileDataType.TUFT_PRODUCT,
    TextileDataType.MACHINE_QUALITY,
    TextileDataType.PATH_LAYOUT_TEMPLATE,
    TextileDataType.CREEL,
    TextileDataType.WEFT_COLORED_YARN_SET,
    TextileDataType.CUSTOMER,
    TextileDataType.ARTICLE,
    TextileDataType.RESOURCE_TYPE,
    TextileDataType.COLOR,
    TextileDataType.DATA_UNIT_SETUP
  ];

  private readonly OVERLAY_NO_DATA_SCALE_IF_ACTIONS_ARE_PRESENT = 0.6;

  private gridResizable: boolean;
  private disableActionButton: boolean;
  private dialogBuilder: DialogBuilder;
  private exportData: OverviewListTextileData[] = [];
  private multiSelection: boolean;
  private previousCheckboxSelection = false;

  public constructor(
    private readonly router: Router,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly toastService: ToastService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly navigationHelperService: NavigationHelperService<TextileDataType>
  ) {
    super();
  }

  public getGridApi(): GridApi {
    return this.gridModelForTextileDataOverviewFromService;
  }

  public resetGridModelForTextileDataOverviewFromService(): void {
    this.gridModelForTextileDataOverviewFromService = null;
  }

  public executeActionForItem(action: keyof PageUrls, itemId: number | string, textileDataType: TextileDataType, eventEmitter: EventEmitter<any>, managedRoute: boolean = false): void {
    if (this.textileDataTypesToEmitEventForOnAction.get(action).includes(textileDataType)) {
      eventEmitter.emit(action === 'add' ? undefined : this.gridModelForTextileDataOverviewFromService.getSelectedRows()[0]);
    } else {
      const pageUrl = LastModifiedCardUtils.getPageUrls(textileDataType)[action];
      if (action === 'add') {
        this.navigateToAddPage(pageUrl, textileDataType, managedRoute);
      } else {
        this.router.navigate([pageUrl.replace(':id', itemId.toString())]);
      }
    }
  }

  public canDisableDeleteButton(disableDeleteButton: boolean, textileDataType: TextileDataType, isSingleTextileDataRowSelected: boolean, selectedItem: OverviewListTextileData): boolean {
    let result = disableDeleteButton;

    if (textileDataType === TextileDataType.PRODUCTION_ORDER_WEAVING && isSingleTextileDataRowSelected) {
      const selectedProductionOrder = selectedItem as OverviewListProductionOrderWeaving;
      result = selectedProductionOrder.status !== ProductionScheduleStatus.CREATED && selectedProductionOrder.status !== ProductionScheduleStatus.TO_PLAN;
    }

    return result;
  }

  public removeConfirmation(
    selected: OverviewListTextileData | DetailTextileData | OverviewListTextileData[] | DetailTextileData[],
    textileDataType: TextileDataType,
    managedRoute: boolean,
    deleteItem: EventEmitter<number | string>,
    textileDataService: TextileDataService,
    selectedTextileDataItems?: OverviewListTextileData[],
    translations?: Translations,
    reloadData?: EventEmitter<PropertyValue[]>,
    advancedSearchFilters?: PropertyValue[],
    infiniteGrid?: boolean,
    rowsSelected?: EventEmitter<any>,
    amountOfTotalCreatedTextileDataItemsSubject?: BehaviorSubject<number>,
    multiDelete: boolean = false
  ): void {
    if (AssertionUtils.isNullOrUndefined(translations)) {
      translations = LastModifiedCardUtils.getTranslations(textileDataType);
    }

    let object = this.getConfirmationDeleteMessage(selected, translations);
    let selectedObject: OverviewListTextileData | DetailTextileData | OverviewListTextileData[] | DetailTextileData[];
    if (!Array.isArray(selected)) {
      selectedObject = selected;
    } else if (selected.length === 1) {
      selectedObject = selected[0];
    }
    if (!AssertionUtils.isNullOrUndefined(selectedObject)) {
      if ('name' in selectedObject) {
        object = selectedObject.name;
      } else if ('technicalName' in selectedObject) {
        object = selectedObject.technicalName;
      }
    }

    const hasMultipleSelected = Array.isArray(selected) && selected.length > 1;

    this.dialogBuilderFactoryService
      .getBuilder()
      .openAlertDialog({
        titleText: this.translate.instant('GENERAL.ACTIONS.DELETE_OBJECT', {object}),
        messageText: this.translate.instant('GENERAL.ACTIONS.CONFIRM_DELETE_DESCRIPTION'),
        entities: hasMultipleSelected
          ? selected.map((data: OverviewListTextileData | DetailTextileData) => ({
              name: (data as Article).name,
              route: LastModifiedCardUtils.getPageUrls(textileDataType).edit.replace(':id', data.id.toString())
            }))
          : null,
        type: DialogType.CONFIRM_DELETE
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((result: AlertDialogResult) => {
        if (result === AlertDialogResult.CONFIRM) {
          let selectedRows = this.getSelectedRows();
          if (AssertionUtils.isEmpty(selectedRows)) {
            selectedRows = Array.isArray(selected) ? selected : [selected];
          }

          this.deleteSelectedTextileDataItem(
            selected,
            textileDataType,
            translations,
            managedRoute,
            deleteItem,
            textileDataService,
            reloadData,
            advancedSearchFilters,
            infiniteGrid,
            selectedRows,
            rowsSelected,
            multiDelete || hasMultipleSelected,
            selectedTextileDataItems,
            amountOfTotalCreatedTextileDataItemsSubject
          );
        }
      });
  }

  public exportAllData(filterModel: IMultiFilterModel, exportGridApi: GridApi, textileDataService: TextileDataService, columnDefs: MobileColDef[]): Promise<void> {
    return new Promise((resolve: () => void) => {
      this.dialogBuilder = this.dialogBuilderFactoryService.getBuilder();
      this.dialogBuilder.openAlertDialog({
        titleText: 'AGGRID.EXPORT',
        messageText: 'AGGRID.EXPORTMESSAGE',
        type: DialogType.INFORMATION
      });
      exportGridApi.setGridOption('columnDefs', columnDefs);
      const sortModel: SortModelItem[] = [];
      let startRow: number = 0;
      let endRow: number = 30;
      const gridModel: GridModel = new GridModel(startRow, endRow, sortModel, filterModel);

      this.fetchExportData(gridModel, exportGridApi, textileDataService, resolve);
    });
  }

  public showAdvancedSearch(
    iconForSearch: string,
    isTextileDataTypeProductionSchedule: boolean,
    advancedSearchFilters: PropertyValue[],
    filterComponent: FilterComponent,
    pickDensityUnit: Unit,
    reedDensityUnit: Unit,
    searchFilters: SearchFiltersComponent
  ): Promise<void> {
    return new Promise((resolve: () => void) => {
      if (iconForSearch !== 'search') {
        this.dialogBuilderFactoryService
          .getBuilder()
          .withWidth('800px')
          .withMaxHeight('100%')
          .withClass(['reposition-dialog', isTextileDataTypeProductionSchedule ? 'production-schedule-overview-advanced-search' : 'orderbook-overview-advanced-search'])
          .openDialog(RepositionDialogComponent, {
            advancedSearchFilters,
            defaultUnit: Unit.CENTIMETER,
            component: AdvancedSearchDialogComponent,
            sourceElement: filterComponent.iconElement.nativeElement,
            positionOfDialog: PositionOfDialog.BOTTOM,
            pickDensityUnit,
            reedDensityUnit
          })
          .pipe(takeUntil(this.unSubscribeOnViewDestroy))
          .subscribe((advancedSearchFiltersValues: PropertyValue[]) => {
            if (!isEmpty(advancedSearchFilters)) {
              advancedSearchFilters = advancedSearchFiltersValues;
              resolve();
              searchFilters.emitCheckIfFiltersAreTooBigForContainerEvent();
            }
          });
      }
    });
  }

  public updateGrid(changes: SimpleChanges, rowsSelected: EventEmitter<any>, hideOverlay: () => void, changeDetectorRef: ChangeDetectorRef, selectedTextileDataItems: OverviewListTextileData[]): void {
    if (!isNil(this.gridModelForTextileDataOverviewFromService) && !isNil(this.gridOptions) && size(changes.columnDefs.currentValue) !== size(changes.columnDefs.previousValue)) {
      this.deselectRows(rowsSelected, selectedTextileDataItems);
      this.gridModelForTextileDataOverviewFromService.clearFocusedCell();
      if (!this.gridResizable) {
        this.gridModelForTextileDataOverviewFromService.sizeColumnsToFit();
      }
      this.gridModelForTextileDataOverviewFromService.resetRowHeights();
    }

    if (!isNil(this.gridModelForTextileDataOverviewFromService) && !isNil(changes.listOfTextileData) && changes.listOfTextileData.currentValue !== changes.listOfTextileData.previousValue) {
      if (this.gridModelForTextileDataOverviewFromService.getDisplayedRowCount() < 1) {
        this.showNoRowsOverlay();
      } else {
        hideOverlay();
      }

      changeDetectorRef.detectChanges();
      this.gridModelForTextileDataOverviewFromService.redrawRows();
    }

    if (changes.disableActionButton !== undefined) {
      this.disableActionButton = changes.disableActionButton.currentValue;
    }
  }

  public initialiseGridOptionsForTextileDataOverview(
    infiniteGrid: boolean,
    cacheBlockSize: number,
    rowHeight: (params: any) => number,
    autoSizeAllColumns: boolean,
    selectedTextileDataItems: OverviewListTextileData[],
    rowsSelected: EventEmitter<any>,
    loadData: EventEmitter<void>,
    responsivenessViewMode: ResponsivenessViewMode,
    columnDefs: MobileColDef[],
    textileDataType: TextileDataType,
    advancedSearchFilters: PropertyValue[],
    isTextileDataTypeProductionSchedule: boolean,
    changeDetectorRef: ChangeDetectorRef,
    gridIdentifier: GridIdentifier,
    dataSource: IServerSideDatasource,
    disableActionButton: boolean,
    gridResizable: boolean,
    getRowClass: (params: RowClassParams) => string | string[],
    rememberScrollPosition: boolean,
    multiSelection: boolean
  ): GridOptions {
    this.gridOptions = this.initialGridOptions(
      infiniteGrid,
      cacheBlockSize,
      rowHeight,
      autoSizeAllColumns,
      selectedTextileDataItems,
      rowsSelected,
      loadData,
      responsivenessViewMode,
      columnDefs,
      textileDataType,
      advancedSearchFilters,
      isTextileDataTypeProductionSchedule,
      changeDetectorRef,
      gridIdentifier,
      dataSource,
      disableActionButton,
      gridResizable,
      getRowClass,
      rememberScrollPosition,
      multiSelection
    ).build();

    return this.gridOptions;
  }

  public subscribeToFilterChanges(
    filterChangeSubject: Subject<string>,
    iconForSearch: string,
    isTextileDataTypeProductionSchedule: boolean,
    advancedSearchFilters: PropertyValue[],
    searchFilters: SearchFiltersComponent,
    reloadData: EventEmitter<PropertyValue[]>,
    hideOverlay: () => void
  ): void {
    filterChangeSubject
      .asObservable()
      .pipe(debounceTime(500), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((filterText: string) => {
        if (iconForSearch !== 'search' || isTextileDataTypeProductionSchedule) {
          find(advancedSearchFilters, ['propertyName', AdvancedSearchInput.NAME]).propertyValue = filterText;

          if (iconForSearch !== 'search') {
            searchFilters.emitCheckIfFiltersAreTooBigForContainerEvent();
          }

          reloadData.emit(advancedSearchFilters);
        } else {
          this.gridModelForTextileDataOverviewFromService.setGridOption('quickFilterText', filterText);

          if (this.gridModelForTextileDataOverviewFromService.getDisplayedRowCount() < 1) {
            this.showNoRowsOverlay();
          } else {
            hideOverlay();
          }
        }
      });
  }

  public subscribeToDeviceChanges(responsivenessViewMode: ResponsivenessViewMode, columnDefs: MobileColDef[]): void {
    responsivenessViewMode
      .observeDeviceChanges()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((device: Device) => {
        if (!isNil(this.gridModelForTextileDataOverviewFromService)) {
          this.gridModelForTextileDataOverviewFromService.setGridOption('columnDefs', this.getColumnDefs(device === Device.PHONE, columnDefs));
        }
      });
  }

  public setHeaderNameForColumn(columnName: string, headerName: string): void {
    this.gridModelForTextileDataOverviewFromService.getColumnDef(columnName).headerName = headerName;
  }

  public refreshHeader(): void {
    this.gridModelForTextileDataOverviewFromService.refreshHeader();
    Array.from(document.getElementsByClassName('ag-input-field-input')).forEach((obj: any) => {
      if (obj.attributes['disabled']) {
        return;
      }
      obj.setAttribute('placeholder', 'Search');
    });
  }

  public redrawRows(): void {
    this.gridModelForTextileDataOverviewFromService.redrawRows();
  }

  public showNoRowsOverlay(): void {
    this.gridModelForTextileDataOverviewFromService.showNoRowsOverlay();
  }

  public refreshServerSide(purge: boolean): void {
    this.gridModelForTextileDataOverviewFromService.refreshServerSide({purge});
  }

  public ensureIndexVisible(index: number): void {
    this.gridModelForTextileDataOverviewFromService.ensureIndexVisible(index);
  }

  public hideOverlay(): void {
    this.gridModelForTextileDataOverviewFromService.hideOverlay();
  }

  public deselectRows(rowsSelected: EventEmitter<any>, selectedTextileDataItems: OverviewListTextileData[]): void {
    this.gridModelForTextileDataOverviewFromService.deselectAll();
    selectedTextileDataItems.splice(0, selectedTextileDataItems.length);
    rowsSelected.emit(selectedTextileDataItems);
    this.showCheckboxIfNeeded();
  }

  public navigateAndShowToast(saveType: SaveType, textileDataType: TextileDataType, translationKey: string, isEditing: boolean, name: string, id: number = null): void {
    if (saveType === SaveType.SAVE && !isEditing) {
      this.executeActionForItem('edit', id, textileDataType, null, null);
    } else if (saveType === SaveType.SAVE_AND_CLOSE) {
      if (this.textileDataTypesToSaveNavigationNewItemData.includes(textileDataType)) {
        this.navigationHelperService.savePartialState<NavigationNewItemData>({newItemId: id});
      }
      this.navigationHelperService.navigateToPreviousRoute(LastModifiedCardUtils.getPageUrls(textileDataType)['overview']);
    } else if (saveType === SaveType.SAVE_AND_CREATE_NEW) {
      this.executeActionForItem('add', undefined, textileDataType, null, null);
    }

    this.toastService.showInfo({
      tapToDismiss: false,
      timeOut: 2000,
      message: this.translate.instant(isEditing ? 'GENERAL.ACTIONS.EDITED_OBJECT' : 'GENERAL.ACTIONS.CREATED_OBJECT', {
        object: this.translate.instant(translationKey, {count: 1}),
        name,
        count: 1
      })
    });
  }

  public getConflicts(id: string | number, entityName: string, textileDataService: TextileDataService, translations: Translations): void {
    (textileDataService as ConflictsRepository)
      .getConflicts(id)
      .pipe(
        switchMap((conflicts: Conflict[]) => {
          return this.dialogBuilderFactoryService
            .getBuilder()
            .withClass('alert-dialog')
            .openDialog(ConflictsDialogComponent, ConflictsDialogData.createCannotDeleteData(translations.entity, entityName, conflicts, ConflictType));
        }),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe();
  }

  public setLoadingCellRendererParams(loadingCellRendererParams: LoadingCellOverlayComponentParams): void {
    this.gridOptions.loadingCellRendererParams = loadingCellRendererParams;
  }

  private getObjectName(hasMultipleSelected: boolean, selected: OverviewListTextileData | DetailTextileData | OverviewListTextileData[] | DetailTextileData[], translations: Translations): string {
    if (!Array.isArray(selected)) {
      if (selected instanceof OverviewListMachineQuality || selected instanceof WeaveQuality) {
        return selected.technicalName;
      } else if (selected instanceof OverviewListOrderLine) {
        return selected.salesOrderLineReference;
      } else if (selected instanceof SalesOrder || selected instanceof OverviewListSalesOrder) {
        return selected.orderNumber.toString();
      } else {
        return selected.name;
      }
    } else {
      return this.getObjectNameForList(hasMultipleSelected, selected, translations);
    }
  }

  private getObjectNameForList(
    hasMultipleSelected: boolean,
    selected: OverviewListTextileData | DetailTextileData | OverviewListTextileData[] | DetailTextileData[],
    translations: Translations
  ): string {
    if (hasMultipleSelected) {
      return this.translate.instant(translations.delete, {count: 2}).toLowerCase();
    } else if (selected[0] instanceof OverviewListMachineQuality || selected[0] instanceof WeaveQuality) {
      return selected[0].technicalName;
    } else if (selected[0] instanceof OverviewListOrderLine) {
      return selected[0].salesOrderLineReference;
    } else if (selected[0] instanceof SalesOrder || selected[0] instanceof OverviewListSalesOrder) {
      return selected[0].orderNumber.toString();
    } else {
      return selected[0].name;
    }
  }

  private deleteSelectedTextileDataItem(
    selected: OverviewListTextileData | DetailTextileData | OverviewListTextileData[] | DetailTextileData[],
    textileDataType: TextileDataType,
    translations: Translations,
    managedRoute: boolean,
    deleteItem: EventEmitter<number | string>,
    textileDataService: TextileDataService,
    reloadData: EventEmitter<PropertyValue[]>,
    advancedSearchFilters: PropertyValue[],
    infiniteGrid: boolean,
    selectedRows: any[],
    rowsSelected: EventEmitter<any>,
    multiDelete: boolean,
    selectedTextileDataItems: OverviewListTextileData[] = [],
    amountOfTotalCreatedTextileDataItemsSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  ): void {
    const selectedItemId = (Array.isArray(selected) ? selected[0] : selected).id;

    if (this.deleteItemEmit(textileDataType)) {
      deleteItem.emit(selectedItemId);
    } else if (textileDataType === TextileDataType.WEAVE_PATTERN || textileDataType === TextileDataType.EVENT_ALERT) {
      deleteItem.emit(selectedItemId);
      this.textileDataItemHasBeenRemoved(reloadData, advancedSearchFilters, infiniteGrid, selectedRows, translations, rowsSelected, textileDataType, selectedTextileDataItems, selected);
    } else {
      let deleteRequest: Observable<MultiDeleteResponse[]>;

      if (multiDelete && Array.isArray(selected)) {
        deleteRequest = (textileDataService as TextileDataWithMultiDeleteService).deleteMulti(selected.map((value: OverviewListTextileData | DetailTextileData) => value.id) as number[]);
      } else if (textileDataType === TextileDataType.WEAVE_STRUCTURE && managedRoute) {
        deleteRequest = (textileDataService as WeaveStructures).manageDelete(Number(selectedItemId)).pipe(map(() => []));
      } else {
        deleteRequest = textileDataService.delete(selectedItemId).pipe(map(() => []));
      }

      deleteRequest.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe({
        next: (multiDeleteResponses: MultiDeleteResponse[]) => {
          this.handleDeletionResponse(
            selectedItemId,
            multiDeleteResponses,
            textileDataType,
            translations,
            deleteItem,
            textileDataService,
            reloadData,
            advancedSearchFilters,
            infiniteGrid,
            selectedRows,
            rowsSelected,
            multiDelete,
            selectedTextileDataItems,
            amountOfTotalCreatedTextileDataItemsSubject,
            selected
          );
        },
        error: (errorMessage: BackendError) => {
          this.handleDeletionErrorResponse(errorMessage, selectedItemId, textileDataType, textileDataService, translations);
        }
      });
    }
  }

  private handleDeletionResponse(
    selectedItemId: string | number,
    multiDeleteResponses: MultiDeleteResponse[],
    textileDataType: TextileDataType,
    translations: Translations,
    deleteItem: EventEmitter<number | string>,
    textileDataService: TextileDataService,
    reloadData: EventEmitter<PropertyValue[]>,
    advancedSearchFilters: PropertyValue[],
    infiniteGrid: boolean,
    selectedRows: any[],
    rowsSelected: EventEmitter<any>,
    multiDelete: boolean,
    selectedTextileDataItems: OverviewListTextileData[],
    amountOfTotalCreatedTextileDataItemsSubject: BehaviorSubject<number>,
    selected: OverviewListTextileData | DetailTextileData | OverviewListTextileData[] | DetailTextileData[]
  ): void {
    if (deleteItem && !multiDelete) {
      deleteItem.emit(selectedItemId);
    }

    let deletedRows = selectedRows;

    if (multiDeleteResponses.length > 0) {
      const succesfullDeletedIds = multiDeleteResponses
        .filter((multiDeleteResponse: MultiDeleteResponse) => multiDeleteResponse.statusCode === 200)
        .map((multiDeleteResponse: MultiDeleteResponse) => multiDeleteResponse.id);

      if (succesfullDeletedIds.length !== selectedRows.length) {
        this.handleConflics(multiDeleteResponses, succesfullDeletedIds, textileDataService, selectedRows, rowsSelected, translations, selectedTextileDataItems);
      }

      deletedRows = deletedRows.filter((row: any) => succesfullDeletedIds.includes(row.id));
    }

    amountOfTotalCreatedTextileDataItemsSubject?.next(amountOfTotalCreatedTextileDataItemsSubject.value - deletedRows.length);
    if ((multiDelete && deletedRows.length > 0) || !multiDelete) {
      this.textileDataItemHasBeenRemoved(reloadData, advancedSearchFilters, infiniteGrid, selectedRows, translations, rowsSelected, textileDataType, selectedTextileDataItems, selected);
    }
  }

  private handleDeletionErrorResponse(
    errorMessage: BackendError,
    selectedItemId: string | number,
    textileDataType: TextileDataType,
    textileDataService: TextileDataService,
    translations: Translations
  ): void {
    if (BackendErrorCodeEnum[errorMessage.errorContext.errorCode as string] === BackendErrorCodeEnum.LINKED_ENTITIES && LastModifiedCardUtils.canShowConflictsDialog(textileDataType)) {
      this.getConflicts(selectedItemId, errorMessage.errorContext.entityName, textileDataService, translations);
    } else {
      this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
        titleText: this.translate.instant('GENERAL.ACTIONS.DELETE_OBJECT', {object: this.translate.instant(translations.entity, {count: 1}).toLowerCase()}),
        messageText: errorMessage.errorContext?.messages?.length > 0 ? errorMessage.errorContext.messages[0] : errorMessage.message,
        type: DialogType.INFORMATION
      });
    }
  }

  private handleConflics(
    multiDeleteResponses: MultiDeleteResponse[],
    succesfullDeletedIds: number[],
    textileDataService: TextileDataService,
    selectedRows: any[],
    rowsSelected: EventEmitter<any>,
    translations: Translations,
    selectedTextileDataItems: OverviewListTextileData[]
  ): void {
    const unsuccesfullDeletedIds = multiDeleteResponses.filter((multiDeleteResponse: MultiDeleteResponse) => !succesfullDeletedIds.includes(multiDeleteResponse.id));

    forkJoin(unsuccesfullDeletedIds.map((multiDeleteResponse: MultiDeleteResponse) => (textileDataService as ConflictsRepository).getConflicts(multiDeleteResponse.id)))
      .pipe(
        switchMap((conflicts: Conflict[][]) => {
          const entities = selectedRows.filter((row: any) => !succesfullDeletedIds.includes(row.id)).map((row: any) => row.name);

          this.deselectRows(rowsSelected, selectedTextileDataItems);

          return this.dialogBuilderFactoryService
            .getBuilder()
            .withClass('alert-dialog')
            .openDialog(ConflictsDialogComponent, ConflictsDialogData.createCannotDeleteData(translations.entity, entities, conflicts, ConflictType));
        }),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe();
  }

  private textileDataItemHasBeenRemoved(
    reloadData: EventEmitter<PropertyValue[]>,
    advancedSearchFilters: PropertyValue[],
    infiniteGrid: boolean,
    selectedRows: any[],
    translations: Translations,
    rowsSelected: EventEmitter<any>,
    textileDataType: TextileDataType,
    selectedTextileDataItems: OverviewListTextileData[],
    selected: OverviewListTextileData | DetailTextileData | OverviewListTextileData[] | DetailTextileData[]
  ): void {
    const hasMultipleSelected = Array.isArray(selected) && selected.length > 1;

    if (reloadData) {
      reloadData.emit(advancedSearchFilters);

      if (!infiniteGrid) {
        this.gridModelForTextileDataOverviewFromService.applyTransaction({
          remove: selectedRows
        });
      }

      this.deselectRows(rowsSelected, selectedTextileDataItems);
    } else {
      this.navigationHelperService.navigateToPreviousRoute(LastModifiedCardUtils.getPageUrls(textileDataType)['overview']);
    }

    if (hasMultipleSelected) {
      this.toastService.showInfo({
        tapToDismiss: false,
        timeOut: 2000,
        message: this.translate.instant('GENERAL.ACTIONS.DELETED_OBJECT_WITH_AMOUNT', {
          object: this.getObjectName(hasMultipleSelected, selected, translations),
          amount: selected.length,
          count: 1
        })
      });
    } else {
      this.toastService.showInfo({
        tapToDismiss: false,
        timeOut: 2000,
        message: this.translate.instant('GENERAL.ACTIONS.DELETED_OBJECT', {
          object: `${this.translate.instant(translations.delete, {count: 1})} ${this.getObjectName(hasMultipleSelected, selected, translations)}`,
          count: 1
        })
      });
    }
  }

  private resizeGrid(autoSizeAllColumns: boolean, gridResizable: boolean): void {
    if (!isNil(this.gridModelForTextileDataOverviewFromService) && !gridResizable) {
      this.gridModelForTextileDataOverviewFromService.sizeColumnsToFit();
    }
    if (autoSizeAllColumns && !gridResizable) {
      this.gridModelForTextileDataOverviewFromService.autoSizeAllColumns();
    }
  }

  private showCheckboxIfNeeded(): void {
    const selectedRows = this.gridModelForTextileDataOverviewFromService.getSelectedRows();
    if (this.multiSelection) {
      const columnDefs = this.gridModelForTextileDataOverviewFromService.getColumnDefs();
      let newCheckboxSelection = null;

      columnDefs.forEach((colDef: ColDef, index: number) => {
        if (index === 0) {
          colDef.checkboxSelection = selectedRows.length > 1;
          newCheckboxSelection = colDef.checkboxSelection;
        }
      });

      if (this.previousCheckboxSelection !== newCheckboxSelection) {
        this.gridModelForTextileDataOverviewFromService.setGridOption('columnDefs', columnDefs);
        this.gridModelForTextileDataOverviewFromService.refreshCells();
      }

      this.previousCheckboxSelection = newCheckboxSelection;
    }
  }

  private onRowClicked(event: RowClickedEvent, selectedTextileDataItems: OverviewListTextileData[], rowsSelected: EventEmitter<any>, changeDetectorRef: ChangeDetectorRef): void {
    this.showCheckboxIfNeeded();

    const target = event.event.target as HTMLElement;

    if (event?.node?.data && !Object.values(target.classList).includes('color-preview')) {
      const textileDataOfRow = event.node.data;

      if (event.node.isSelected() && !some(selectedTextileDataItems, {id: textileDataOfRow.id})) {
        if (this.getGridApi().getGridOption('rowSelection') === AgGridRowSelection.MULTIPLE) {
          selectedTextileDataItems.push(textileDataOfRow);
        } else {
          selectedTextileDataItems[0] = textileDataOfRow;
        }

        rowsSelected.emit(selectedTextileDataItems);
      } else {
        if (selectedTextileDataItems.length > 1) {
          remove(selectedTextileDataItems, {id: textileDataOfRow.id});
          rowsSelected.emit(selectedTextileDataItems);
        } else {
          this.deselectRows(rowsSelected, selectedTextileDataItems);
        }
      }

      changeDetectorRef.markForCheck();
    }
  }

  private onGridReadyEvent(params: GridReadyEvent, infiniteGrid: boolean, loadData: EventEmitter<void>, gridResizable: boolean): void {
    this.gridModelForTextileDataOverviewFromService = params.api;
    if (!gridResizable) {
      this.gridModelForTextileDataOverviewFromService.sizeColumnsToFit();
    }

    if (infiniteGrid) {
      loadData.emit();
    }
  }

  private getColumnDefs(isPhone: boolean, columnDefs: MobileColDef[]): ColDef[] {
    return reduce(
      columnDefs,
      (columnDefsValue: ColDef[], textileDataOverviewColDef: MobileColDef) => {
        if ((isPhone && textileDataOverviewColDef.mobile) || !isPhone) {
          const {mobile, ...columnDef} = textileDataOverviewColDef;

          columnDefsValue.push(columnDef);
        }

        return columnDefsValue;
      },
      []
    );
  }

  private getOverlayNoDataCreateParamKey(textileDataType: TextileDataType): string {
    return {
      [TextileDataType.PRODUCTION_SCHEDULE]: 'PRODUCTION_ORDER.PRODUCTION_ORDER',
      [TextileDataType.COLOR_SET]: 'TEXTILE_DATA.COLOR_SET.COLOR_SET',
      [TextileDataType.COLOR]: 'TEXTILE_DATA.COLOR.COLOR',
      [TextileDataType.COLORED_YARN_SET]: 'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET',
      [TextileDataType.WEFT_COLORED_YARN_SET]: 'TEXTILE_DATA.WEFT_COLORED_YARN_SET.WEFT_COLORED_YARN_SET',
      [TextileDataType.CREEL]: 'TEXTILE_DATA.CREEL.CREEL',
      [TextileDataType.FINISHING_TEMPLATE]: 'TEXTILE_DATA.FINISHING_TEMPLATE.FINISHING_TEMPLATE',
      [TextileDataType.FINISHING]: 'TEXTILE_DATA.FINISHING.FINISHING',
      [TextileDataType.MACHINE_QUALITY]: 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY',
      [TextileDataType.QUALITY_FINISHING]: 'TEXTILE_DATA.FINISHING.FINISHING',
      [TextileDataType.YARN_SET]: 'TEXTILE_DATA.YARN_SET.YARN_SET',
      [TextileDataType.YARN_TYPE]: 'TEXTILE_DATA.YARN_TYPE.YARN_TYPE',
      [TextileDataType.ORDERBOOK]: 'SALES_ORDERS.ORDER_LINES.ORDER_LINE',
      [TextileDataType.COMPANY_WEAVE_STRUCTURE]: 'MACHINE.MACHINE',
      [TextileDataType.WEAVE_PRODUCT]: 'TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT',
      [TextileDataType.PRODUCT_CONFIGURATION]: 'PRODUCT_CONFIGURATION.PRODUCT_CONFIGURATION',
      [TextileDataType.WEAVE_STRUCTURE]: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE',
      [TextileDataType.WEAVE_PATTERN]: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_PATTERN',
      [TextileDataType.DATA_UNIT_SETUP_CATALOG]: 'BMSCONFIG.DATA_UNIT_SETUP.DATA_UNIT_SETUP',
      [TextileDataType.EVENT]: 'BMSCONFIG.EVENTS.EVENT',
      [TextileDataType.EVENT_ALERT]: 'BMSCONFIG.EVENTS.ALERT',
      [TextileDataType.DEVICE_TEMPLATE_COUNTER]: 'BMSCONFIG.DEVICE_TEMPLATES.COUNTERS.COUNTERS',
      [TextileDataType.DEVICE_TEMPLATE_COUNTER_CATALOG]: 'BMSCONFIG.DEVICE_TEMPLATES.COUNTERS.COUNTERS',
      [TextileDataType.DEVICE_TEMPLATE_CONFIGURATION]: 'BMSCONFIG.DEVICE_TEMPLATES.CONFIGURATION.CONFIGURATIONS',
      [TextileDataType.DEVICE_TEMPLATE_CONFIGURATION_CATALOG]: 'BMSCONFIG.DEVICE_TEMPLATES.CONFIGURATION.CONFIGURATIONS'
    }[textileDataType];
  }

  private getOverlayNoDataActions(textileDataType: TextileDataType): OverlayComponentParamsAction[] {
    const addAction: OverlayComponentParamsAction = {
      key: textileDataType,
      titleKey: this.translate.instant('GENERAL.ACTIONS.ADD_OBJECT', {object: this.translate.instant(this.getOverlayNoDataCreateParamKey(textileDataType) ?? '', {count: 1})?.toLowerCase()}),
      disabled: () => this.disableActionButton,
      isPrimary: true
    };

    return {
      [TextileDataType.EVENT]: [addAction],
      [TextileDataType.EVENT_ALERT]: [addAction],
      [TextileDataType.QUALITY_FINISHING]: [addAction],
      [TextileDataType.DEVICE_TEMPLATE_COUNTER]: [addAction],
      [TextileDataType.DEVICE_TEMPLATE_COUNTER_CATALOG]: [addAction],
      [TextileDataType.DEVICE_TEMPLATE_CONFIGURATION]: [addAction],
      [TextileDataType.DEVICE_TEMPLATE_CONFIGURATION_CATALOG]: [addAction]
    }[textileDataType];
  }

  private getOverlayNoDataHideDescriptionCreateParamKey(textileDataType: TextileDataType): boolean {
    return {
      [TextileDataType.COMPANY_WEAVE_STRUCTURE]: true,
      [TextileDataType.EVENT]: true,
      [TextileDataType.EVENT_ALERT]: true,
      [TextileDataType.QUALITY_FINISHING]: true,
      [TextileDataType.DEVICE_TEMPLATE_COUNTER]: true,
      [TextileDataType.DEVICE_TEMPLATE_COUNTER_CATALOG]: true,
      [TextileDataType.DEVICE_TEMPLATE_CONFIGURATION]: true,
      [TextileDataType.DEVICE_TEMPLATE_CONFIGURATION_CATALOG]: true
    }[textileDataType];
  }

  private deleteItemEmit(textileDataType: TextileDataType): boolean {
    return {
      [TextileDataType.QUALITY_FINISHING]: true,
      [TextileDataType.DEVICE_TEMPLATE_COUNTER]: true,
      [TextileDataType.DEVICE_TEMPLATE_COUNTER_CATALOG]: true,
      [TextileDataType.DEVICE_TEMPLATE_CONFIGURATION]: true,
      [TextileDataType.DEVICE_TEMPLATE_CONFIGURATION_CATALOG]: true
    }[textileDataType];
  }

  private hasFilterActive(advancedSearchFilters: PropertyValue[]): boolean {
    return advancedSearchFilters.some((searchFilter: PropertyValue) => AdvancedSearchUtils.hasValue(searchFilter));
  }

  private getSelectedRows(): any[] {
    return this.gridModelForTextileDataOverviewFromService?.getSelectedRows() ?? [];
  }

  private initialGridOptions(
    infiniteGrid: boolean,
    cacheBlockSize: number,
    rowHeight: (params: any) => number,
    autoSizeAllColumns: boolean,
    selectedTextileDataItems: OverviewListTextileData[],
    rowsSelected: EventEmitter<any>,
    loadData: EventEmitter<void>,
    responsivenessViewMode: ResponsivenessViewMode,
    columnDefs: MobileColDef[],
    textileDataType: TextileDataType,
    advancedSearchFilters: PropertyValue[],
    isTextileDataTypeProductionSchedule: boolean,
    changeDetectorRef: ChangeDetectorRef,
    gridIdentifier: GridIdentifier,
    dataSource: IServerSideDatasource,
    disableActionButton: boolean,
    gridResizable: boolean,
    getRowClass: (params: RowClassParams) => string | string[],
    rememberScrollPosition: boolean,
    multiSelection: boolean
  ): GridOptionsBuilder {
    this.gridResizable = gridResizable;
    this.disableActionButton = disableActionButton;
    this.multiSelection = multiSelection;

    const gridOptions = this.gridOptionsBuilderFactoryService
      .getBuilder(this.getColumnDefs(responsivenessViewMode.isPhone, columnDefs), gridIdentifier, undefined, this.gridResizable)
      .withGetRowId((params: GetRowIdParams) => params.data.id)
      .withGetRowHeight(rowHeight)
      .withOnGridSizeChanged(() => this.resizeGrid(autoSizeAllColumns, this.gridResizable))
      .withOnRowClicked((event: RowClickedEvent) => this.onRowClicked(event, selectedTextileDataItems, rowsSelected, changeDetectorRef))
      .withOnGridReady((params: GridReadyEvent) => this.onGridReadyEvent(params, infiniteGrid, loadData, this.gridResizable))
      .withLoadingCellRenderer('customLoadingCellRenderer')
      .withComponents({
        customLoadingCellRenderer: LoadingCellOverlayComponent
      })
      .withNoRowsOverlay({
        titleParam: this.getOverlayNoDataCreateParamKey(textileDataType),
        hideDescription: this.getOverlayNoDataHideDescriptionCreateParamKey(textileDataType),
        actions: this.getOverlayNoDataActions(textileDataType),
        scale: this.getOverlayNoDataActions(textileDataType) ? this.OVERLAY_NO_DATA_SCALE_IF_ACTIONS_ARE_PRESENT : 1,
        filterDescriptionParam: {
          paramKeyCreate: this.getOverlayNoDataCreateParamKey(textileDataType)
        },
        isAnyFilterPresent: () => this.gridModelForTextileDataOverviewFromService?.isAnyFilterPresent() || this.hasFilterActive(advancedSearchFilters)
      } as NoDataOverlayComponentParams)
      .withHeaderHeight(40)
      .withOnFirstDataRendered(({api}: FirstDataRenderedEvent) => (autoSizeAllColumns && !this.gridResizable ? api.autoSizeAllColumns() : null))
      .withGetRowClass(getRowClass);

    if (!infiniteGrid) {
      gridOptions.withLoadingOverlay();
    }

    if (dataSource) {
      gridOptions.withServerSideDataSource(cacheBlockSize, dataSource);
    }

    if (rememberScrollPosition) {
      gridOptions.withRememberStateForNavigationHelper();
    }

    if (multiSelection) {
      gridOptions.withRowSelection(true, false, false, AssertionUtils.isNullOrUndefined(dataSource));
    }

    return gridOptions;
  }

  private navigateToAddPage(pageUrl: string, textileDataType: TextileDataType, managedRoute: boolean): void {
    if (textileDataType === TextileDataType.WEAVE_STRUCTURE && managedRoute) {
      this.router.navigateByUrl(RouteUtils.paths.texStyle.weaveStructure.manageAddWeaveStructure.absolutePath);
    } else {
      this.router.navigateByUrl(pageUrl);
    }
  }

  private fetchExportData(gridModel: GridModel, exportGridApi: GridApi, textileDataService: TextileDataService, resolve: () => void): void {
    (textileDataService as GridModelRepository<OverviewListTextileData>)
      .get(gridModel)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfTextileData: any[]) => {
        listOfTextileData.forEach((color: any) => {
          this.exportData.push(color);
        });
        if (listOfTextileData.length < 29) {
          exportGridApi.updateGridOptions({
            rowData: this.exportData,
            cacheBlockSize: this.exportData.length
          });
          exportGridApi.exportDataAsCsv();
          this.exportData = [];
          resolve();
          this.dialogBuilder.close();
        } else {
          gridModel.startRow += 30;
          gridModel.endRow += 30;
          this.fetchExportData(gridModel, exportGridApi, textileDataService, resolve);
        }
      });
  }

  private getConfirmationDeleteMessage(selected: OverviewListTextileData | DetailTextileData | OverviewListTextileData[] | DetailTextileData[], translations: Translations): string {
    if (!Array.isArray(selected) || (Array.isArray(selected) && selected.length < 2)) {
      return this.translate.instant(translations.delete, {count: 1}).toLowerCase();
    }

    return `${selected.length} ${this.translate.instant(translations.delete, {count: 2}).toLowerCase()}`;
  }
}
