// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resizer {
  z-index: 2;
  left: 232px;
  width: 16px;
  height: 100%;
  cursor: w-resize;
  position: absolute;
}
.resizer.no-interaction {
  cursor: default;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/navigation/navigation-resizer/navigation-resizer.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;EACA,oBAAA;AACJ","sourcesContent":[".resizer {\n  z-index: 2;\n  left: 232px;\n  width: 16px;\n  height: 100%;\n  cursor: w-resize;\n  position: absolute;\n\n  &.no-interaction {\n    cursor: default;\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
