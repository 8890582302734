import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {IdNameDescription} from '@domain/id-name-description';
import {AutomaticStopGroup} from '@domain/machine/automatic-stop-group';
import {DeclarationGroup} from '@domain/machine/declaration-group';
import {MachineType} from '@domain/machine/machine-type.enum';
import {ReportItem} from '@domain/machine/report-item';
import {ReportItemTemplateField} from '@domain/report-data-set/report-item-template-field.enum';
import {HttpAutomaticStopGroupsService} from '@infrastructure/http/automatic-stop-group/http-automatic-stop-groups.service';
import {DECLARATIONS, Declarations} from '@infrastructure/http/declaration/declarations';
import {HttpReportItemsService} from '@infrastructure/http/report-item/http-report-items.service';
import {
  BaseComponent,
  ColDefBuilderFactoryService,
  EnumUtils,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  SelectGridDialog,
  TranslateService
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, GridApi, GridOptions, RowNode} from 'ag-grid-community';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './select-report-item.component.html'
})
export class SelectReportItemComponent extends BaseComponent implements OnInit, AfterViewInit, SelectGridDialog {
  @ViewChild('reportItemsGrid') public reportItemsGrid: AgGridAngular;

  private readonly REPORT_ITEM_TRANSLATION_KEY = 'BMSCONFIG.REPORT_ITEMS.REPORT_ITEM';

  public listOfGridOptions: GridOptions<any>[] = [];
  public listOfGridApis: GridApi[];
  public listOfReportItems: ReportItem[];
  public selectedReportItems: ReportItem[];
  public reportItemIdsToExclude: number[];
  public title: string;

  private isCatalog: boolean;
  private addStopGroups: boolean;
  private addDeclarationsGroups: boolean;
  private machineTypes: MachineType[];
  private stopGroupsCategory = new IdNameDescription(null, this.translate.instant('MACHINE.SETTINGS.STOP_GROUPS').toUpperCase(), null);
  private declarationCategory = new IdNameDescription(null, this.translate.instant('MACHINE.SETTINGS.DECLARATION_GROUPS').toUpperCase(), null);
  private disabledCategory = new IdNameDescription(null, this.translate.instant('MACHINE.SETTINGS.DISABLED_REPORT_ITEMS').toUpperCase(), null);
  private stopGroupOrDeclarationId: number = 0;

  public constructor(
    @Inject(DECLARATIONS) public readonly declarationsService: Declarations,
    @Inject(MAT_DIALOG_DATA)
    data: {isCatalog: boolean; selectedReportItems: ReportItem[]; machineTypes: MachineType[]; addStopGroups: boolean; addDeclarationsGroups: boolean; reportItemIdsToExclude: number[]},
    private readonly dialogRef: MatDialogRef<SelectReportItemComponent>,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly reportItemService: HttpReportItemsService,
    private readonly automaticStopGroupsService: HttpAutomaticStopGroupsService
  ) {
    super();
    this.selectedReportItems = data?.selectedReportItems ? data.selectedReportItems : null;
    this.machineTypes = data?.machineTypes ? data.machineTypes : (EnumUtils.getEnumValues(MachineType).filter((machineType: MachineType) => machineType !== MachineType.UNDEFINED) as MachineType[]);
    this.isCatalog = data.isCatalog ?? false;
    this.addStopGroups = data.addStopGroups ?? false;
    this.addDeclarationsGroups = data.addDeclarationsGroups ?? false;
    this.reportItemIdsToExclude = data.reportItemIdsToExclude ?? [];

    this.title = this.isCatalog ? this.translate.instant('BMSCONFIG.REPORT_ITEMS.REPORT_ITEM_CATALOG') : this.translate.instant(this.REPORT_ITEM_TRANSLATION_KEY, {count: 1});
  }

  public ngOnInit(): void {
    this.getReportItems();
    this.initialiseGridOptions();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.reportItemsGrid?.api];
  }

  public get gridOptionsReportItems(): GridOptions {
    return this.listOfGridOptions[0];
  }

  public chooseReportItems(): void {
    this.dialogRef.close(this.listOfGridApis[0].getSelectedRows());
  }

  public isMinAmountOfReportItemsSelected(): boolean {
    return this.listOfGridApis && this.listOfGridApis.length > 0 && this.listOfGridApis[0]?.getSelectedRows()?.length > 0;
  }

  private isJsonReportItem(catalogId: number): boolean {
    return (
      catalogId === ReportItemTemplateField.STOP_GROUPS ||
      catalogId === ReportItemTemplateField.TOTAL_STOP_GROUP_TIME ||
      catalogId === ReportItemTemplateField.DECLARATIONS ||
      catalogId === ReportItemTemplateField.TOTAL_DECLARATION_TIME
    );
  }

  private initialiseGridOptions(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfReportItems(), this.isCatalog ? GridIdentifier.SELECT_REPORT_ITEM_CATALOG : GridIdentifier.SELECT_REPORT_ITEM)
        .withColumnMenu(['generalMenuTab', 'filterMenuTab'])
        .withRowSelection(true, true, true, false, true)
        .withAutoGroupColumnDef(this.getAutoGroupColumnDef())
        .withGroupDisplayType('singleColumn')
        .withFloatingFiltersHeight(32)
        .withHeaderHeight(32)
        .withLoadingOverlay({
          scale: 0.7
        })
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: this.translate.instant(this.REPORT_ITEM_TRANSLATION_KEY, {count: 1}),
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withOnFirstDataRendered(() => {
          if (this.selectedReportItems) {
            this.listOfGridApis[0].forEachNode((node: RowNode) => {
              node.setSelected(
                this.selectedReportItems.some(
                  (reportItem: ReportItem) =>
                    (!this.isJsonReportItem(reportItem.catalogId) && reportItem.id === node.data?.id) ||
                    (this.isJsonReportItem(reportItem.catalogId) &&
                      reportItem.catalogId === node.data?.catalogId &&
                      (this.nameWithExtension(reportItem.name, reportItem) === node.data?.name || reportItem.name === node.data?.name))
                )
              );
            });
          }
        })
        .build()
    ];
  }

  private getColumnDefsForListOfReportItems(): ColDef[] {
    return [this.colDefBuilderFactoryService.getBuilder().withColId('category').withField('category.name').withRowGroup().withHide().build()];
  }

  private getAutoGroupColumnDef(): ColDef {
    return this.colDefBuilderFactoryService
      .getBuilder()
      .withField('name')
      .withHeaderName(this.translate.instant(this.REPORT_ITEM_TRANSLATION_KEY, {count: 2}))
      .withSuppressMovable()
      .withResizable()
      .build();
  }

  private getReportItems(): void {
    const request = this.isCatalog ? this.reportItemService.getAllFromCatalog() : this.reportItemService.getEnabledAndByMachineTypes(this.machineTypes);

    request
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        switchMap((listOfReportItems: ReportItem[]) => this.handleReportItems(listOfReportItems))
      )
      .subscribe((finalListOfReportItems: ReportItem[]) => {
        this.listOfReportItems = finalListOfReportItems.filter((reportItem: ReportItem) => !this.reportItemIdsToExclude.includes(reportItem.id));
      });
  }

  private handleReportItems(listOfReportItems: ReportItem[]): Observable<ReportItem[]> {
    const observables: Observable<ReportItem[]>[] = [];

    if (this.addStopGroups) {
      observables.push(this.createStopGroupsObservable(listOfReportItems));
    }

    if (this.addDeclarationsGroups) {
      observables.push(this.createDeclarationsObservable(listOfReportItems));
    }

    return this.handleObservables(observables, listOfReportItems);
  }

  private createStopGroupsObservable(listOfReportItems: ReportItem[]): Observable<ReportItem[]> {
    const stopGroupReportItem = listOfReportItems.find((reportItem: ReportItem) => reportItem.catalogId === ReportItemTemplateField.STOP_GROUPS);
    const totalTimeStopGroupsReportItem = listOfReportItems.find((reportItem: ReportItem) => reportItem.catalogId === ReportItemTemplateField.TOTAL_STOP_GROUP_TIME);

    if (stopGroupReportItem || totalTimeStopGroupsReportItem) {
      return this.automaticStopGroupsService.getAutomaticStopGroupsForMachineTypes(this.machineTypes).pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        map((listOfStopGroups: AutomaticStopGroup[]) => {
          let newReportItems = [];
          if (stopGroupReportItem) {
            newReportItems = newReportItems.concat(this.createStopGroupReportItems(listOfStopGroups, stopGroupReportItem));
          }
          if (totalTimeStopGroupsReportItem) {
            newReportItems = newReportItems.concat(this.createStopGroupReportItems(listOfStopGroups, totalTimeStopGroupsReportItem));
          }
          return newReportItems;
        })
      );
    } else {
      return of([]);
    }
  }

  private createDeclarationsObservable(listOfReportItems: ReportItem[]): Observable<ReportItem[]> {
    const declarationReportItem = listOfReportItems.find((reportItem: ReportItem) => reportItem.catalogId === ReportItemTemplateField.DECLARATIONS);
    const totalDeclarationsTime = listOfReportItems.find((reportItem: ReportItem) => reportItem.catalogId === ReportItemTemplateField.TOTAL_DECLARATION_TIME);

    if (declarationReportItem || totalDeclarationsTime) {
      return this.declarationsService.getDeclarationGroupsForMachineTypes(this.machineTypes).pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        map((listOfDeclarationGroups: DeclarationGroup[]) => {
          let newReportItems = [];
          if (declarationReportItem) {
            newReportItems = newReportItems.concat(this.createDeclarationReportItems(listOfDeclarationGroups, declarationReportItem));
          }
          if (totalDeclarationsTime) {
            newReportItems = newReportItems.concat(this.createDeclarationReportItems(listOfDeclarationGroups, totalDeclarationsTime));
          }
          return newReportItems;
        })
      );
    } else {
      return of([]);
    }
  }

  private handleObservables(observables: Observable<ReportItem[]>[], combinedReportItems: ReportItem[]): Observable<ReportItem[]> {
    if (observables.length > 0) {
      return forkJoin(observables).pipe(
        map((results: ReportItem[][]) => {
          results.forEach((result: ReportItem[]) => {
            combinedReportItems = combinedReportItems.concat(result);
          });
          return combinedReportItems;
        }),
        switchMap((finalCombinedReportItems: ReportItem[]) => {
          return this.addSelectedReportItems(finalCombinedReportItems);
        })
      );
    } else {
      return this.addSelectedReportItems(combinedReportItems);
    }
  }

  private addSelectedReportItems(combinedReportItems: ReportItem[]): Observable<ReportItem[]> {
    if (this.selectedReportItems) {
      this.selectedReportItems.forEach((selectedReportItem: ReportItem) => {
        if (!selectedReportItem.enabled) {
          selectedReportItem.category = this.disabledCategory;
          combinedReportItems.push(selectedReportItem);
        }
      });
      return of(combinedReportItems);
    }

    return of(combinedReportItems);
  }

  private createStopGroupReportItems(listOfStopgroups: AutomaticStopGroup[], stopGroupReportItem: ReportItem): ReportItem[] {
    let stopGroupNames = ['Total', ...listOfStopgroups.map((stopGroup: AutomaticStopGroup) => stopGroup.name)];

    return stopGroupNames.map((name: string) => {
      return new ReportItem(
        this.stopGroupOrDeclarationId++,
        this.nameWithExtension(name, stopGroupReportItem),
        this.stopGroupsCategory,
        stopGroupReportItem.module,
        stopGroupReportItem.physicalQuantity,
        stopGroupReportItem.unit,
        stopGroupReportItem.formatting,
        stopGroupReportItem.description,
        stopGroupReportItem.enabled,
        name,
        stopGroupReportItem.dataType,
        stopGroupReportItem.cumulationType,
        stopGroupReportItem.supportedMachineTypes,
        stopGroupReportItem.catalogId,
        stopGroupReportItem.periodType,
        stopGroupReportItem.sequence,
        stopGroupReportItem.value,
        stopGroupReportItem.id
      );
    });
  }

  private createDeclarationReportItems(listOfDeclarations: DeclarationGroup[], declarationReportItem: ReportItem): ReportItem[] {
    let declarationNames = ['Total', ...listOfDeclarations.map((declarationGroup: DeclarationGroup) => declarationGroup.name)];

    return declarationNames.map((name: string) => {
      return new ReportItem(
        this.stopGroupOrDeclarationId++,
        this.nameWithExtension(name, declarationReportItem),
        this.declarationCategory,
        declarationReportItem.module,
        declarationReportItem.physicalQuantity,
        declarationReportItem.unit,
        declarationReportItem.formatting,
        declarationReportItem.description,
        declarationReportItem.enabled,
        name,
        declarationReportItem.dataType,
        declarationReportItem.cumulationType,
        declarationReportItem.supportedMachineTypes,
        declarationReportItem.catalogId,
        declarationReportItem.periodType,
        declarationReportItem.sequence,
        declarationReportItem.value,
        declarationReportItem.id
      );
    });
  }

  private nameWithExtension(name: string, reportItem: ReportItem): string {
    if (reportItem.catalogId === ReportItemTemplateField.STOP_GROUPS) {
      return this.translate.instant('MACHINE.SETTINGS.STOPS', {name: name});
    } else if (reportItem.catalogId === ReportItemTemplateField.TOTAL_STOP_GROUP_TIME) {
      return this.translate.instant('MACHINE.SETTINGS.STOP_TIME', {name: name});
    } else if (reportItem.catalogId === ReportItemTemplateField.DECLARATIONS) {
      return this.translate.instant('MACHINE.SETTINGS.DECLARED_STOPS', {name: name});
    } else if (reportItem.catalogId === ReportItemTemplateField.TOTAL_DECLARATION_TIME) {
      return this.translate.instant('MACHINE.SETTINGS.DECLARATION_TIME', {name: name});
    }
    return name;
  }
}
