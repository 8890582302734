// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .dialog-content {
  overflow: auto;
  max-height: calc(90vh - 146px);
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/dialogs/conflicts-dialog/conflicts-dialog.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,8BAAA;AAAJ","sourcesContent":[":host {\n  .dialog-content {\n    overflow: auto;\n    max-height: calc(90vh - 146px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
