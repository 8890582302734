// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .mat-icon {
  height: 56px;
}
:host .reason-for-incompatibility {
  line-height: 28px;
  white-space: pre-wrap;
  padding: 12px 0;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/pages/textile-data/finishing/add/select-machine-quality-and-finishing-template/grid-compatible-with-machine-quality/grid-compatible-with-machine-quality.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AAGE;EACE,iBAAA;EACA,qBAAA;EACA,eAAA;AADJ","sourcesContent":[":host {\n  .mat-icon {\n    height: 56px;\n  }\n\n  .reason-for-incompatibility {\n    line-height: 28px;\n    white-space: pre-wrap;\n    padding: 12px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
