// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .checkbox-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/grids/input/checkbox-cell-editor/checkbox-cell-editor.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ","sourcesContent":[":host {\n  .checkbox-wrapper {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
