// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .arrow {
  position: absolute;
  pointer-events: none;
}
:host .arrow.top {
  transform: rotate(90deg) translateX(-8px);
}
:host .arrow.bottom {
  transform: rotate(-90deg);
}
:host .arrow.right {
  transform: scaleX(-1);
}
:host .arrow.left {
  transform: translateX(-8px);
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/components/reposition-dialog/reposition-dialog.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,oBAAA;AAAJ;AAEI;EACE,yCAAA;AAAN;AAGI;EACE,yBAAA;AADN;AAII;EACE,qBAAA;AAFN;AAKI;EACE,2BAAA;AAHN","sourcesContent":[":host {\n  .arrow {\n    position: absolute;\n    pointer-events: none;\n\n    &.top {\n      transform: rotate(90deg) translateX(-8px);\n    }\n\n    &.bottom {\n      transform: rotate(-90deg);\n    }\n\n    &.right {\n      transform: scaleX(-1);\n    }\n\n    &.left {\n      transform: translateX(-8px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
