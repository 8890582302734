<div #container [class.padding-container]="config.isObjectSelection" class="full-width full-height bms-theme flex-column">
  <div *ngIf="config.headerTitle && config.isObjectSelection" class="header">
    <button mat-icon-button class="mat-icon-action-button-lg-with-border back-button" tabindex="-1" (click)="cancel()" [matTooltip]="'back'" matTooltipPosition="above" matTooltipClass="bms-theme">
      <mat-icon class="back-icon" svgIcon="solid-arrow-back"></mat-icon>
    </button>
    <div class="font-weight-semibold header-title">{{ config.headerTitle }}</div>
  </div>
  <div class="actions">
    <div class="flex-row flex-end-center gap-8">
      <button
        type="button"
        mat-icon-button
        [matTooltip]="'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.EXPORT' | bmsTranslate"
        matTooltipPosition="above"
        matTooltipClass="bms-theme"
        tabindex="-1"
        (click)="exportData()"
      >
        <mat-icon class="icon" svgIcon="solid-download"></mat-icon>
      </button>
      <button *ngIf="hasNewClickedEvent()" class="action-button" mat-flat-button (click)="config.withNewClicked($event)">
        {{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.NEW' | bmsTranslate }}
      </button>
    </div>
  </div>

  <div #gridContainer class="full-width full-height">
    <ag-grid-angular
      #grid
      class="full-width full-height ag-theme-vdw ag-theme-vdw"
      [style.height]="dynamicGridHeight ? dynamicGridHeight + 'px' : '100%'"
      [rowData]="config.rowData"
      [gridOptions]="config.gridOptions"
    ></ag-grid-angular>
  </div>

  <div *ngIf="config.isObjectSelection" class="footer">
    <div class="footer-start" *ngIf="config.gridOptions?.rowSelection === 'multiple' && (selectedItemCount !== 0 || onlyShowSelected)">
      <vdw-toggle-button class="toggle" [label]="showSelected" [checked]="onlyShowSelected" (checkedChange)="toggleShowOnlySelected($event)"></vdw-toggle-button>
      <div class="selected-item-count font-weight-semibold">{{ selectedItemCount }} {{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.OF' | bmsTranslate }} {{ config.rowData?.length }}</div>
    </div>

    <div class="footer-end">
      <button mat-button (click)="cancel()" tabindex="-1">{{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.CANCEL' | bmsTranslate }}</button>
      <button [class]="'action-button ' + CHOOSE_BUTTON_CLASS_NAME" mat-flat-button (click)="select()" [disabled]="selectedItemCount === 0">
        {{ 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.CONFIRM_SELECTION' | bmsTranslate }}
      </button>
    </div>
  </div>
</div>
