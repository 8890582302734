import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {NavigationFunctionData} from '@application/helper/navigation-helper/navigation-function-data.interface';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNewItemData} from '@application/helper/navigation-helper/navigation-new-item-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {PanelState} from '@domain/panel-state.enum';
import {DrawingConfigurationWeaveQuality} from '@domain/production-schedule/drawing-configuration-weave-quality';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {DrawingRecolorInformation} from '@domain/production-schedule/drawing-recolor-information';
import {Permission} from '@domain/profile/permission.enum';
import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {OverviewListCreelPosition} from '@domain/textile-data/creel/overview-list-creel-position';
import {WeaveQuality} from '@domain/textile-data/machine-quality/weave-quality';
import {COLORED_YARN_SETS, ColoredYarnSets} from '@infrastructure/http/colored-yarn-set/colored-yarn-sets';
import {DRAWINGS, Drawings} from '@infrastructure/http/drawing/drawings';
import {MACHINE_QUALITIES, MachineQualities} from '@infrastructure/http/machine-quality/machine-qualities';
import {YARN_SETS, YarnSets} from '@infrastructure/http/yarn-set/yarn-sets';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {SelectColorSetComponent} from '@presentation/pages/textile-data/colored-yarn-set/add/select-color-set/select-color-set.component';
import {SelectYarnSetComponent} from '@presentation/pages/textile-data/colored-yarn-set/add/select-yarn-set/select-yarn-set.component';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {SelectColoredYarnSetComponent} from '@presentation/pages/textile-data/creel/add/select-colored-yarn-set/select-colored-yarn-set.component';
import {OverviewListYarnSet} from '@presentation/pages/textile-data/yarn-set/overview/overview-list-yarn-set';
import {AssertionUtils, convertToCommercialUnitCentimeter, DialogBuilderFactoryService, DialogComponentData, ObjectUtils, TranslateService, Unit} from '@vdw/angular-component-library';
import {cloneDeep, isEmpty, isEqual, isNil, orderBy, size} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';
import {DrawingConfigurationForEpDrawing} from '../../pages/texedit/drawing-library/drawing-configuration-for-ep-drawing';
import {DownloadDrawingComponent} from '../download/download-drawing.component';
import {SelectMachineQualityComponent} from '../select-machine-quality/select-machine-quality.component';
import {BaseDrawingConfigurationComponent} from './base-drawing-configuration.component';
import {NavigationDrawingConfigurationIndexData} from './navigation-drawing-configuration-index-data.interface';

@Component({
  selector: 'app-drawing-configuration',
  templateUrl: './drawing-configuration.component.html',
  styleUrls: ['./drawing-configuration.component.scss']
})
export class DrawingConfigurationComponent extends BaseDrawingConfigurationComponent implements OnInit, OnChanges {
  @Input() public drawingId: number;
  @Input() public form: UntypedFormGroup;
  @Input() public drawingConfigurationIndex: number;
  @Input() public canShowInvalidFormMessageError = false;
  @Input() public designName: string;
  @Input() public allowToSaveAsEp = false;
  @Input() public canEditInputs = true;
  @Input() public canDelete = true;
  @Input() public canDeleteQuality = false;
  @Input() public useColoredYarnSet = false;
  @Input() public allowNumberOfCreelPositions = false;
  @Input() public hasMissingDesign = false;
  @Input() public canDownloadSmartCreelFiles = false;
  @Input() public newItemId: number;
  @Input() public dialogComponent: any;
  @Output() public delete: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveAsEp: EventEmitter<DrawingConfigurationForEpDrawing> = new EventEmitter<DrawingConfigurationForEpDrawing>();
  @Output() public qualitySelected: EventEmitter<DrawingConfigurationWeaveQuality> = new EventEmitter<DrawingConfigurationWeaveQuality>();

  public readonly QUALITY_CHIP_VALUE_KEY = '%(technicalNameWithVersion)';
  public readonly QUALITY_BASE_ROUTER_LINK = RouteUtils.paths.texStyle.machineQuality.editMachineQuality.absolutePath;
  public readonly YARN_SET_BASE_ROUTER_LINK = RouteUtils.paths.texStyle.yarnSet.editYarnSet.absolutePath;
  public readonly COLOR_SET_BASE_ROUTER_LINK = RouteUtils.paths.texStyle.colorSet.editColorSet.absolutePath;
  public readonly COLORED_YARN_SET_BASE_ROUTER_LINK = RouteUtils.paths.texStyle.coloredYarnSet.editColoredYarnSet.absolutePath;
  public texeditWritePermission: Permission = Permission.TEXEDIT_EDIT;
  public requiredPermissionToSaveAsEp: Permission = Permission.TEXEDIT_PROC;
  public requiredPermissionToDownloadPVDFile: Permission = Permission.TEXFAB_YARNCONSUMPTION_FOR_SMARTCREEL_PVD_FILE;
  public readonly TEXEDIT_SHOW_YARN_PERCENTAGES: Permission = Permission.TEXEDIT_DESIGN_YARN_PERCENTAGES;
  public colorSet: OverviewListColorSet;
  public yarnSet: OverviewListYarnSet;

  protected WARNING_REASON = 'DESIGN_LIBRARY.DETAILS.CONFIGURATION.CONFIRM_MACHINE_QUALITY_SELECTION';

  private panelState: PanelState = PanelState.CLOSED;
  private coloredYarnSet: OverviewListColoredYarnSet;
  private readonly getFunctionsBasedOnDialogComponent = new Map<any, NavigationFunctionData>([
    [SelectMachineQualityComponent, {loadNewItem: ({newItemId}: NavigationNewItemData): void => this.getQuality(newItemId), reopenDialog: (): void => this.selectQuality()}],
    [SelectColorSetComponent, {loadNewItem: ({newItemId}: NavigationNewItemData): void => this.getRecoloredImage(newItemId), reopenDialog: (): void => this.selectColorSet()}],
    [SelectYarnSetComponent, {loadNewItem: ({newItemId}: NavigationNewItemData): void => this.setYarnSet(newItemId), reopenDialog: (): void => this.selectYarnSet()}],
    [SelectColoredYarnSetComponent, {loadNewItem: ({newItemId}: NavigationNewItemData): void => this.setColoredYarnSet(newItemId), reopenDialog: (): void => this.selectColoredYarnSet()}]
  ]);

  public constructor(
    @Inject(MACHINE_QUALITIES) qualities: MachineQualities,
    @Inject(DRAWINGS) drawings: Drawings,
    @Inject(COLORED_YARN_SETS) public coloredYarnSets: ColoredYarnSets,
    @Inject(YARN_SETS) private yarnSets: YarnSets,
    translate: TranslateService,
    dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly navigationHelperService: NavigationHelperService<NavigationDrawingConfigurationIndexData>
  ) {
    super(qualities, drawings, translate, dialogBuilderFactoryService);
  }

  public ngOnInit(): void {
    if (!this.canEditInputs) {
      this.form.disable();
    }
    this.colorSet = this.getColorSet();
    this.yarnSet = this.getYarnSet();

    this.updateDrawingConfiguration();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('dialogComponent' in changes) {
      if (!AssertionUtils.isNullOrUndefined(this.newItemId)) {
        this.getFunctionsBasedOnDialogComponent.get(this.dialogComponent).loadNewItem({newItemId: this.newItemId});
      } else if (!AssertionUtils.isNullOrUndefined(this.dialogComponent)) {
        this.getFunctionsBasedOnDialogComponent.get(this.dialogComponent).reopenDialog();
      }
    }
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<NavigationDrawingConfigurationIndexData>({drawingConfigurationIndex: this.drawingConfigurationIndex});
  }

  public getDrawingImage(): DrawingImage {
    return this.form.get('image').value;
  }

  public deleteConfiguration(): void {
    this.delete.emit();
  }

  public openPanel(): void {
    this.panelState = PanelState.OPEN;
  }

  public closePanel(): void {
    this.panelState = PanelState.CLOSED;
  }

  public isPanelOpen(): boolean {
    return isEqual(this.panelState, PanelState.OPEN);
  }

  public canShowColorListPreview(): boolean {
    return !this.isPanelOpen() && this.isColorItemSelected();
  }

  public isCurrentMainColorsCreelPosition(creelPosition: OverviewListCreelPosition): boolean {
    const currentMainColor: OverviewListCreelPosition = this.form.get('mainColorsCreelPosition').value as OverviewListCreelPosition;

    return !isNil(currentMainColor) && isEqual(currentMainColor.position, creelPosition.position);
  }

  public isCurrentBorderColorsCreelPosition(creelPosition: OverviewListCreelPosition): boolean {
    const currentBorderColor: OverviewListCreelPosition = this.form.get('borderColorsCreelPosition').value as OverviewListCreelPosition;

    return !isNil(currentBorderColor) && isEqual(currentBorderColor.position, creelPosition.position);
  }

  public deselectCurrentMainColorsCreelPosition(event: Event, creelPosition: OverviewListCreelPosition): void {
    const currentMainColor: OverviewListCreelPosition = this.form.get('mainColorsCreelPosition').value as OverviewListCreelPosition;
    if (!this.useColoredYarnSet && currentMainColor != null && currentMainColor.position === creelPosition.position) {
      event.preventDefault();
      this.form.get('mainColorsCreelPosition').reset();
    }
  }

  public deselectCurrentBorderColorsCreelPosition(event: Event, creelPosition: OverviewListCreelPosition): void {
    const currentBorderColor: OverviewListCreelPosition = this.form.get('borderColorsCreelPosition').value as OverviewListCreelPosition;
    if (!this.useColoredYarnSet && currentBorderColor != null && currentBorderColor.position === creelPosition.position) {
      event.preventDefault();
      this.form.get('borderColorsCreelPosition').reset();
    }
  }

  public updateCreelPosition(creelPosition: OverviewListCreelPosition, event: MatCheckboxChange): void {
    creelPosition.needsToBeFromSameBath = event.checked;
  }

  public imageDownloaded(image: string): void {
    const drawingImage = this.getDrawingImage();
    drawingImage.data = image;
    this.form.get('image').setValue(drawingImage);
  }

  public onSaveAsEp(): void {
    const quality: DrawingConfigurationWeaveQuality = this.form.get('quality').value;
    const colorSet: OverviewListColorSet = this.form.get('colorSet').value;
    const image: DrawingImage = cloneDeep(this.form.get('image').value);

    this.saveAsEp.emit({quality, colorSet, image});
  }

  public downloadSmartCreelFiles(): void {
    const image = this.getDrawingImage();

    this.dialogBuiderForBasicDialogPanel
      .withWidth('720px')
      .withHeight('472px')
      .openDialog(DownloadDrawingComponent, {
        objectTranslationKey: 'DESIGN_LIBRARY.SMART_CREEL.DOWNLOAD_SMART_CREEL_FILES',
        drawingImageId: image.id,
        designId: this.drawingId,
        colorSetId: this.form.get('colorSet').value.id,
        yarnSetId: this.form.get('yarnSet').value.id,
        qualityId: this.form.get('quality').value.id,
        designName: this.designName
      });
  }

  public isDownloadSmartCreelFilesDisabled(): boolean {
    return (
      AssertionUtils.isNullOrUndefined(this.form.get('image')?.value?.id) ||
      AssertionUtils.isNullOrUndefined(this.drawingId) ||
      AssertionUtils.isNullOrUndefined(this.form.get('colorSet')?.value?.id) ||
      AssertionUtils.isNullOrUndefined(this.form.get('yarnSet')?.value?.id) ||
      AssertionUtils.isNullOrUndefined(this.form.get('quality')?.value?.id)
    );
  }

  public getInvalidSmartCreelDownloadIndication(): string {
    return this.translate.instant('GENERAL.HINTS.INSUFFICIENT_INFORMATION');
  }

  public isColoredYarnSetSelected(): boolean {
    return !isNil(this.form.get('coloredYarnSet').value);
  }

  public selectColoredYarnSet(): void {
    if (this.allowNumberOfCreelPositions) {
      this.qualities
        .getById(this.form.get('quality').value.id)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((quality: WeaveQuality) => {
          this.openSelectYarnComponent({
            numberOfCreelPositions: AssertionUtils.isNullOrUndefined(quality.singleWidth)
              ? quality.doubleWidth.topWeaveStructure.numberOfCreelPositions
              : quality.singleWidth.weaveStructure.numberOfCreelPositions
          });
        });
    } else {
      this.openSelectYarnComponent();
    }
  }

  public isYarnSetSelected(): boolean {
    return !isNil(this.form.get('yarnSet')?.value);
  }

  public canShowColoredYarnSetPreview(): boolean {
    return this.isYarnSetSelected() && !isNil(this.drawingId);
  }

  public selectYarnSet(): void {
    this.dialogBuiderForBasicDialogPanel
      .openSelectGridDialog(SelectYarnSetComponent, {numberOfCreelPositions: this.isColorSetSelected() ? size((this.form.get('colorSet').value as ColorSet).creelPositions) : null})
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((yarnSet: OverviewListYarnSet) => {
        if (!isEmpty(yarnSet)) {
          this.form.get('yarnSet').setValue(yarnSet);
          this.yarnSet = this.getYarnSet();
        }
      });
  }

  public removeYarnSet(): void {
    this.form.get('yarnSet').setValue(null);
    this.yarnSet = null;
  }

  public isColorItemSelected(): boolean {
    return this.useColoredYarnSet ? this.isColoredYarnSetSelected() : this.isColorSetSelected();
  }

  protected handleMachineQualitySelection(chosenMachineQuality: DrawingConfigurationWeaveQuality): void {
    if (this.isColorItemSelected()) {
      this.confirmMachineQualitySelection(chosenMachineQuality);
    } else {
      this.form.get('quality').setValue(chosenMachineQuality);
      this.qualitySelected.emit(chosenMachineQuality);
    }
  }

  protected onConfirmMachineQualitySelection(machineQuality: DrawingConfigurationWeaveQuality): void {
    this.form.get('quality').setValue(machineQuality);
    this.resetFormFields();
    this.qualitySelected.emit(machineQuality);
  }

  protected handleColorSetSelection(drawingRecolorInformation: DrawingRecolorInformation): void {
    if (this.useColoredYarnSet) {
      this.coloredYarnSet.overviewListColorSet = drawingRecolorInformation.colorSet;
      this.form.get('coloredYarnSet').setValue(this.coloredYarnSet);
      this.coloredYarnSet = null;
    } else {
      this.form.get('colorSet').setValue(drawingRecolorInformation.colorSet);
    }

    this.form.get('mainColorsCreelPosition').setValue(drawingRecolorInformation.mainColorsCreelPosition);
    this.form.get('borderColorsCreelPosition').setValue(drawingRecolorInformation.borderColorsCreelPosition);
    this.form.get('image').setValue({id: this.form.get('image').value.id, data: drawingRecolorInformation.image} as DrawingImage);
    this.form.get('heightInCm').setValue(
      convertToCommercialUnitCentimeter({
        unit: Unit.MILLIMETER,
        value: drawingRecolorInformation.commercialDimensionsInMm.heightInMM
      })
    );
    this.form.get('widthInCm').setValue(
      convertToCommercialUnitCentimeter({
        unit: Unit.MILLIMETER,
        value: drawingRecolorInformation.commercialDimensionsInMm.widthInMM
      })
    );

    this.recoloringImage = false;
    this.colorSet = this.getColorSet();
  }

  protected getDrawingImageId(): string {
    return this.form.get('image').value.id;
  }

  private updateDrawingConfiguration(): void {
    const emptyDrawingConfigurationIndexState = {drawingConfigurationIndex: null} as NavigationDrawingConfigurationIndexData;
    const drawingConfigurationIndexState = this.navigationHelperService.getPartialState<NavigationDrawingConfigurationIndexData>(Object.keys(emptyDrawingConfigurationIndexState));

    if (
      !AssertionUtils.isNullOrUndefined(drawingConfigurationIndexState) &&
      !ObjectUtils.isDeepEqual(drawingConfigurationIndexState, emptyDrawingConfigurationIndexState) &&
      drawingConfigurationIndexState.drawingConfigurationIndex === this.drawingConfigurationIndex
    ) {
      this.navigationHelperService.setNewItemOrReopenDialogIfPresent(
        ({newItemId}: NavigationNewItemData, {dialogComponent}: DialogComponentData) => this.getFunctionsBasedOnDialogComponent.get(dialogComponent).loadNewItem({newItemId}),
        ({dialogComponent}: DialogComponentData) => this.getFunctionsBasedOnDialogComponent.get(dialogComponent).reopenDialog()
      );
    }
  }

  private setYarnSet(id: number): void {
    this.yarnSets
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfYarnSets: OverviewListYarnSet[]) => {
        this.form.get('yarnSet').setValue(listOfYarnSets.find((yarnSet: OverviewListYarnSet) => yarnSet.id === id));
        this.yarnSet = this.getYarnSet();
      });
  }

  private resetFormFields(): void {
    if (this.useColoredYarnSet) {
      this.form.get('coloredYarnSet').reset();
    } else {
      this.form.get('colorSet').reset();
    }
    this.form.get('mainColorsCreelPosition').reset();
    this.form.get('borderColorsCreelPosition').reset();
  }

  private getColorSet(): OverviewListColorSet {
    let colorSet: OverviewListColorSet;

    if (this.useColoredYarnSet && !isNil(this.form.get('coloredYarnSet').value)) {
      colorSet = (this.form.get('coloredYarnSet').value as OverviewListColoredYarnSet).overviewListColorSet;
    } else if (!this.useColoredYarnSet && !isNil(this.form.get('colorSet').value)) {
      colorSet = this.form.get('colorSet').value as OverviewListColorSet;
    }

    return !isNil(colorSet) && new OverviewListColorSet(colorSet.id, colorSet.name, orderBy(colorSet.creelPositions, ['position'], ['desc']));
  }

  private getYarnSet(): OverviewListYarnSet {
    return this.useColoredYarnSet ? (this.form.get('coloredYarnSet').value as OverviewListColoredYarnSet)?.overviewListYarnSet : (this.form.get('yarnSet').value as OverviewListYarnSet);
  }

  private openSelectYarnComponent(dialogData?: {numberOfCreelPositions: number}): void {
    this.dialogBuiderForBasicDialogPanel
      .openSelectGridDialog(SelectColoredYarnSetComponent, dialogData)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((coloredYarnSet: OverviewListColoredYarnSet) => {
        if (coloredYarnSet && (this.form.get('coloredYarnSet').value as OverviewListColoredYarnSet)?.id !== coloredYarnSet.id) {
          this.coloredYarnSet = coloredYarnSet;
          this.getRecoloredImage(coloredYarnSet.overviewListColorSet.id);
        }
      });
  }

  private setColoredYarnSet(id: number): void {
    this.coloredYarnSets
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfColoredYarnSets: OverviewListColoredYarnSet[]) => {
        const newlyCreatedColoredYarnSet: OverviewListColoredYarnSet = listOfColoredYarnSets.find((coloredYarnSet: OverviewListColoredYarnSet) => coloredYarnSet.id === id);

        this.coloredYarnSet = newlyCreatedColoredYarnSet;
        this.getRecoloredImage(newlyCreatedColoredYarnSet.overviewListColorSet.id);
      });
  }

  public removeColorSet(): void {
    this.form.get('colorSet').setValue(null);
    this.form.get('mainColorsCreelPosition').setValue(null);
    this.form.get('borderColorsCreelPosition').setValue(null);
    this.colorSet = null;
  }

  public canShowWidthAndHeight(): boolean {
    return this.useColoredYarnSet ? this.isColoredYarnSetSelected() : this.isQualitySelected();
  }

  public getMinimumValueForWidthAndHeight(): string {
    return this.useColoredYarnSet ? '1' : '0';
  }
}
