// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host .mat-mdc-card:has(> :not(:only-child)) {
  border-radius: 4px 4px 0 0;
}`, "",{"version":3,"sources":["webpack://./libs/angular-component-library/src/lib/custom-components/tabs-menu-card/tabs-menu-card.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,0BAAA;AACJ","sourcesContent":[":host {\n  width: 100%;\n\n  .mat-mdc-card:has(> :not(:only-child)) {\n    border-radius: 4px 4px 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
