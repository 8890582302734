// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host .ag-cell-value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./apps/texconnect/src/app/presentation/pages/textile-data/textile-data-overview/grid-labels/grid-labels.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,gBAAA;EACA,mBAAA;EACA,uBAAA;AACJ","sourcesContent":[":host {\n  width: 100%;\n\n  .ag-cell-value {\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
