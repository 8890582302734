import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {DialogData} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';

@Component({
  templateUrl: './order-lines-settings.component.html',
  styleUrls: ['./order-lines-settings.component.scss']
})
export class OrderLinesCustomSettingsDialogComponent implements DialogData<{showPreselectedItems: boolean; showAlternativeColorSets: boolean}>, OnInit {
  public form: UntypedFormGroup;
  public showOnlyPreselectedItems = false;
  public showOnlyAlternativeColorSets = true;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    this.showOnlyPreselectedItems = data.showPreselectedItems;
    this.showOnlyAlternativeColorSets = data.showAlternativeColorSets;
  }

  public ngOnInit(): void {
    this.setFormFields(this.data.showOnlySelectedItems, this.data.showOnlyAlternativeColorSets);
  }

  public onShowPreselectedItemsChanged(showPreSelectedItems: boolean): void {
    this.showOnlyPreselectedItems = showPreSelectedItems;
    this.form.patchValue({showPreselectedItems: showPreSelectedItems});
  }

  public onShowAlternativeColorSetsChanged(showAlternativeColorSets: boolean): void {
    this.showOnlyAlternativeColorSets = showAlternativeColorSets;
    this.form.patchValue({showAlternativeColorSets: showAlternativeColorSets});
  }

  public getValue(): Observable<{showPreselectedItems: boolean; showAlternativeColorSets: boolean}> {
    return this.form.valueChanges;
  }

  public viewAllSettings(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.productionOrder.settings.absolutePath);
  }

  private setFormFields(showOnlySelectedItems: boolean, showOnlyAlternativeColorSets: boolean): void {
    this.form = this.formBuilder.group({
      showPreselectedItems: [showOnlySelectedItems],
      showAlternativeColorSets: [showOnlyAlternativeColorSets]
    });
  }
}
