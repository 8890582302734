<section fxLayout="column" fxFlex *ngIf="!addingPattern">
  <vdw-header [title]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: patternTranslationKey | bmsTranslate: {count: 1} | lowercase}" svgIcon="select-blue" [canShowClose]="true"></vdw-header>
  <vdw-tabs-menu *ngIf="showPatternTypeFilter" [menuItems]="menuItems" [selectedMenuItem]="selectedMenuItem" (selectedMenuItemChange)="onSelectedMenuItemChange($event)"></vdw-tabs-menu>
  <article class="dialog-content" fxLayout="row">
    <div *ngIf="allowPatternCreation">
      <button mat-icon-button class="mat-icon-action-button-lg-with-border" [matTooltip]="'GENERAL.ACTIONS.CREATE_NEW' | bmsTranslate" (click)="patternImageInput.click()">
        <mat-icon svgIcon="add"></mat-icon>
      </button>
      <input #patternImageInput fxHide type="file" accept=".bmp" (change)="openAddPattern($event.target.files); patternImageInput.value = ''" />
    </div>
    <div fxFlex fxLayoutAlign="end center" fxLayoutGap="16px">
      <vdw-toggle-button *ngIf="canShowOnlyDefaultToggle" (checkedChange)="showOnlyDefaultsChanged($event)" label="ANGULAR_COMPONENT_LIBRARY.ONLY_DEFAULTS.SHOW_ONLY_DEFAULTS"></vdw-toggle-button>
    </div>
  </article>
  <article fxFlex appDataCy="grid" fxLayout="column" class="dialog-content">
    <ag-grid-angular #colorPatternsGrid *ngIf="isColorMenuItemSelected() && !isPatternTypeFolding()" class="ag-theme-vdw" [gridOptions]="gridOptionsForListOfColorPatterns" [rowData]="listOfPatterns">
    </ag-grid-angular>
    <ag-grid-angular
      #drawingPatternsGrid
      *ngIf="!isColorMenuItemSelected() || isPatternTypeFolding()"
      class="ag-theme-vdw"
      [gridOptions]="gridOptionsForListOfDrawingPatterns"
      [rowData]="listOfPatterns"
    >
    </ag-grid-angular>
  </article>
  <vdw-footer [locked]="!canSelectPattern" [centered]="true" (chooseEvent)="selectPattern()"></vdw-footer>
</section>

<app-add-pattern *ngIf="addingPattern" [image]="image" (closeAddPattern)="closeAddPattern()" (addedPattern)="selectPattern($event)"></app-add-pattern>
