import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, Renderer2, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {intComparator} from '@application/helper/int-comparator';
import {StringUtils} from '@application/helper/string-utils';
import {WeaveStructureColumnPipe} from '@application/pipes/weave-structure-column.pipe';
import {Gauge} from '@domain/machine/gauge';
import {Drawing} from '@domain/production-schedule/drawing';
import {PropertyValue} from '@domain/property-value';
import {TargetForListOfDrawingsEnum} from '@domain/target-for-list-of-drawings.enum';
import {WeaveStructureForMachineQuality} from '@domain/textile-data/weave-structure/weave-structure-for-machine-quality';
import {WeftColoredYarnSet} from '@domain/textile-data/weft-colored-yarn-set/weft-colored-yarn-set';
import {Articles, ARTICLES} from '@infrastructure/http/article/articles';
import {COLOR_SETS, ColorSets} from '@infrastructure/http/color-set/color-sets';
import {Colors, COLORS} from '@infrastructure/http/color/colors';
import {COLORED_YARN_SETS, ColoredYarnSets} from '@infrastructure/http/colored-yarn-set/colored-yarn-sets';
import {Creels, CREELS} from '@infrastructure/http/creel/creels';
import {DRAWINGS, Drawings} from '@infrastructure/http/drawing/drawings';
import {GetAllMachineQualitiesParameters, MACHINE_QUALITIES, MachineQualities} from '@infrastructure/http/machine-quality/machine-qualities';
import {Machines, MACHINES} from '@infrastructure/http/machine/machines.interface';
import {HttpTuftProductsService} from '@infrastructure/http/tuft-product/http-tuft-products.service';
import {GetAllWeaveProductsParameters, WEAVE_PRODUCT, WeaveProducts} from '@infrastructure/http/weave-product/weave-products.interface';
import {WEAVE_STRUCTURES, WeaveStructures} from '@infrastructure/http/weave-structure/weave-structures';
import {WEFT_COLORED_YARN_SETS, WeftColoredYarnSets} from '@infrastructure/http/weft-colored-yarn-set/weft-colored-yarn-sets';
import {DesignPreviewComponent} from '@presentation/components/grid/design-preview/design-preview.component';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {OverviewMachine} from '@presentation/pages/machine-overview/overview-machine';
import {OverviewListArticle} from '@presentation/pages/texfab/article/overview/overview-list-article';
import {GridColorSetPreviewComponent} from '@presentation/pages/textile-data/color-set/overview/grid-color-set-preview/grid-color-set-preview.component';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {GridColorPreviewComponent} from '@presentation/pages/textile-data/color/overview/grid-color-preview/grid-color-preview.component';
import {GridColoredYarnSetPreviewComponent} from '@presentation/pages/textile-data/colored-yarn-set/overview/grid-colored-yarn-set-preview/grid-colored-yarn-set-preview.component';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {GridCreelPreviewComponent} from '@presentation/pages/textile-data/creel/overview/grid-creel-preview/grid-creel-preview.component';
import {OverviewListCreel} from '@presentation/pages/textile-data/creel/overview/overview-list-creel';
import {OverviewWeaveQualityTypeComponent} from '@presentation/pages/textile-data/machine-quality/overview-weave-quality-type/overview-weave-quality-type.component';
import {OverviewListMachineQuality} from '@presentation/pages/textile-data/machine-quality/overview/overview-list-machine-quality';
import {WeaveQualityType} from '@presentation/pages/textile-data/machine-quality/overview/weave-quality-type.enum';
import {OverviewListTuftProduct} from '@presentation/pages/textile-data/tuft-product/overview/overview-list-tuft-product';
import {OverviewListWeaveProduct} from '@presentation/pages/textile-data/weave-product/overview/overview-list-weave-product';
import {
  AgGridUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  Color,
  convertUnit,
  EnumUtils,
  FooterConstants,
  GridOptionsBuilderFactoryService,
  LocaleUtils,
  TranslateService,
  Unit,
  WINDOW
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, FirstDataRenderedEvent, GridOptions, ICellRendererParams, ITooltipParams, RowDoubleClickedEvent, RowNode, RowSelectedEvent, ValueGetterParams} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {find, isEmpty, isEqual, isNil, lowerCase, size} from 'lodash-es';
import {forkJoin} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AdvancedSearchObjectInput} from '../advanced-search-object-input.enum';
import {AdvancedSearchComponent} from '../advanced-search.component';
import {SelectObjectType} from './select-object-type';

@Component({
  selector: 'app-advanced-search-select-object',
  templateUrl: './advanced-search-select-object.component.html',
  styleUrls: ['./advanced-search-select-object.component.scss']
})
export class AdvancedSearchSelectObjectComponent extends BaseComponent implements OnInit {
  private static readonly nameTranslationKey = 'GENERAL.NAME';
  private static readonly previewTranslationKey = 'GENERAL.PREVIEW';
  private static readonly numberOfCreelPositionsTranslationKey = 'TEXTILE_DATA.MATERIAL_SET.NUMBER_OF_CREEL_POSITIONS';
  private static readonly overviewListColorSetName = 'overviewListColorSet.name';
  private static readonly overviewListYarnSetName = 'overviewListYarnSet.name';
  @Input() public objectInput: AdvancedSearchObjectInput;
  @Input() public selectedObject: SelectObjectType;
  @Input() public showSearchBar = true;
  @Output() public cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() public objectSelected: EventEmitter<SelectObjectType> = new EventEmitter<SelectObjectType>();
  @ViewChild('grid', {read: ElementRef}) public gridElement: ElementRef<HTMLElement>;
  @ViewChild('grid') public grid: AgGridAngular;
  public subtitle: string;
  public listOfObjects: SelectObjectType[];
  public columnsDefsForListOfObjects: ColDef[];
  public gridOptionsForListOfObjects: GridOptions;

  private reedDensityUnit: Unit;
  private pickDensityUnit: Unit;
  private weftDensityUnit: Unit;
  private pileHeightUnit: Unit;

  private readonly actionsHeightInPx = 76;
  private readonly minimumBottomMarginInPx = 16;

  private listOfCustomSettings: PropertyValue[];

  public constructor(
    @Optional() @Inject(MACHINE_QUALITIES) private readonly machineQualities: MachineQualities,
    @Optional() @Inject(COLOR_SETS) private readonly colorSets: ColorSets,
    @Optional() @Inject(COLORS) private readonly colors: Colors,
    @Optional() @Inject(COLORED_YARN_SETS) private readonly coloredYarnSets: ColoredYarnSets,
    @Optional() @Inject(ARTICLES) private readonly articles: Articles,
    @Optional() @Inject(MACHINES) private readonly machines: Machines,
    @Optional() @Inject(CREELS) private readonly creels: Creels,
    @Optional() @Inject(WEAVE_STRUCTURES) private readonly weaveStructures: WeaveStructures,
    @Optional() @Inject(DRAWINGS) private readonly drawings: Drawings,
    @Optional() @Inject(WEFT_COLORED_YARN_SETS) private weftColoredYarnSets: WeftColoredYarnSets,
    @Optional() @Inject(WEAVE_PRODUCT) private readonly weaveProducts: WeaveProducts,
    @Optional() private readonly tuftProducts: HttpTuftProductsService,
    @Inject(WINDOW) private readonly window: Window,
    private readonly translate: TranslateService,
    private readonly l10nIntlService: L10nIntlService,
    private readonly dialogRef: MatDialogRef<AdvancedSearchComponent>,
    private readonly renderer: Renderer2,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService,
    private readonly weaveStructureColumnPipe: WeaveStructureColumnPipe
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setSubtitle();
    this.initialiseColumnsDefsForListOfObjects();
    this.initialiseGridOptionsForListOfObjects();
    this.getListOfObjects();
  }

  public filterObjects(filterText: string): void {
    this.grid.api.setGridOption('quickFilterText', filterText);
  }

  public cancelObjectSelection(): void {
    this.cancel.emit();
  }

  public canChooseSelectedObject(): boolean {
    return !isNil(this.selectedObject);
  }

  public chooseSelectedObject(): void {
    this.objectSelected.emit(this.selectedObject);
  }

  public showOnlyLatestVersionChanged(showOnlyLatestVersion: boolean): void {
    this.getListOfObjects(showOnlyLatestVersion);
  }

  public canShowLatestVersionToggle(): boolean {
    return [AdvancedSearchObjectInput.MACHINE_QUALITY, AdvancedSearchObjectInput.WEAVE_PRODUCT].includes(this.objectInput);
  }

  private getOverlayTranslationKey(): string {
    let key = 'GENERAL.ADVANCED_SEARCH';
    switch (this.objectInput) {
      case AdvancedSearchObjectInput.MACHINE_QUALITY:
        key = this.translate.instant(`${key}.MACHINE_QUALITY`, {count: 2});
        break;
      case AdvancedSearchObjectInput.COLORED_YARN_SET:
        key = this.translate.instant(`${key}.COLORED_YARN_SET`, {count: 2});
        break;
      case AdvancedSearchObjectInput.COLOR_SET:
        key = this.translate.instant(`${key}.COLOR_SET`, {count: 2});
        break;
      case AdvancedSearchObjectInput.MAIN_COLOR:
        key = this.translate.instant(`${key}.MAIN_COLOR`, {count: 2});
        break;
      case AdvancedSearchObjectInput.ARTICLE:
        key = this.translate.instant(`${key}.ARTICLE`, {count: 2});
        break;
      case AdvancedSearchObjectInput.WEAVE_STRUCTURE:
        key = this.translate.instant(`${key}.WEAVE_STRUCTURE`, {count: 2});
        break;
      case AdvancedSearchObjectInput.MAIN_DESIGN:
        key = this.translate.instant(`${key}.MAIN_DESIGN`, {count: 2});
        break;
      case AdvancedSearchObjectInput.WEFT_COLORED_YARN_SET:
        key = this.translate.instant(`${key}.WEFT_COLORED_YARN_SET`, {count: 2});
        break;
      default:
        key = this.translate.instant(`${key}.BORDER_COLOR`, {count: 2});
        break;
    }

    return key;
  }

  private initialiseGridOptionsForListOfObjects(): void {
    this.gridOptionsForListOfObjects = this.gridOptionsBuilderFactoryService
      .getBuilder(this.columnsDefsForListOfObjects, this.getGridIdentifier())
      .withRowHeight(this.getRowHeight())
      .withOnRowDoubleClicked((event: RowDoubleClickedEvent) => this.onRowDoubleClicked(event))
      .withOnRowSelected((event: RowSelectedEvent) => (this.selectedObject = event.node.data))
      .withOnGridReady(() => this.onGridReadyEvent())
      .withOnFirstDataRendered(({api}: FirstDataRenderedEvent) => {
        api.forEachNode((node: RowNode) => {
          node.setSelected(isEqual(node.data, this.selectedObject));
        });
      })
      .withNoRowsOverlay({
        titleParam: this.getOverlayTranslationKey(),
        hideDescription: true,
        filterDescriptionParam: {
          paramKeyCreate: this.getOverlayTranslationKey()
        }
      })
      .withLoadingOverlay()
      .withAutomaticRowSelection()
      .withAutomaticButtonFocusOnRowSelection(`[id="${this.dialogRef.id}"] .${FooterConstants.CHOOSE_BUTTON_CLASS_NAME}`)
      .build();
  }

  private getRowHeight(): number {
    return this.objectInput === AdvancedSearchObjectInput.MAIN_DESIGN ? 96 : 56;
  }

  private onRowDoubleClicked(event: RowDoubleClickedEvent): void {
    if (event && event.node && event.node.data) {
      this.objectSelected.emit(event.node.data);
    }
  }

  private setSubtitle(): void {
    const subtitleKey = 'GENERAL.ACTIONS.CHOOSE_OBJECT';
    let key = 'GENERAL.ADVANCED_SEARCH';
    switch (this.objectInput) {
      case AdvancedSearchObjectInput.MACHINE_QUALITY:
        key = this.translate.instant(`${key}.MACHINE_QUALITY`, {count: 1});
        break;
      case AdvancedSearchObjectInput.COLORED_YARN_SET:
        key = this.translate.instant(`${key}.COLORED_YARN_SET`, {count: 1});
        break;
      case AdvancedSearchObjectInput.MACHINE:
        key = this.translate.instant(`${key}.MACHINE`);
        break;
      case AdvancedSearchObjectInput.COLOR_SET:
        key = this.translate.instant(`${key}.COLOR_SET`, {count: 1});
        break;
      case AdvancedSearchObjectInput.MAIN_COLOR:
        key = this.translate.instant(`${key}.MAIN_COLOR`, {count: 1});
        break;
      case AdvancedSearchObjectInput.ARTICLE:
        key = this.translate.instant(`${key}.ARTICLE`, {count: 1});
        break;
      case AdvancedSearchObjectInput.CREEL:
        key = this.translate.instant(`${key}.CREEL`, {count: 1});
        break;
      case AdvancedSearchObjectInput.WEAVE_STRUCTURE:
        key = this.translate.instant(`${key}.WEAVE_STRUCTURE`, {count: 1});
        break;
      case AdvancedSearchObjectInput.MAIN_DESIGN:
        key = this.translate.instant(`${key}.MAIN_DESIGN`, {count: 1});
        break;
      case AdvancedSearchObjectInput.WEFT_COLORED_YARN_SET:
        key = this.translate.instant(`${key}.WEFT_COLORED_YARN_SET`, {count: 1});
        break;
      case AdvancedSearchObjectInput.WEAVE_PRODUCT:
        key = this.translate.instant(`${key}.WEAVE_PRODUCT`);
        break;
      case AdvancedSearchObjectInput.TUFT_PRODUCT:
        key = this.translate.instant(`${key}.TUFT_PRODUCT`);
        break;
      default:
        key = this.translate.instant(`${key}.BORDER_COLOR`, {count: 1});
        break;
    }

    this.subtitle = this.translate.instant(subtitleKey, {object: lowerCase(key)});
  }

  private getListOfObjects(showOnlyLatestVersion: boolean = true): void {
    switch (this.objectInput) {
      case AdvancedSearchObjectInput.MACHINE_QUALITY:
        this.getMachineQualities(showOnlyLatestVersion);
        break;
      case AdvancedSearchObjectInput.COLORED_YARN_SET:
        this.getColoredYarnSets();
        break;
      case AdvancedSearchObjectInput.MACHINE:
        this.getMachines();
        break;
      case AdvancedSearchObjectInput.COLOR_SET:
        this.getColorSets();
        break;
      case AdvancedSearchObjectInput.MAIN_COLOR:
      case AdvancedSearchObjectInput.BORDER_COLOR:
        this.getColors();
        break;
      case AdvancedSearchObjectInput.ARTICLE:
        this.getArticles();
        break;
      case AdvancedSearchObjectInput.CREEL:
        this.getCreels();
        break;
      case AdvancedSearchObjectInput.WEAVE_STRUCTURE:
        this.getWeaveStructures();
        break;
      case AdvancedSearchObjectInput.MAIN_DESIGN:
        this.getMainDesigns();
        break;
      case AdvancedSearchObjectInput.WEFT_COLORED_YARN_SET:
        this.getWeftColoredYarnSets();
        break;
      case AdvancedSearchObjectInput.WEAVE_PRODUCT:
        this.getWeaveProducts(showOnlyLatestVersion);
        break;
      case AdvancedSearchObjectInput.TUFT_PRODUCT:
        this.getTuftProducts();
    }
  }

  private getMachines(): void {
    this.machines
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfMachines: MachineOverviewList[]) => (this.listOfObjects = listOfMachines));
  }

  private getColoredYarnSets(): void {
    this.coloredYarnSets
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfColoredYarnSets: OverviewListColoredYarnSet[]) => (this.listOfObjects = listOfColoredYarnSets));
  }

  private getMachineQualities(showOnlyLatestVersion: boolean): void {
    const parameters: Partial<GetAllMachineQualitiesParameters> = {showOnlyLatestVersion};

    forkJoin([this.machineQualities.getListOfCustomSettings(), this.machineQualities.getAll(parameters)])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(([listOfCustomSettings, machineQualities]: [PropertyValue[], OverviewListMachineQuality[]]) => {
        this.listOfCustomSettings = listOfCustomSettings;
        this.listOfObjects = machineQualities;

        this.reedDensityUnit = Unit[this.getDefaultMachineQualityParameterForPropertyName('reedDensityUnit')];
        this.pickDensityUnit = Unit[this.getDefaultMachineQualityParameterForPropertyName('pickDensityUnit')];
        this.weftDensityUnit = Unit[this.getDefaultMachineQualityParameterForPropertyName('weftDensityUnit')];
        this.pileHeightUnit = Unit[this.getDefaultMachineQualityParameterForPropertyName('pileHeightUnit')];
      });
  }

  private getColorSets(): void {
    this.colorSets
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((colorSets: OverviewListColorSet[]) => (this.listOfObjects = colorSets));
  }

  private getColors(): void {
    this.colors
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((colors: Color[]) => (this.listOfObjects = colors));
  }

  private getArticles(): void {
    this.articles
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((articles: OverviewListArticle[]) => (this.listOfObjects = articles));
  }

  private getCreels(): void {
    this.creels
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((creels: OverviewListCreel[]) => (this.listOfObjects = creels));
  }

  private getWeaveStructures(): void {
    this.weaveStructures
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((weaveStructures: WeaveStructureForMachineQuality[]) => (this.listOfObjects = weaveStructures));
  }

  private getMainDesigns(): void {
    this.drawings
      .getSlice(0, 0, 'thumbs', false, TargetForListOfDrawingsEnum.PRODUCTION_ORDER, [])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((drawings: Drawing[]) => {
        this.listOfObjects = drawings;
      });
  }

  private getWeftColoredYarnSets(): void {
    this.weftColoredYarnSets
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((weftColoredYarnSets: WeftColoredYarnSet[]) => (this.listOfObjects = weftColoredYarnSets));
  }

  private getWeaveProducts(showOnlyLatestVersion: boolean): void {
    const parameters: Partial<GetAllWeaveProductsParameters> = {showOnlyLatestVersion};

    this.weaveProducts
      .getAll(parameters)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((weaveProducts: OverviewListWeaveProduct[]) => (this.listOfObjects = weaveProducts));
  }

  private getTuftProducts(): void {
    this.tuftProducts
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((tuftProducts: OverviewListTuftProduct[]) => (this.listOfObjects = tuftProducts));
  }

  private getColumnDefsForListOfMachines(): ColDef[] {
    return [
      this.colDefBuilderFactory
        .getBuilder()
        .withField('name')
        .withValueGetter((params: ValueGetterParams) => this.machineNameValueGetter(params))
        .withSuppressHeaderMenuButton()
        .withResizable(false)
        .build()
    ];
  }

  private machineNameValueGetter(params: ValueGetterParams): string {
    const overviewMachine: OverviewMachine = params.data;
    return !isEmpty(overviewMachine.name) ? overviewMachine.name : this.getNoDataTranslation();
  }

  private getNoDataTranslation(): string {
    return this.translate.instant('GENERAL.NO_DATA', {object: lowerCase(this.translate.instant(AdvancedSearchSelectObjectComponent.nameTranslationKey))});
  }

  private getColumnDefsForListOfColoredYarnSets(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('name', true).withHeaderName(AdvancedSearchSelectObjectComponent.nameTranslationKey).withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('overviewListColorSet.creelPositions.length', true)
        .withHeaderName(AdvancedSearchSelectObjectComponent.numberOfCreelPositionsTranslationKey)
        .withComparator(intComparator)
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField(AdvancedSearchSelectObjectComponent.overviewListColorSetName, true)
        .withHeaderName('TEXTILE_DATA.COLOR_SET.COLOR_SET')
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField(AdvancedSearchSelectObjectComponent.overviewListYarnSetName, true)
        .withHeaderName('TEXTILE_DATA.YARN_SET.YARN_SET')
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName(AdvancedSearchSelectObjectComponent.previewTranslationKey)
        .withSortable(false)
        .withoutFilter()
        .withCellRenderer(GridColorSetPreviewComponent)
        .build()
    ];
  }

  private getColumnDefsForListOfMachineQualities(): ColDef[] {
    return [
      this.colDefBuilderFactory
        .getBuilder()
        .withField('technicalNameWithVersion')
        .withHeaderName(AdvancedSearchSelectObjectComponent.nameTranslationKey)
        .withComparator(StringUtils.stringComparator)
        .withTooltipField('technicalNameWithVersion')
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('weaveStructure.name')
        .withHeaderName('TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE')
        .withComparator(StringUtils.stringComparator)
        .withValueGetter((params: ValueGetterParams) => this.getValueForWeaveStructureCell(params.data), true)
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('reedDensityInDentsPerMM')
        .withHeaderValueGetter(() => this.getHeaderNameFromUnit(this.reedDensityUnit))
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithoutUnit(params.getValue(), Unit.DENTS_PER_MILLIMETER, this.reedDensityUnit))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, Unit.DENTS_PER_MILLIMETER, this.reedDensityUnit))
        .withoutFilter()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('pickDensityInPicksPerMM')
        .withHeaderValueGetter(() => this.getHeaderNameFromUnit(this.pickDensityUnit))
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithoutUnit(params.getValue(), Unit.PICKS_PER_MILLIMETER, this.pickDensityUnit))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, Unit.PICKS_PER_MILLIMETER, this.pickDensityUnit))
        .withoutFilter()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('weftDensityInDentsPerMM')
        .withHeaderValueGetter(() => this.getHeaderNameFromUnit(this.weftDensityUnit))
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithoutUnit(params.getValue(), Unit.WEFTS_PER_MILLIMETER, this.weftDensityUnit))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, Unit.WEFTS_PER_MILLIMETER, this.weftDensityUnit))
        .withoutFilter()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('practicalPickDensityInPicksPerMM')
        .withHeaderValueGetter(() => this.getHeaderNameFromUnit(this.pickDensityUnit))
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithoutUnit(params.getValue(), Unit.PICKS_PER_MILLIMETER, this.pickDensityUnit))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, Unit.PICKS_PER_MILLIMETER, this.pickDensityUnit))
        .withoutFilter()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('pileHeightInMM')
        .withHeaderValueGetter(() => this.getHeaderNameFromUnit(this.pileHeightUnit))
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithoutUnit(params.getValue(), Unit.MILLIMETER, this.pileHeightUnit))
        .withTooltipValueGetter((params: ITooltipParams) => this.buildAgGridCellTooltipWithUnit(params.value, Unit.MILLIMETER, this.pileHeightUnit))
        .build()
    ];
  }

  private getValueForWeaveStructureCell(machineQuality: OverviewListMachineQuality): string {
    return this.weaveStructureColumnPipe.transform(machineQuality);
  }

  private getDefaultMachineQualityParameterForPropertyName(propertyName: string): string {
    return find(this.listOfCustomSettings, ['propertyName', propertyName]).propertyValue;
  }

  private getColumnDefsForListOfColorSets(): ColDef[] {
    return [
      this.colDefBuilderFactory
        .getBuilder()
        .withField('name')
        .withHeaderName(AdvancedSearchSelectObjectComponent.nameTranslationKey)
        .withComparator(StringUtils.stringComparator)
        .withTooltipField('name')
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName(AdvancedSearchSelectObjectComponent.numberOfCreelPositionsTranslationKey)
        .withCellRenderer((params: ICellRendererParams) => `${this.getNumberOfCreelPositionsForColorSet(params.data)}`)
        .withTooltipValueGetter((params: ITooltipParams) => `${this.getNumberOfCreelPositionsForColorSet(params.data)}`)
        .withComparator((valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode) => {
          return size((nodeA.data as OverviewListColorSet).creelPositions) - size((nodeB.data as OverviewListColorSet).creelPositions);
        })
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.COLOR.COLOR')
        .withCellRenderer((params: ICellRendererParams) => this.getCommaSeparatedStringForColorSet(params.data))
        .withTooltipValueGetter((params: ITooltipParams) => this.getCommaSeparatedStringForColorSet(params.data))
        .withComparator((valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode) => {
          const firstColorSetOverviewList: OverviewListColorSet = nodeA.data;
          const colorNamesOfFirstColorSet: string = StringUtils.createStringFromColorsOrYarnTypes(firstColorSetOverviewList.getUniqueColors());

          const secondColorSetOverviewList: OverviewListColorSet = nodeB.data;
          const colorNamesOfSecondColorSet: string = StringUtils.createStringFromColorsOrYarnTypes(secondColorSetOverviewList.getUniqueColors());

          return StringUtils.stringComparator(colorNamesOfFirstColorSet, colorNamesOfSecondColorSet);
        })
        .build(),
      this.colDefBuilderFactory.getBuilder().withHeaderName(AdvancedSearchSelectObjectComponent.previewTranslationKey).withSortable(false).withCellRenderer(GridColorSetPreviewComponent).build()
    ];
  }

  private getNumberOfCreelPositionsForColorSet(colorSet: OverviewListColorSet): number {
    return size(colorSet.creelPositions);
  }

  private getCommaSeparatedStringForColorSet(colorSet: OverviewListColorSet): string {
    return StringUtils.createStringFromColorsOrYarnTypes(colorSet.getUniqueColors());
  }

  private getColumnDefsForListOfColors(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('name', true).withHeaderName(AdvancedSearchSelectObjectComponent.nameTranslationKey).withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.COLOR.RGB')
        .withSortable(false)
        .withCellRenderer(GridColorPreviewComponent, {
          showRGBValue: true
        })
        .withValueGetter('data')
        .withTooltipField('name')
        .withoutFilter()
        .build()
    ];
  }

  private getColumnDefsForListOfArticles(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('name').withHeaderName('CUSTOMERS.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory.getBuilder().withField('design.name').withHeaderName('DESIGN_LIBRARY.DESIGN').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('machineQuality.nameWithVersion')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY')
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withValueGetter('data.coloredYarnSet')
        .withHeaderName('TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET')
        .withCellRenderer(GridColoredYarnSetPreviewComponent)
        .withSortable(false)
        .withoutFilter()
        .build()
    ];
  }

  private getColumnDefsForListOfCreels(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('name', true).withHeaderName(AdvancedSearchSelectObjectComponent.nameTranslationKey).withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('preview')
        .withValueGetter('data.coloredYarnSetsWithStartDent')
        .withHeaderName(AdvancedSearchSelectObjectComponent.previewTranslationKey)
        .withCellRenderer(GridCreelPreviewComponent)
        .withSortable(false)
        .withoutFilter()
        .build()
    ];
  }

  private getColumnDefsForListOfWeaveStructures(): ColDef[] {
    return [
      this.colDefBuilderFactory
        .getBuilder()
        .withField('name', true)
        .withColId('name')
        .withHeaderName(AdvancedSearchSelectObjectComponent.nameTranslationKey)
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactory.getBuilder().withField('version', true).withColId('version').withHeaderName('GENERAL.VERSION').withComparator(intComparator).build(),
      this.colDefBuilderFactory.getBuilder().withField('type', true).withColId('type').withHeaderName('GENERAL.TYPE').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('qualityType')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.QUALITY_TYPE')
        .withCellRenderer(OverviewWeaveQualityTypeComponent)
        .withComparator(StringUtils.stringComparator)
        .withTooltipValueGetter((params: ITooltipParams) => this.getValueForQualityTypeCell(params.data.qualityType))
        .build(),
      this.colDefBuilderFactory.getBuilder().withField('description', true).withColId('description').withHeaderName('GENERAL.DESCRIPTION').withComparator(StringUtils.stringComparator).build()
    ];
  }

  private getColumnDefsForListOfDesigns(): ColDef[] {
    return [
      this.colDefBuilderFactory
        .getBuilder()
        .withField('name')
        .withHeaderName(AdvancedSearchSelectObjectComponent.nameTranslationKey)
        .withComparator(StringUtils.stringComparator)
        .withTooltipField('name')
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withValueGetter('data')
        .withHeaderName(AdvancedSearchSelectObjectComponent.previewTranslationKey)
        .withCellRenderer(DesignPreviewComponent)
        .withSortable(false)
        .withoutFilter()
        .build()
    ];
  }

  private getColumnDefsForListOfWeftColoredYarnSets(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('name', true).withHeaderName(AdvancedSearchSelectObjectComponent.nameTranslationKey).withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('overviewListColorSet.creelPositions.length')
        .withHeaderName(AdvancedSearchSelectObjectComponent.numberOfCreelPositionsTranslationKey)
        .withComparator(intComparator)
        .build(),
      this.colDefBuilderFactory.getBuilder().withField(AdvancedSearchSelectObjectComponent.overviewListColorSetName).withHeaderName('TEXTILE_DATA.COLOR_SET.COLOR_SET').build(),
      this.colDefBuilderFactory.getBuilder().withField(AdvancedSearchSelectObjectComponent.overviewListYarnSetName).withHeaderName('TEXTILE_DATA.YARN_SET.YARN_SET').build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withValueGetter('data')
        .withHeaderName(AdvancedSearchSelectObjectComponent.previewTranslationKey)
        .withCellRenderer(GridColoredYarnSetPreviewComponent)
        .withSortable(false)
        .withoutFilter()
        .build()
    ];
  }

  private getColumnDefsForListOfWeaveProducts(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withField('name', true).withHeaderName(AdvancedSearchSelectObjectComponent.nameTranslationKey).withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('widthInCm')
        .withHeaderName('GENERAL.DIMENSIONS.WIDTH')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.value, Unit.CENTIMETER))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('pickDensityInPicksPerCm')
        .withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.PICK_DENSITY')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.value, Unit.PICKS_PER_CENTIMETER))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('standardSpeedInRotationsPerMinute')
        .withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.STANDARD_SPEED')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.value, Unit.ROTATIONS_PER_MINUTE))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('standardEfficiencyInPercentage')
        .withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.STANDARD_EFFICIENCY')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => `${params.value} %`)
        .build(),
      this.colDefBuilderFactory.getBuilder().withField('numberOfFeeders').withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.NUMBER_OF_FEEDERS').withRightAlignment().build(),
      this.colDefBuilderFactory.getBuilder().withField('numberOfShafts').withHeaderName('TEXTILE_DATA.WEAVE_PRODUCT.NUMBER_OF_SHAFTS').withRightAlignment().build()
    ];
  }

  private getColumnDefsForListOfTuftProducts(): ColDef[] {
    return [
      this.colDefBuilderFactory.getBuilder().withColIdAndField('name', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('version', true)
        .withHeaderName('GENERAL.VERSION')
        .withCellRenderer((params: ICellRendererParams) => params.getValue())
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('widthInCm')
        .withHeaderName('GENERAL.DIMENSIONS.WIDTH')
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue(), Unit.CENTIMETER))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnit(params.value, Unit.CENTIMETER, this.l10nIntlService))
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('blend', true)
        .withHeaderName('TEXTILE_DATA.TUFT_PRODUCT.BLEND')
        .withCellRenderer((params: ICellRendererParams) => params.getValue())
        .withCellClass('left')
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('color', true)
        .withHeaderName('TEXTILE_DATA.TUFT_PRODUCT.COLOR')
        .withCellRenderer((params: ICellRendererParams) => params.getValue())
        .withCellClass('left')
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('gauge')
        .withHeaderName('GENERAL.DIMENSIONS.GAUGE')
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue().fractionalValueAsString, Unit.INCH))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnit(params.value?.fractionalValueAsString, Unit.INCH, this.l10nIntlService))
        .withCellClass('right')
        .withComparator(this.gaugeComparator)
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('stitchRateInStitchesPerCm')
        .withHeaderName('TEXTILE_DATA.TUFT_PRODUCT.STITCH_RATE')
        .withCellRenderer((params: ICellRendererParams) => this.buildAgGridCellTextWithUnit(params.getValue(), Unit.PER_CENTIMETER))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnit(params.value, Unit.PER_CENTIMETER, this.l10nIntlService))
        .withCellClass('right')
        .build()
    ];
  }

  private gaugeComparator(valueA: Gauge, valueB: Gauge, _nodeA: RowNode<Gauge>, _nodeB: RowNode<Gauge>, _isInverted: boolean): number {
    const comparison = valueA.fractionalValue > valueB.fractionalValue ? 1 : -1;
    return valueA.fractionalValue === valueB.fractionalValue ? 0 : comparison;
  }

  private getValueForQualityTypeCell(qualityType: WeaveQualityType): string {
    return this.translate.instant(`TEXTILE_DATA.MACHINE_QUALITY.TYPES.${qualityType}`);
  }

  private buildAgGridCellTextWithUnit(value: number, defaultUnit: Unit): string {
    return AgGridUtils.buildAgGridCellTextWithUnit(value, defaultUnit, this.l10nIntlService);
  }

  private buildAgGridCellTextWithoutUnit(value: number, fromUnit: Unit, toUnit: Unit): string {
    const conversionResult = convertUnit({
      from: {
        value,
        unit: fromUnit
      },
      to: toUnit
    });

    return LocaleUtils.formatNumber(conversionResult, this.l10nIntlService);
  }

  private buildAgGridCellTooltipWithUnit(value: number, defaultUnit: Unit, toUnit: Unit): string {
    return AgGridUtils.buildAgGridCellTooltipWithUnitConversion(value, defaultUnit, toUnit, this.l10nIntlService);
  }

  private initialiseColumnsDefsForListOfObjects(): void {
    switch (this.objectInput) {
      case AdvancedSearchObjectInput.MACHINE_QUALITY:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfMachineQualities();
        break;
      case AdvancedSearchObjectInput.COLORED_YARN_SET:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfColoredYarnSets();
        break;
      case AdvancedSearchObjectInput.MACHINE:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfMachines();
        break;
      case AdvancedSearchObjectInput.COLOR_SET:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfColorSets();
        break;
      case AdvancedSearchObjectInput.MAIN_COLOR:
      case AdvancedSearchObjectInput.BORDER_COLOR:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfColors();
        break;
      case AdvancedSearchObjectInput.ARTICLE:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfArticles();
        break;
      case AdvancedSearchObjectInput.CREEL:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfCreels();
        break;
      case AdvancedSearchObjectInput.WEAVE_STRUCTURE:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfWeaveStructures();
        break;
      case AdvancedSearchObjectInput.MAIN_DESIGN:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfDesigns();
        break;
      case AdvancedSearchObjectInput.WEFT_COLORED_YARN_SET:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfWeftColoredYarnSets();
        break;
      case AdvancedSearchObjectInput.WEAVE_PRODUCT:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfWeaveProducts();
        break;
      case AdvancedSearchObjectInput.TUFT_PRODUCT:
        this.columnsDefsForListOfObjects = this.getColumnDefsForListOfTuftProducts();
        break;
    }
  }

  private calculateGridHeight(): void {
    const gridHeight = this.window.innerHeight - this.gridElement.nativeElement.getBoundingClientRect().y - this.actionsHeightInPx - this.minimumBottomMarginInPx;
    this.renderer.setStyle(this.gridElement.nativeElement, 'height', `${gridHeight}px`);
  }

  private onGridReadyEvent(): void {
    this.calculateGridHeight();
  }

  private getHeaderNameFromUnit(unit: Unit): string {
    return unit ? this.translate.instant(`GENERAL.UNIT.${EnumUtils.getKeyFromValue(Unit, unit)}`) : '';
  }

  private getGridIdentifier(): GridIdentifier {
    switch (this.objectInput) {
      case AdvancedSearchObjectInput.MACHINE_QUALITY:
        return GridIdentifier.ADVANCED_SEARCH_MACHINE_QUALITY;
      case AdvancedSearchObjectInput.COLORED_YARN_SET:
        return GridIdentifier.ADVANCED_SEARCH_COLORED_YARN_SET;
      case AdvancedSearchObjectInput.MACHINE:
        return GridIdentifier.ADVANCED_SEARCH_MACHINE;
      case AdvancedSearchObjectInput.COLOR_SET:
        return GridIdentifier.ADVANCED_SEARCH_COLOR_SET;
      case AdvancedSearchObjectInput.MAIN_COLOR:
        return GridIdentifier.ADVANCED_SEARCH_MAIN_COLOR;
      case AdvancedSearchObjectInput.BORDER_COLOR:
        return GridIdentifier.ADVANCED_SEARCH_BORDER_COLOR;
      case AdvancedSearchObjectInput.ARTICLE:
        return GridIdentifier.ADVANCED_SEARCH_ARTICLE;
      case AdvancedSearchObjectInput.CREEL:
        return GridIdentifier.ADVANCED_SEARCH_CREEL;
      case AdvancedSearchObjectInput.WEAVE_STRUCTURE:
        return GridIdentifier.ADVANCED_SEARCH_WEAVE_STRUCTURE;
      case AdvancedSearchObjectInput.MAIN_DESIGN:
        return GridIdentifier.ADVANCED_SEARCH_MAIN_DESIGN;
      case AdvancedSearchObjectInput.WEFT_COLORED_YARN_SET:
        return GridIdentifier.ADVANCED_SEARCH_WEFT_COLORED_YARN_SET;
      case AdvancedSearchObjectInput.WEAVE_PRODUCT:
        return GridIdentifier.ADVANCED_SEARCH_WEAVE_PRODUCT;
      case AdvancedSearchObjectInput.TUFT_PRODUCT:
        return GridIdentifier.ADVANCED_SEARCH_TUFT_PRODUCT;
      default:
        return undefined;
    }
  }
}
